import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { FormSection, Textarea } from "@web/ui";

interface StorageLabelInputProps {
  onChange: (label: string) => void;
  defaultValue?: string;
}

export const StorageLabelInput: React.FC<StorageLabelInputProps> = ({ onChange, defaultValue }) => {
  const { t } = useTranslation();

  const handleLabelChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormSection
      title={t("common.components.storageLabel.storageLabel")}
      subtitle={t("common.components.storageLabel.storageLabelDescription")}
      testId="agentInformation"
    >
      <Textarea
        className="text-sm font-light"
        placeholder={t("components.contactInfo.storageLabelPlaceholder")}
        value={defaultValue}
        maxLength={500}
        onChange={handleLabelChange}
      />
    </FormSection>
  );
};
