import { useQuery } from "@tanstack/react-query";

import { getVesselConfiguration } from "src/api/getVesselConfiguration";
import { AppVesselConfiguration } from "src/typegens";

export const useVesselConfigurationQuery = (vesselId?: string) => {
  return useQuery<AppVesselConfiguration>({
    queryKey: ["vesselConfigurationQuery"],
    queryFn: () => getVesselConfiguration({ vesselId: vesselId || "" }),
    gcTime: 0,
    enabled: !!vesselId,
  });
};
