import { DefaultError, useMutation } from "@tanstack/react-query";

import { useSystemMessage } from "src/hooks/useSystemMessage";

import { createOrder } from "../api/createOrder";
import { ApiResponse } from "../store";
import { AppOrderCreationRequest, AppOrderRequisition } from "../typegens";
import { useSuccessCreationCallback } from "./useSuccessCreationCallback";

export const useCreateOrderMutation = () => {
  const successCb = useSuccessCreationCallback();
  const { setSystemMessage } = useSystemMessage();

  return useMutation<ApiResponse<AppOrderRequisition>, DefaultError, AppOrderCreationRequest>({
    mutationKey: ["createOrder"],
    mutationFn: createOrder,
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an error when creating an order",
      });
    },
    onSuccess: successCb,
  });
};
