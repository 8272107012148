import { CheckCircleIcon, ExclamationIcon, XCircleIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { PropsWithChildren } from "react";

import Vector from "@web/common/icons/Vector.svg";
import { OrderRequisition } from "@web/common/network/model";
import { Heading, Label, LoadingInline, Paragraph, TextColors } from "@web/ui";

interface Props {
  closeModal: () => void;
  ctaButtons: React.ReactElement;
  isDraft?: boolean;
  loading: boolean;
  order: OrderRequisition;
  title: string;
  ratio?: [number, number];
  error?: string;
}

export const ItemsAvailabilityModalContainer: React.FC<PropsWithChildren<Props>> = ({
  children,
  closeModal,
  ctaButtons,
  error,
  isDraft,
  loading,
  order,
  ratio,
  title,
}) => {
  const state = ratio
    ? ratio[0] === 0
      ? "red"
      : ratio[0] !== ratio[1]
      ? "blue"
      : "green"
    : undefined;

  return (
    <div className="inline-block bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={closeModal}
        >
          <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col w-130 m-5">
        <Heading size="200">{title}</Heading>{" "}
        <div className="flex flex-row mt-2">
          <div>
            <Label size="200">Review the availability of items</Label>
            <Paragraph size="300" color="text-textIcon-blackSecondary">
              Items that are not available will not be added to your basket
            </Paragraph>
          </div>
          <div className="ml-auto">
            <Paragraph size="300" color="text-textIcon-blackSecondary" className="mb-2">
              Original Delivery Port
            </Paragraph>
            <div className="flex">
              <img src={Vector} alt="Vector Icon" style={{ paddingLeft: "0.1em" }} />
              <Paragraph size="200" color="text-neutral_700" className="pl-3">
                {order.port.locationCode}{" "}
                <span className="text-textIcon-blackPrimary">{order.port.name}</span>
              </Paragraph>
            </div>
          </div>
        </div>
        <div className="mt-4">
          {loading && (
            <>
              <LoadingInline />
              <Label
                size="200"
                className="ml-4"
                /* color="text-text-whiteDisabled" */
                // TODO #6444: Fixme, there is no such color in tailwind config
              >
                Checking availability of items...
              </Label>
            </>
          )}
          {!!ratio && (
            <Label
              size="200"
              color={
                classnames({
                  "text-successDefault": state === "green",
                  "text-primaryDefault": state === "blue",
                  "text-dangerDefault": state === "red",
                }) as TextColors
              }
              className="ml-4 flex"
            >
              {state === "green" ? (
                <CheckCircleIcon className="w-5 mr-2" />
              ) : state === "blue" ? (
                <ExclamationIcon className="w-5 mr-2" />
              ) : (
                <XCircleIcon className="w-5 mr-2" />
              )}
              {`${ratio[0]}/${ratio[1]} ${
                isDraft ? "of your draft Items are available" : "Items available"
              }`}
            </Label>
          )}
          {!!error && (
            <Label size="200" color="text-dangerDefault">
              {error}
            </Label>
          )}
        </div>
      </div>
      <div
        className={`bg-neutral_100 border-t-2 border-b-2 p-5 ${
          typeof children === "undefined" && "h-80"
        }`}
      >
        {children}
      </div>
      <div className="m-5 flex">{ctaButtons}</div>
    </div>
  );
};
