import { v4 as uuidv4 } from "uuid";

import { ToastMessage } from "@web/ui";

import { setSystemMessage as setSystemMessageAction, useAppDispatch } from "src/store";

export const useSystemMessage = () => {
  const dispatch = useAppDispatch();

  const setSystemMessage = (toastMessage: Omit<ToastMessage, "id">) => {
    dispatch(setSystemMessageAction({ ...toastMessage, id: uuidv4() }));
  };

  return { setSystemMessage };
};
