import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { Fragment, forwardRef, useMemo } from "react";

import { Port } from "@web/models";

type Props = {
  selectedPorts: string[];
  portsTable: Record<string, Array<Port>>;
  setSelectedPorts(value: string[]): void;
};

export const MultiplePortSelector = forwardRef<
  HTMLDivElement,
  Props & React.HTMLAttributes<HTMLDivElement>
>(({ selectedPorts, setSelectedPorts, portsTable }, ref) => {
  const options: Array<string | Port> = useMemo(
    () =>
      Object.entries(portsTable).reduce((acc, cur) => {
        return [...acc, cur[0], ...cur[1]];
      }, [] as Array<string | Port>),
    [portsTable]
  );

  const ports: Port[] = useMemo(
    () =>
      Object.values(portsTable).reduce((acc, cur) => {
        return [...acc, ...cur];
      }, []),
    [portsTable]
  );

  const selectedPortsTitle = selectedPorts
    .map((id) => ports.find((o) => o?.id === id))
    .map((p) => p?.locationCode)
    .join(", ");

  return (
    <Listbox value={selectedPorts} onChange={setSelectedPorts} multiple>
      {({ open }) => (
        <>
          <div className="relative mt-1" ref={ref}>
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-neutral_300 bg-neutral_0 py-2 pl-3 pr-10 text-left shadow-sm focus-visible:border-primaryDefault focus:outline-none focus-visible:ring-1 focus-visible:ring-primaryDefault sm:text-sm">
              <span className="inline-flex w-full truncate">
                <span className="truncate">{selectedPortsTitle}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-neutral_0 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) =>
                  typeof option === "string" ? (
                    <div key={option}>{option}</div>
                  ) : (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classnames(
                          active
                            ? "text-neutral_0 bg-primaryDefault"
                            : "text-textIcon-blackPrimary",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={option.id}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex">
                            <span
                              className={classnames(
                                active ? "text-primaryDefault" : "text-textIcon-blackSecondary",
                                "truncate"
                              )}
                            >
                              {option.locationCode}
                            </span>
                            <span
                              className={classnames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-2 truncate"
                              )}
                            >
                              {option.name}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classnames(
                                active ? "text-neutral_0" : "text-primaryDefault",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  )
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
});

MultiplePortSelector.displayName = "MultiplePortSelector";
