import { Disclosure } from "@headlessui/react";
import {
  ChevronDownIcon as ChevronDownIconOutline,
  ChevronRightIcon as ChevronRightIconOutline,
} from "@heroicons/react/outline";
import { Fragment, useState } from "react";

import { BasketEntry, ProductSku } from "@web/common/models";
import { Heading, IconButton, RegularButton } from "@web/ui";
import { isDefined } from "@web/utils";

import { LineItemUI } from "./LineItemUI";
import { SupplierOrderComment, SupplierOrderCommentState } from "./SupplierOrderComment";

type Props = {
  // TODO #3393 Create common interface for lineItems, so SupplierSubBasket and LineItemUI components can be used in both lite & main apps
  lineItems: BasketEntry[];
  orderComment?: string;
  productRoute?: string;
  onOrderCommentDelete: () => void;
  onOrderCommentSave: (comment: string) => void;
  setQuantity: (sku: ProductSku) => (quantity: number) => void;
  supplierId: string;
  supplierDisplayName?: string;
  onLineItemClick?: () => void;
};

export const SupplierSubBasket = ({
  lineItems,
  orderComment,
  onOrderCommentDelete,
  onOrderCommentSave,
  setQuantity,
  supplierId,
  supplierDisplayName,
  productRoute,
  onLineItemClick,
}: Props) => {
  const lineItemsLength = lineItems.length;
  const [comment, setComment] = useState(orderComment);
  const [commentStates, setCommentsState] = useState<SupplierOrderCommentState>(
    comment ? "SUBMITTED" : "EMPTY"
  );

  return (
    <Disclosure as="section" className="mt-6" data-testid="supplierSubBasket_section" defaultOpen>
      {({ open }) => (
        <>
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <Disclosure.Button as={Fragment}>
                <IconButton
                  variant="secondary"
                  size="large"
                  shape="circle"
                  label="Expand/Contract Supplier"
                  Icon={open ? ChevronDownIconOutline : ChevronRightIconOutline}
                  isControlled
                  data-testid="supplierSubBasket_button"
                />
              </Disclosure.Button>
              <Heading
                size="300"
                data-testid="supplierSubBasket_name"
                color="text-textIcon-blackSecondary"
                className="ml-2 capitalize"
              >
                <span className="text-textIcon-blackPrimary">{`${
                  isDefined(supplierDisplayName) ? supplierDisplayName : ""
                }`}</span>
                <span>{` ( ${lineItemsLength} ${lineItemsLength > 1 ? "Items" : "Item"} )`}</span>
              </Heading>
            </div>
            {commentStates === "EMPTY" && (
              <RegularButton
                variant="secondary"
                size="large"
                label="Add Order Comments"
                onClick={() => setCommentsState("EDITING")}
              />
            )}
          </div>
          <Disclosure.Panel as="dd" className="mt-2">
            <SupplierOrderComment
              comment={comment}
              commentState={commentStates}
              onOrderCommentDelete={onOrderCommentDelete}
              onOrderCommentSave={onOrderCommentSave}
              setCommentState={setCommentsState}
              setComment={setComment}
              supplierName={isDefined(supplierDisplayName) ? supplierDisplayName : supplierId}
            />
            {lineItems.map((lineItem) => {
              return (
                <LineItemUI
                  initialQuantity={lineItem.quantity}
                  lineItem={lineItem}
                  key={lineItem.sku.id}
                  setQuantity={setQuantity}
                  onLineItemClick={onLineItemClick}
                  productRoute={productRoute}
                />
              );
            })}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
