import { ProductSku } from "@web/common/models/ProductSku";
import { getSalesEntityPrice } from "@web/common/utils";
import { Label, Paragraph } from "@web/ui";
import { formatMoney } from "@web/utils";

interface Props {
  activeSkuIndex: number;
  setSkuIndex: (index: number) => void;
  skuList: Array<ProductSku>;
}

export const ProductVariants = ({ activeSkuIndex, setSkuIndex, skuList }: Props) => {
  return skuList.length > 1 ? (
    <>
      <Label size="300" color="text-textIcon-blackSecondary" className="pb-4 pt-5 block">
        VARIANTS
      </Label>
      <div className="flex flex-row">
        {skuList.map((variantSku, idx) => (
          <div
            key={variantSku.id}
            onClick={() => setSkuIndex(idx)}
            className={`${
              activeSkuIndex === idx ? "bg-primaryBackground border-primaryDefault" : "bg-neutral_0"
            } p-3 mr-2 cursor-pointer rounded-md border-2 border-neutral_300`}
          >
            <Label size="200">{variantSku?.about?.name}</Label>
            <Paragraph size="300">
              <span className="text-textIcon-blackSecondary">
                {formatMoney(getSalesEntityPrice(variantSku))}
              </span>
            </Paragraph>
          </div>
        ))}
      </div>
    </>
  ) : null;
};
