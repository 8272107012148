import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter as Router } from "react-router-dom";

import { OpenAPI as AttachmentsOpenAPI } from "@web/attachments";
import { imagorConfig } from "@web/utils";

import "./App.css";
import setAppLanguage from "./config/i18n";
import AppProviders from "./context/Providers";
import { Routes } from "./routes";
import { OpenAPI } from "./typegens";

const VITE_API_BASE_URL = import.meta.env.VITE_API_BASE_URL as string;
const VITE_GOOGLE_ANALYTICS = import.meta.env.VITE_GOOGLE_ANALYTICS as string;
const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;
const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT as string;
const VITE_GIT_HASH = import.meta.env.VITE_GIT_HASH as string;

const apiBaseUrl = VITE_API_BASE_URL;

Sentry.init({
  integrations: [new BrowserTracing()],
  dsn: VITE_SENTRY_DSN,
  environment: VITE_ENVIRONMENT,
  release: VITE_GIT_HASH,
});

OpenAPI.BASE = apiBaseUrl;
OpenAPI.ENCODE_PATH = encodeURIComponent;
AttachmentsOpenAPI.BASE = apiBaseUrl;
AttachmentsOpenAPI.ENCODE_PATH = encodeURIComponent;
imagorConfig.baseUrl = apiBaseUrl;

const App = () => {
  useEffect(() => {
    ReactGA.initialize(VITE_GOOGLE_ANALYTICS || "UA-XXXXXXXXX-X");
  }, []);

  setAppLanguage();

  return (
    <Router>
      <AppProviders>
        <Routes />
      </AppProviders>
    </Router>
  );
};

export default App;
