import Fuse from "fuse.js";
import { useRef, useState } from "react";

export const useFuzzySearch = <T>({ collection, keys }: { collection: T[]; keys: string[] }) => {
  const [filteredCollection, setFilteredCollection] = useState(collection);
  const fuse = useRef(new Fuse(collection, { keys }));

  const handleSearchPatternChange = (pattern: string) => {
    if (pattern === "") {
      setFilteredCollection(collection);
    } else {
      setFilteredCollection(fuse.current.search(pattern).map((result) => ({ ...result.item })));
    }
  };

  return { filteredCollection, handleSearchPatternChange };
};
