import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon, PlusIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useModalContext } from "@web/common/contexts/ModalContext";
import { Vessel } from "@web/common/models/Vessel";
import { RegularButton } from "@web/ui";

import { SelectVesselModal } from "../../../components/modals/SelectVesselModal";
import routes from "../../../config/routes";
import { useVesselsQuery } from "../../../hooks/useVesselsQuery";
import { clearGatherBasket, setGatherVessel, useAppDispatch } from "../../../store";

type CreateOrderOptions = "Order for this Vessel" | "Order for another Vessel";

const createOrderOptions: CreateOrderOptions[] = [
  "Order for this Vessel",
  "Order for another Vessel",
];

export const CreateNewOrderButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { openModal, closeModal } = useModalContext();
  const vesselsQuery = useVesselsQuery();
  const [selected, setSelected] = useState<CreateOrderOptions>("Order for this Vessel");

  const getCurrentVesselId = (): string | undefined => {
    const currentParams = new URLSearchParams(location.search);
    return currentParams.get("vesselId") || undefined;
  };

  const createNewOrderFromAnotherVessel = () => {
    openModal(<SelectVesselModal closeModal={closeModal} />);
  };

  const createNewOrderFromSelectedVessel = () => {
    let vessel: Vessel | undefined = undefined;

    if (vesselsQuery.isSuccess) {
      vessel = vesselsQuery.data.find((vessel) => vessel.id === getCurrentVesselId());

      if (vessel) {
        dispatch(clearGatherBasket());
        dispatch(setGatherVessel(vessel));
        navigate(routes.preconfigureOrderSetup);
      }
    }
  };

  const handleOptionChange = (option: CreateOrderOptions) => {
    setSelected(option);

    if (option === "Order for this Vessel") {
      createNewOrderFromSelectedVessel();
    } else if (option === "Order for another Vessel") {
      createNewOrderFromAnotherVessel();
    }
  };

  return (
    <div className="relative flex items-center">
      {getCurrentVesselId() ? (
        <Listbox value={selected} onChange={handleOptionChange}>
          <Listbox.Button>
            <RegularButton
              variant="primary"
              size="large"
              label="Create New Order"
              LeadingIcon={PlusIcon}
              TrailingIcon={ChevronDownIcon}
              isControlled
              data-testid="createNewOrderCTA"
            />
          </Listbox.Button>
          <Listbox.Options className="absolute right-0 w-20 py-1 /ring-1/ring-black ring-opacity-5 shadow-lg bg-neutral_100 rounded-md overflow-hidden top-full mt-2">
            {createOrderOptions.map((option) => {
              return (
                <Listbox.Option
                  className={({ selected }) =>
                    `w-full bg-neutral_0 max-h-60 px-4 py-3 text-base sm:text-sm hover:bg-neutral_100 cursor-pointer text-textIcon-blackSecondary hover:text-textIcon-blackPrimary flex justify-between ${
                      selected ? "font-medium" : ""
                    }`
                  }
                  key={option}
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span>{option}</span>
                      {selected ? (
                        <CheckIcon className="h-5 w-5 text-primaryDefault" aria-hidden="true" />
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Listbox>
      ) : (
        <RegularButton
          variant="primary"
          size="large"
          label="Create New Order"
          LeadingIcon={PlusIcon}
          onClick={createNewOrderFromAnotherVessel}
          data-testid="createNewOrderCTA"
        />
      )}
    </div>
  );
};
