import { ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormSection, RegularButton, Textarea } from "@web/ui";

interface Props {
  comment?: string;
  onChange: (comment: string) => void;
}

export const OrderComment: React.FC<Props> = ({ comment: commentProp = "", onChange }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState(commentProp);
  const [commentOpened, setCommentOpened] = useState(Boolean(commentProp));

  const handleNoteChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setComment(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    if (!commentOpened) {
      onChange("");
    } else {
      onChange(comment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, commentOpened]);

  return (
    <div className="flex flex-col gap-4 mb-6">
      <FormSection
        title={t("common.components.noteBox.orderNotes")}
        subtitle={t("components.contactInfo.orderCommentsDescription")}
        testId="orderComments"
      >
        <div className="w-16">
          <RegularButton
            variant="secondary"
            size="large"
            label={
              commentOpened
                ? t("components.contactInfo.notesCancel")
                : t("components.contactInfo.notesAdd")
            }
            onClick={() => setCommentOpened(!commentOpened)}
            data-testid="orderComment_CTA"
          />
        </div>
      </FormSection>
      {commentOpened && (
        <div className="w-16">
          <Textarea
            className="text-sm font-light"
            testId="orderComment"
            placeholder={t("components.contactInfo.notesPlaceholder")}
            value={comment}
            maxLength={1000}
            onChange={handleNoteChange}
          />
        </div>
      )}
    </div>
  );
};
