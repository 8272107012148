import { useParams } from "react-router-dom";

import Breadcrumbs from "@web/common/components/Breadcrumbs";
import { Heading } from "@web/ui";

import CategoryFilter from "../../components/CategoryFilter";
import routes from "../../config/routes";
import { useCategories } from "../../hooks/useCategories";
import ProductsList from "./components/ProductsList";

export const GatherCategoriesPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const encodedId = encodeURIComponent(id);
  const { getBreadcrumb, getCategory, getChildCategories } = useCategories();
  const category = getCategory(encodedId);
  const childCategories = getChildCategories(encodedId);

  return (
    <>
      <div className="flex justify-between items-baseline">
        <Breadcrumbs
          catalogHomeRoute={routes.gatherCatalog}
          catalogIdRoute={routes.gatherCatalog}
          categoryTree={getBreadcrumb(encodedId)}
        />
      </div>
      <div>
        <div className="pb-4">
          <Heading size="200">{category?.name}</Heading>
        </div>
        <div className="flex h-max">
          {childCategories.length > 0 && (
            <div className="flex flex-col">
              <CategoryFilter heading="Categories" options={childCategories} />
            </div>
          )}
          <div className="flex flex-col items-center w-full">
            <ProductsList />
          </div>
        </div>
      </div>
    </>
  );
};
