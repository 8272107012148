import jwtDecode from "jwt-decode";
import { PropsWithChildren, useEffect, useState } from "react";

import { useAuth0WithRedirect } from "@web/common/contexts/Auth0WithRedirect";
import { Loading } from "@web/ui";

import { setScopeAction, useAppDispatch } from "../store";
import { OpenAPI } from "../typegens";
import { NonAuthenticatedApp } from "./NonAuthenticatedApp";

const VITE_MOCK_SERVER = import.meta.env.VITE_MOCK_SERVER as string;

interface JwtDecoded {
  exp: number;
  scope: string;
}

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const hasMockedServer = VITE_MOCK_SERVER === "true";
  const [keepBlocking, setKeepBlocking] = useState(!hasMockedServer);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0WithRedirect();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      const handleToken = async () => {
        const token = await getAccessTokenSilently();
        OpenAPI.TOKEN = getAccessTokenSilently;
        const decoded = jwtDecode(token);
        const scope = (decoded as JwtDecoded).scope.split(" ");
        dispatch(setScopeAction(scope));
        setKeepBlocking(false);
      };
      handleToken().catch(console.error);
    }
    if (!isAuthenticated && !isLoading) {
      setKeepBlocking(false);
    }
  }, [dispatch, getAccessTokenSilently, isAuthenticated, isLoading]);

  if (keepBlocking) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div style={{ height: 32, width: 32 }}>
          <Loading />
        </div>
      </div>
    );
  }

  if ((!keepBlocking && isAuthenticated) || hasMockedServer) {
    return <>{children}</>;
  }

  return <NonAuthenticatedApp />;
};

export default AuthProvider;
