interface Props {
  headerNames: string[];
}

const ManageTableHeaders: React.FC<Props> = ({ headerNames }) => (
  <tr>
    {headerNames.map((headerName) => (
      <th
        scope="col"
        className={`pl-5 py-3 text-left text-xs font-medium text-textIcon-blackSecondary uppercase tracking-wider`}
        key={headerName}
      >
        {headerName}
      </th>
    ))}
  </tr>
);

export default ManageTableHeaders;
