import { useSelector } from "react-redux";

import { HomeCategoriesView } from "@web/common/views/HomeCategories";
import { Loading } from "@web/ui";

import routes from "../../../config/routes";
import useCategoriesQuery from "../../../hooks/useCategoriesQuery";
import { RootState } from "../../../store";

export const HomeCategories: React.FC = () => {
  const { port, vessel } = useSelector((state: RootState) => state.gather);
  const categoriesQuery = useCategoriesQuery(
    { portId: port?.id || "", vesselId: vessel?.id || "" },
    { enabled: !!port && !!vessel }
  );

  if (categoriesQuery.status !== "success") {
    return <Loading />;
  }

  return (
    <HomeCategoriesView categoryIdRoute={routes.gatherCatalog} categories={categoriesQuery.data} />
  );
};
