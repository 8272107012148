import { BasketEntry } from "@web/common/models/basket";
import { OrderItem, RfqItem } from "@web/common/network/model";

import { Item as EditItem, QuotationApproveItem } from "../../../typegens";

export const toQuotationApproveItemList: (lineItems: BasketEntry[]) => QuotationApproveItem[] = (
  lineItems
) => {
  return lineItems
    .filter((entry) => entry.quantity)
    .map(({ sku, quantity }) => {
      return {
        id: sku.id,
        quantity: quantity,
      };
    });
};

export const toEditItemList: (lineItems: BasketEntry[]) => EditItem[] = (lineItems) => {
  return lineItems
    .filter((entry) => entry.quantity)
    .map(({ sku, quantity }) => {
      return {
        variantId: sku.id,
        quantity: quantity * sku.salesEntityQuantity,
      };
    });
};

function isRfqItem(item: RfqItem | OrderItem): item is RfqItem {
  return (item as RfqItem).id !== undefined;
}

export const toQuotationApproveItem: (item: RfqItem | OrderItem) => QuotationApproveItem = (
  item
) => {
  if (isRfqItem(item)) {
    return {
      id: item.id,
      quantity: item.quantity,
    };
  }

  return {
    id: item.variantId,
    quantity: item.quantity,
  };
};

export const toApproveRequisitionItem: (item: OrderItem) => EditItem = (item) => {
  return {
    quantity: item.entityQuantity,
    variantId: item.variantId,
  };
};
