import { CheckIcon } from "@heroicons/react/solid";

import ContactCard from "@web/common/layouts/ContactCard";
import { Paragraph, RegularButton } from "@web/ui";

interface Props {
  closeModal: () => void;
}

export const CancelSuccessModal: React.FC<Props> = ({ closeModal }) => {
  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4"></div>
      <div className="flex flex-col sm:items-center text-center py-4">
        <div className="mx-auto shrink-0 flex items-center justify-center p-2 rounded-full bg-successBackground sm:mx-0">
          <CheckIcon className="w-6 text-successDefault" aria-hidden="true" />
        </div>
        <div className="sm:mt-3 sm:mb-2 sm:text-center">
          <Paragraph size="100">Request sent successfully!</Paragraph>
          <div className="mt-2">
            <Paragraph size="200" color="text-neutral_700">
              Requisition has been cancelled and no further action is necessary.
            </Paragraph>
          </div>
        </div>
      </div>
      <hr />
      <div className="mt-3">
        <ContactCard />
        <div className="sm:mt-4 sm:mb-4 sm:flex justify-center">
          <RegularButton
            variant="primary"
            size="large"
            width="container"
            label="Done"
            onClick={closeModal}
          />
        </div>
      </div>
    </div>
  );
};
