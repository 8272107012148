import { MenuIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";

import { CategorySelector as CategorySelectorUI, RegularButton } from "@web/ui";

import useCategoriesQuery from "../../../hooks/useCategoriesQuery";
import { RootState } from "../../../store";

type Props = {
  subCategoryRoute: string;
};

export const CategorySelector: React.FC<Props> = ({ subCategoryRoute }) => {
  const { port, vessel } = useSelector((state: RootState) => state.gather);

  const categoriesQuery = useCategoriesQuery(
    { portId: port?.id || "", vesselId: vessel?.id || "" },
    { enabled: !!port && !!vessel }
  );

  if (categoriesQuery.status !== "success") {
    return (
      <RegularButton
        variant="secondary"
        size="large"
        label="Categories"
        LeadingIcon={MenuIcon}
        isControlled
        loading={true}
      />
    );
  }

  return (
    <CategorySelectorUI categories={categoriesQuery.data} subCategoryRoute={subCategoryRoute} />
  );
};
