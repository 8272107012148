import { CalendarIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

import { Paragraph } from "@web/ui";

interface LeadTimeProps {
  leadTime?: string;
  customStyling?: string;
}

export const LeadTime: React.FC<LeadTimeProps> = ({ leadTime, customStyling }) => {
  const { t } = useTranslation();

  if (!leadTime) {
    return null;
  }

  return (
    <div
      className={`bg-neutral_100 rounded-md my-1 px-1 py-1 flex flex-row justify-center ${customStyling}`}
    >
      <CalendarIcon className="text-textIcon-blackSecondary w-4 h-4 mx-1" />
      <Paragraph size="300" className="text-neutral_700">
        {t("common.components.leadTime.leadTimeIs", { count: parseInt(leadTime) })}
      </Paragraph>
    </div>
  );
};
