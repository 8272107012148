import { HashtagIcon } from "@heroicons/react/solid";

import { Paragraph } from "../Typography";

interface DesiredDeliveryTimeProps {
  label?: string;
  poNumber: string;
  className?: string;
}

export const PONumber: React.FC<DesiredDeliveryTimeProps> = ({
  poNumber,
  label = "internal po number",
  className,
}) => {
  return (
    <div className={className}>
      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pb-2 uppercase">
        {label}
      </Paragraph>
      <div className="flex items-center truncate">
        <div className="flex gap-3">
          <HashtagIcon className="-ml-0.5 text-textIcon-blackSecondary" width="20px" />
          <div className="flex flex-col flex-1 truncate">
            <Paragraph size="200" color="text-neutral_700" className="truncate">
              {poNumber}
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};
