import ReactGA from "react-ga";

import {
  LineItemQuantity as UiLineItemQuantity,
  LineItemQuantityProps as UiLineItemQuantityProps,
} from "@web/ui";

import trackingCategories from "../utils/trackingCategories";
import trackingEvents from "../utils/trackingEvents";

interface Props extends UiLineItemQuantityProps {
  shouldTrack?: boolean;
}

export const LineItemQuantity: React.FC<Props> = ({ shouldTrack, ...props }) => {
  const minusButtonClickCallback = () => {
    if (!shouldTrack) {
      return;
    }

    ReactGA.event({
      category: trackingCategories.CREW_APP,
      action: trackingEvents.QUANTITY_DECREASED_LINE_ITEM,
    });
  };

  const plusButtonClickCallback = () => {
    if (!shouldTrack) {
      return;
    }

    ReactGA.event({
      category: trackingCategories.CREW_APP,
      action: trackingEvents.QUANTITY_INCREASED_LINE_ITEM,
    });
  };

  const inputChangeCallback = (value: string) => {
    if (!shouldTrack) {
      return;
    }

    ReactGA.event({
      category: trackingCategories.CREW_APP,
      action: trackingEvents.QUANTITY_MODIFIED_BASKET,
      value: parseInt(value),
    });
  };

  return (
    <UiLineItemQuantity
      minusButtonClickCallback={minusButtonClickCallback}
      plusButtonClickCallback={plusButtonClickCallback}
      inputChangeCallback={inputChangeCallback}
      {...props}
    />
  );
};
