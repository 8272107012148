import { useSelector } from "react-redux";

import { LocalOrderReqService } from "src/services/LocalOrderReqService";
import { RootState } from "src/store";

import { DraftRequest } from "../typegens";

export const useDraft = () => {
  const port = useSelector((state: RootState) => state.gather.port);
  const vessel = useSelector((state: RootState) => state.gather.vessel);
  const deliveryDate = useSelector((state: RootState) => state.gather.deliveryDate);
  const dutyFreeDeclaration = useSelector((state: RootState) => state.gather.dutyFreeDeclaration);
  const basketEntries = useSelector((state: RootState) => state.gather.basket.catalogItems);
  const supplierId = LocalOrderReqService.extractSupplierIdFromBasketEntries(basketEntries);

  const getDraftFromState: () => DraftRequest = () => {
    if (!vessel) {
      throw new Error("No vessel selected");
    }
    if (!port) {
      throw new Error("No port selected");
    }
    if (!supplierId) {
      throw new Error("No port selected");
    }

    return {
      requesterInformation: {
        email: vessel?.contactInformation?.email || "",
        name: vessel?.contactInformation?.name || "",
      },
      catalogItems: LocalOrderReqService.convertBasketEntriesToCreationItems(basketEntries),
      portId: port?.id,
      vesselId: vessel?.id,
      supplierId,
      storageLabel: "",
      deliveryDate,
      dutyFreeDeclaration,
    };
  };

  return {
    getDraftFromState,
  };
};
