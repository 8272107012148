import { v4 as uuidv4 } from "uuid";

import { VesselFormType } from "../components/modals/EditVesselModal/schema";
import { ApiResponse, handleResponse } from "../store/utils";
import { AppVessel, MainAppService } from "../typegens";

export const createVessel = async (form: VesselFormType): Promise<ApiResponse<AppVessel>> => {
  const id = uuidv4();
  const result = await handleResponse(() =>
    MainAppService.saveOrUpdateVessel({
      id,
      requestBody: {
        contactInformation: { name: form.contactName, email: form.contactEmail },
        name: form.vesselName,
        fleetId: form.fleet.value,
        imoNumber: form.imoNumber,
        id,
        invoiceAccounts: [],
        externalId: "",
      },
    })
  );

  return result;
};
