import { PencilIcon, ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useModalContext } from "@web/common/contexts/ModalContext";
import { RegularButton } from "@web/ui";

import RejectRequisitionModal from "../../components/modals/RejectRequisitionModal";
import routes from "../../config/routes";
import { clearGatherBasketEntries, useAppDispatch } from "../../store";

interface Props {
  quotationId: string;
}

const QuotationCTAButtons: React.FC<Props> = ({ quotationId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openModal, closeModal } = useModalContext();

  const onRequisitionEdit = () => {
    navigate(`${routes.orderDetails}/${quotationId}/edit`);
  };

  const handleApproveQuotationClick = () => {
    dispatch(clearGatherBasketEntries());
    navigate(`${routes.orderDetails}/${quotationId}/approve`);
  };

  return (
    <div className="flex h-min gap-2">
      <RegularButton
        variant="secondary"
        size="large"
        label={t("layouts.buttons.editItems")}
        LeadingIcon={PencilIcon}
        onClick={onRequisitionEdit}
      />
      <RegularButton
        variant="danger"
        size="large"
        label={t("layouts.buttons.decline")}
        LeadingIcon={ThumbDownIcon}
        onClick={() =>
          openModal(
            <RejectRequisitionModal
              isQuotation
              isRfQRequisition={false}
              requisitionId={quotationId}
              closeModal={closeModal}
            />
          )
        }
      />
      <RegularButton
        variant="primary"
        size="large"
        label={t("layouts.buttons.approveQuotation")}
        LeadingIcon={ThumbUpIcon}
        onClick={handleApproveQuotationClick}
      />
    </div>
  );
};

export default QuotationCTAButtons;
