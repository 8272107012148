import { Money } from "@web/models";
import { RegularButton } from "@web/ui";
import { formatMoney } from "@web/utils";

import BasketSVG from "../icons/Basket.svg?react";

interface Props {
  hasRfq?: boolean;
  grandTotal: Money;
  onClick: () => void;
}

export const BasketButton = ({ grandTotal, onClick }: Props) => {
  return (
    <RegularButton
      size="large"
      variant="primary"
      label={formatMoney(grandTotal)}
      LeadingIcon={BasketSVG}
      onClick={onClick}
      data-testid="basket"
    />
  );
  /*
    The legacy button component was extended for RFQ feature (that's likely to be revamped),
    and the new button component does not support such extension. Therefore, when the RFQ feature
    is reworked, some custom component will be needed to handle this case.
    The original component code is left below for reference, and to help with the transition in the future.
    //
    <LEGACY_Button
      data-testid="basket"
      aria-label="Basket"
      icon={<img src={BasketSVG} className="h-5 w-5 pr-2 max-w-none" aria-hidden="true" />}
      variant="secondary"
    >
      {formatMoney(grandTotal)}
      {hasRfq && (
        <Tooltip tooltipText="Basket contains RFQ items">
          <div className="flex items-center h-[26px]">
            <div role="presentation" className="bg-neutral_300 w-[1px] h-full mx-2" />
            <div className="flex items-center justify-center text-neutral_0 bg-dangerDefault w-[30px] text-sm rounded-full">
              +1
            </div>
          </div>
        </Tooltip>
      )}
    </LEGACY_Button>
   */
};
