import { useNavigate } from "react-router-dom";

import { Heading, Paragraph, RegularButton } from "@web/ui";

import S2SLogo from "../../icons/s2sIcon.svg";

export const Logout = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center pt-6">
      <img className="w-8 h-8" src={S2SLogo} />
      <Heading size="200" className="mb-5">
        You have been logged out.
      </Heading>
      <div className="flex justify-start items-center gap-2">
        <RegularButton
          variant="secondary"
          size="large"
          label="Click here"
          onClick={() => navigate(-1)}
          data-testid="loginButton"
        />
        <Paragraph size="200">to log back in to Source 2 Sea.</Paragraph>
      </div>
    </div>
  );
};
