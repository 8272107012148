import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Breadcrumbs from "@web/common/components/Breadcrumbs";
import { Heading } from "@web/ui";

import routes from "../../config/routes";
import ProductsList from "./components/ProductsList";

export const GatherSearchPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");
  const navigate = useNavigate();
  const isEmptyQuery = query === "" || query == null;

  useEffect(() => {
    if (isEmptyQuery) {
      navigate(routes.orders);
    }
  }, [isEmptyQuery, navigate, query]);

  if (isEmptyQuery) {
    return null;
  }

  return (
    <>
      <div className="flex justify-between items-baseline">
        <Breadcrumbs
          catalogHomeRoute={routes.gatherCatalog}
          catalogIdRoute={routes.gatherCatalog}
          path={[{ title: "Search Results" }]}
        />
      </div>
      <div className="flex flex-col flex-1">
        <div className="pb-4">
          <Heading size="200">{`Results for "${searchParams.get("query")}"`}</Heading>
        </div>
        <ProductsList />
      </div>
    </>
  );
};
