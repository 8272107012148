import { useTranslation } from "react-i18next";

import { FormSection, Label } from "@web/ui";

type Props = {
  isConsolidatedOrder: boolean;
  onChangeIsConsolidatedOrder: (isConsolidatedOrder: boolean) => void;
};

export const DeliveryMethod = ({ isConsolidatedOrder, onChangeIsConsolidatedOrder }: Props) => {
  const { t } = useTranslation();

  return (
    <FormSection
      subtitle={t("components.contactInfo.deliveryMethod.subtitle")}
      title={t("components.contactInfo.deliveryMethod.title")}
      testId="deliveryMethod"
    >
      <div className="relative flex items-center">
        <div className="flex items-center h-5">
          <input
            checked={isConsolidatedOrder}
            data-testid="consolidatedDeliveryInput"
            id="consolidatedDelivery"
            name="consolidatedDelivery"
            onChange={() => onChangeIsConsolidatedOrder(!isConsolidatedOrder)}
            type="checkbox"
            className="focus:ring-primaryDefault h-4 w-4 text-primaryDefault border-neutral_300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <Label
            size="300"
            component="label"
            htmlFor="consolidatedDelivery"
            /* color="text-black" */
            // TODO #6444: Fixme, there is no such color in tailwind config
          >
            {t("components.contactInfo.deliveryMethod.label")}
          </Label>
        </div>
      </div>
    </FormSection>
  );
};
