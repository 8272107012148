const routes = {
  orders: "/",
  orderDetails: "/orderDetails",
  orderInfo: "/orderInfo",
  orderSuccess: "/orderSuccess",
  manageVessels: "/manageVessels",
  manageCustomers: "/manageCustomers",
  manageUsers: "/manageUsers",
  manageFleets: "/manageFleets",
  preconfigureOrderSetup: "/preconfigureOrderSetup",
  gatherCatalog: "/gather/catalog",
  gatherCatalogDetail: "/gather/catalog",
  gatherSearch: "/gather/search",
  gatherBasket: "/basket",
  gatherProduct: "/gather/product",
  noAccess: "/no-access",
  logout: "/logout",
};

export default routes;
