import { ThumbDownIcon } from "@heroicons/react/outline";
import { DefaultError, useMutation } from "@tanstack/react-query";
import { ChangeEventHandler, useState } from "react";

import { Paragraph, RegularButton, Textarea } from "@web/ui";

import { rejectQuotation } from "../../api/rejectQuotation";
import { rejectRequisition } from "../../api/rejectRequisition";
import { rejectRfq } from "../../api/rejectRfq";
import { useOrderRequisitionQuery } from "../../hooks/useOrderRequisitionQuery";
import { ApiResponse } from "../../store/utils";
import {
  AppOrderRequisition,
  AppQuotationRejectRequest,
  AppRequisitionRejectRequest,
} from "../../typegens";

interface Props {
  isRfQRequisition: boolean;
  isQuotation?: boolean;
  requisitionId: string;
  closeModal: () => void;
}

const RejectRequisitionModal: React.FC<Props> = ({
  isRfQRequisition,
  isQuotation,
  requisitionId,
  closeModal,
}) => {
  const [rejectionNote, setRejectionNote] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { isPending, refetch } = useOrderRequisitionQuery({ orderId: requisitionId });

  const getMutateType = () => {
    if (isRfQRequisition) {
      return rejectRfq;
    }
    if (isQuotation) {
      return rejectQuotation;
    }
    return rejectRequisition;
  };

  const { mutate, isPending: isProcessingRejection } = useMutation<
    ApiResponse<AppOrderRequisition>,
    DefaultError,
    { id: string; requestBody: AppQuotationRejectRequest | AppRequisitionRejectRequest }
  >({
    mutationKey: ["rejectMutation"],
    mutationFn: getMutateType(),
    onSuccess: () => {
      closeModal();
      refetch();
    },
  });

  const isProcessing = isProcessingRejection || isPending;

  const handleRejectRequisition = async () => {
    if (!rejectionNote) {
      return setErrorMessage("Enter a rejection message");
    }

    await mutate({ id: requisitionId, requestBody: { comment: rejectionNote } });
  };

  const handleRejectQuotation = async () => {
    await mutate({ id: requisitionId, requestBody: { comment: "" } });
  };

  const handleNoteChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setRejectionNote(e.target.value);
  };

  if (isQuotation) {
    return (
      <div className="inline-block align-bottom bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full divide-y">
        <div className="flex p-5">
          <div className="bg-dangerBackground w-6.5 h-6.5 rounded-full flex justify-around items-center">
            <ThumbDownIcon className="w-5 h-5 text-dangerDefault" />
          </div>
          <div className=" px-5">
            <Paragraph size="100">Are you sure you want to reject this Quotation?</Paragraph>
            <div className="mt-2">
              <Paragraph size="200" color="text-textIcon-blackSecondary">
                You can not undo this action
              </Paragraph>
            </div>
          </div>
        </div>

        <div className="flex flex-row-reverse px-5 py-3 bg-neutral_100 mt-7 gap-3">
          <RegularButton
            variant="danger"
            size="large"
            label="Yes, Reject Quotation"
            loading={isProcessing}
            onClick={handleRejectQuotation}
          />
          <RegularButton
            variant="secondary"
            size="large"
            label="Cancel"
            disabled={isProcessing}
            onClick={closeModal}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full divide-y">
      <div className="py-4.5 px-5">
        <Paragraph size="100">Reject order</Paragraph>
        <div className="mt-2">
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            Explain why your are rejecting this order
          </Paragraph>
        </div>
      </div>
      <div className="px-5 py-2">
        <Textarea
          errorMessage={errorMessage}
          onChange={handleNoteChange}
          placeholder="Message the crew"
          value={rejectionNote}
        />
      </div>
      <div className="flex flex-row-reverse px-5 py-3 bg-neutral_100 gap-3">
        <RegularButton
          variant="primary"
          size="large"
          label="Reject & send"
          LeadingIcon={ThumbDownIcon}
          loading={isProcessing}
          onClick={handleRejectRequisition}
        />
        <RegularButton
          variant="secondary"
          size="large"
          label="Cancel"
          disabled={isProcessing}
          onClick={closeModal}
        />
      </div>
    </div>
  );
};

export default RejectRequisitionModal;
