import { ImagorFilters } from "./types";

export const createImagorUrlFilters = (filters?: ImagorFilters) => {
  if (!filters) {
    return "";
  }

  return Object.entries(filters).reduce((accumulator, [key, value], index, filtersArray) => {
    const isFirstElement = index === 0;
    const isLastElement = filtersArray.length === index + 1;

    return `${isFirstElement ? "filters" : ""}${accumulator}:${key}(${
      value === true ? "" : value
    })${isLastElement ? "/" : ""}`;
  }, "");
};
