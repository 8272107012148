import { OrderRequisition } from "@web/common/network/model";

import { LocalOrderReqService } from "../services/LocalOrderReqService";
import { MainAppService } from "../typegens";

export const getOrderRequisition = async ({ id }: { id: string }): Promise<OrderRequisition> => {
  const result = await MainAppService.getOrderRequisitionById({ id });

  return LocalOrderReqService.toOrderRequisition(result);
};
