import { OrderItem, ProductChange } from "@web/common/network/model";

export const getUnchangedOrderItems = (items: OrderItem[], changes: ProductChange[]) => {
  const unchangedItems = items.slice();

  for (let index = 0; index < changes.length; index++) {
    const element = changes[index];

    const indexOfChangedItem = unchangedItems.findIndex((item) => {
      return item.variantId === element?.id;
    });

    if (indexOfChangedItem > -1) {
      unchangedItems.splice(indexOfChangedItem, 1);
    }
  }

  return unchangedItems;
};
