import { RootState } from ".";
import { GATHER_KEY } from "../config/consts";

export const saveGather = (state: RootState) => {
  try {
    localStorage.setItem(GATHER_KEY, JSON.stringify(state.gather));
  } catch (err) {
    // not handling error
  }
};
