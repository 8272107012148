import { useTranslation } from "react-i18next";

import { SkuAbout, SkuManufacture } from "@web/common/models/ProductSku";
import { Heading } from "@web/ui";

import { Description, RowGroup } from "./SkuAttributesUtils";

interface Props {
  about?: SkuAbout;
  manufacture?: SkuManufacture;
}

export const AboutSection = ({ about, manufacture }: Props) => {
  const { t } = useTranslation();

  if (!about) {
    return null;
  }

  return (
    <div>
      <div className="py-4">
        <Heading size="300" className="text-textIcon-blackPrimary">
          {t("common.views.productDetails.about")}
        </Heading>
      </div>
      <Description label={t("common.views.productDetails.description")} value={about.description} />
      <RowGroup
        title={t("common.views.productDetails.generalInformation")}
        rows={{
          "Quantity per product":
            about.generalInformation?.packingQuantity !== 1
              ? about.generalInformation?.packingQuantity
              : undefined,
          brand: about.quality?.brand,
          "IMPA code": about.generalInformation?.impaCode,
          "Dangerous goods": about.generalInformation?.dangerousGoods ? "Yes" : "",
          "Dangerous goods code": about.generalInformation?.dangerousGoods,
          "Inventory Hazardous Material": about.generalInformation?.hazardousMaterial ? "Yes" : "",
          "Inventory Hazardous Material Code": about.generalInformation?.hazardousMaterial,
          "Country of Origin": about.quality?.countryOrigin,
          gtin: about.generalInformation?.gtin,
          manufacturer: manufacture?.manufacturerName,
          "Manufacturer Part Number": manufacture?.manufacturersPartNumber,
        }}
      />
    </div>
  );
};
