import { useTranslation } from "react-i18next";

import { FormSection, Paragraph } from "@web/ui";

interface RequesterInformationFromLiteProps {
  isRequester?: boolean;
  emailField: React.ReactNode;
  fullNameField: React.ReactNode;
}

export const ContactInformation: React.FC<RequesterInformationFromLiteProps> = ({
  emailField,
  fullNameField,
}) => {
  const { t } = useTranslation();

  return (
    <FormSection
      title={t("components.contactInfo.information")}
      subtitle={t("components.contactInfo.informationDescription")}
    >
      <div className="flex flex-col gap-4">
        {emailField}
        <div className="bg-neutral_200 py-3 px-4 rounded-md">
          {fullNameField}
          <Paragraph size="300" className="mt-2" color="text-textIcon-blackSecondary">
            {t("components.contactInfo.internalUseOnly")}
          </Paragraph>
        </div>
      </div>
    </FormSection>
  );
};
