import { useTranslation } from "react-i18next";

import { LinkButton } from "@web/ui";

import { AppFleet } from "../../typegens";

interface ManageFleetRowProps {
  fleet: AppFleet;
  handleOnUpdateClick: (fleet: AppFleet) => void;
  handleOnRemoveClick: (fleet: AppFleet) => void;
}

export const ManageFleetRow: React.FC<ManageFleetRowProps> = ({
  fleet,
  handleOnUpdateClick,
  handleOnRemoveClick,
}) => {
  const { t } = useTranslation();

  return (
    <tr key={fleet.id}>
      <td className="pl-5 py-4 text-sm font-medium text-textIcon-blackPrimary">{fleet.name}</td>
      <td className="pl-5 py-4 text-sm text-textIcon-blackSecondary">{fleet.customerName}</td>
      <td className="pl-5 py-4 whitespace-nowrap">
        {fleet.permissions.map((permission) => (
          <span
            key={permission}
            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-successBackground text-successDefault"
          >
            {permission}
          </span>
        ))}
      </td>
      <td className="px-5 py-4 whitespace-nowrap">
        <div className="flex justify-end content-center gap-2">
          <LinkButton
            size="large"
            variant="primary"
            label={t("pages.manageFleets.fleetsList.update")}
            onClick={() => handleOnUpdateClick(fleet)}
          />
          |
          <LinkButton
            size="large"
            variant="danger"
            label={t("pages.manageFleets.fleetsList.remove")}
            onClick={() => handleOnRemoveClick(fleet)}
          />
        </div>
      </td>
    </tr>
  );
};
