import { Tooltip } from "@web/ui";

import FluctuatingPriceIcon from "../icons/FluctuatingPrice.svg";
import WarningIcon from "../icons/Warning.svg";
import { ProductSku } from "../models";

interface Props {
  priceType?: ProductSku["priceType"];
}

export const PriceTypeFlag: React.FC<Props> = ({ priceType = "DEFAULT" }) => {
  return (
    <>
      {priceType === "NON_CONTRACTED" && (
        <Tooltip
          tooltipText={
            "This item does not have a contracted price. The shown price is offered by the supplier."
          }
        >
          <img
            data-testid="price-type-flag-warning"
            src={WarningIcon}
            alt="warning icon"
            className="min-w-[30px]"
          />
        </Tooltip>
      )}
      {priceType === "CONTRACTED_VARIABLE" && (
        <Tooltip tooltipText={"The price of this item is contracted but may fluctuate"}>
          <img
            data-testid="price-type-flag-fluctuating"
            src={FluctuatingPriceIcon}
            alt="fluctuating price icon"
            className="min-w-[30px]"
          />
        </Tooltip>
      )}
    </>
  );
};
