import { ClipboardCopyIcon } from "@heroicons/react/outline";

import { useModalContext } from "@web/common/contexts/ModalContext";
import { OrderRequisition } from "@web/common/network/model";
import { RegularButton } from "@web/ui";

import { CheckItemsAvailabilityModal } from "../modals/Modal";

interface Props {
  order: OrderRequisition;
}

export const ContinueWithDraftButton: React.FC<Props> = ({ order }) => {
  const { openModal } = useModalContext();
  const handleContinueWithDraftClick = () => {
    openModal(<CheckItemsAvailabilityModal draft={order} />);
  };

  return (
    <RegularButton
      className="ml-auto mr-4"
      variant="primary"
      size="large"
      label="Continue with Draft"
      LeadingIcon={ClipboardCopyIcon}
      onClick={handleContinueWithDraftClick}
      data-testid="continueWithDraftButton"
    />
  );
};
