import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FieldError, useForm } from "react-hook-form";

import { Vessel } from "@web/common/models/Vessel";
import { Input, Paragraph, RegularButton, Select } from "@web/ui";

import { createVessel } from "../../../api/createVessel";
import { updateVessel } from "../../../api/updateVessel";
import { AppFleet } from "../../../typegens";
import { VesselFormType, VesselSchema } from "./schema";

interface Props {
  vessel?: Vessel;
  fleets: AppFleet[];
  onUpdate: () => void;
  onCancel: () => void;
}

export const EditVesselModal: React.FC<Props> = ({ vessel, fleets, onUpdate, onCancel }) => {
  const fleetOptions = fleets.map((fleet) => ({
    label: fleet.name,
    value: fleet.id as string,
  }));
  const defaultFleetValue = fleetOptions.find((f) => f.value === vessel?.fleetId) as {
    label: string;
    value: string;
  };

  const onSubmit = (data: VesselFormType) => {
    (vessel ? updateVessel(vessel.id, data) : createVessel(data)).then(() => onUpdate());
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      vesselName: vessel ? vessel.name : "",
      imoNumber: vessel ? vessel.imoNumber : "",
      fleet: defaultFleetValue,
      contactName: vessel ? (vessel.contactInformation ? vessel.contactInformation.name : "") : "",
      contactEmail: vessel
        ? vessel.contactInformation
          ? vessel.contactInformation.email
          : ""
        : "",
    },
    resolver: zodResolver(VesselSchema),
  });

  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg py-4 px-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="sm:flex sm:items-start py-4 pr-4">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <Paragraph size="100">
            {vessel ? "Update Vessel Information" : "Vessel Information"}
          </Paragraph>
          <div className="mt-2">
            {!!vessel && (
              <Paragraph size="200" color="text-textIcon-blackSecondary">
                Change the information of this vessel
              </Paragraph>
            )}
          </div>
        </div>
      </div>
      <form>
        <div className="flex">
          <div className="flex-1">
            <Controller
              name="vesselName"
              control={control}
              render={({ field }) => (
                <Input label="Vessel Name*" errorMessage={errors.vesselName?.message} {...field} />
              )}
            />
          </div>
          <div className="flex-1 ml-3  w-1/2">
            <Controller
              name="imoNumber"
              control={control}
              render={({ field }) => (
                <Input
                  label="IMO Number*"
                  leftBadge="IMO"
                  errorMessage={errors.imoNumber?.message}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex-1">
            <Controller
              name="fleet"
              control={control}
              render={({ field }) => (
                <Select
                  label="Fleet*"
                  options={fleetOptions}
                  errorMessage={(errors.fleet as FieldError)?.message}
                  className="pt-4"
                  isCompactVersion
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex-1 ml-3 w-1/2">
            <Controller
              name="contactName"
              control={control}
              render={({ field }) => (
                <Input
                  label="Contact Name*"
                  errorMessage={errors.contactName?.message}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex-1">
            <Controller
              name="contactEmail"
              control={control}
              render={({ field }) => (
                <Input
                  label="Contact Email*"
                  errorMessage={errors.contactEmail?.message}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex-1 ml-3  w-1/2"></div>
        </div>
        <hr />
        <div className="flex items-end justify-end mt-5">
          <div className="ml-auto">
            <RegularButton
              className="ml-auto"
              variant="secondary"
              size="large"
              label="Cancel"
              onClick={() => {
                onCancel();
              }}
            />
          </div>
          <div className="ml-4">
            <RegularButton
              variant="primary"
              size="large"
              label={vessel ? "Update Vessel" : "Add Vessel"}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
