import { ReactNode } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

interface Props {
  children: ReactNode;
  tooltipText: string;
  className?: string;
}

export const Tooltip = ({ children, tooltipText, className }: Props) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip();
  return (
    <div
      className={className ?? ""}
      data-tip
      data-for="registerTip"
      role="tooltip"
      ref={setTriggerRef}
    >
      {children}
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              "tooltip-container bg-black text-neutral_0 text-sm rounded-md px-2 py-1 z-20",
          })}
        >
          {/* TODO #3948: style tooltip arrow
          <div {...getArrowProps({ className: "tooltip-arrow" })} /> */}
          {tooltipText}
        </div>
      )}
    </div>
  );
};
