import { ReactNode } from "react";

import { Label, Paragraph } from "../../atoms";

interface Props {
  children: ReactNode;
  subtitle: string;
  title: string;
  testId?: string;
}

export const FormSection = ({ children, subtitle, title, testId }: Props) => {
  return (
    <div {...(testId && { "data-testid": `${testId}` })}>
      <p>
        <Label size="100">{title}</Label>
      </p>
      <Paragraph size="300" color="text-textIcon-blackSecondary" className="mb-5">
        {subtitle}
      </Paragraph>
      {children}
    </div>
  );
};
