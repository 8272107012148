import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon, MinusIcon, PlusIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Category } from "@web/common/models";
import { Label, LinkButton, Paragraph } from "@web/ui";

interface Props {
  heading: string;
  options: Array<Category>;
  onListExpand?(): void;
  categoryRoute: string;
}

export const CategoryFilter: React.FC<Props> = ({
  heading,
  options,
  onListExpand,
  categoryRoute,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const expandList = (e: React.FormEvent) => {
    e.preventDefault();
    onListExpand && onListExpand();
    setExpanded(true);
  };

  const visibleOptions = expanded ? options : options.slice(0, 5);
  const displayShowMoreButton = options.length > 5 && !expanded;

  return (
    <dl className="w-80">
      <Disclosure as="div" defaultOpen={true}>
        {({ open }) => (
          <>
            <dt>
              <Disclosure.Button className="w-full flex justify-between items-center text-text-whiteDisabled focus:outline-none cursor-pointer">
                <Label size="200" color="text-textIcon-blackPrimary">
                  {heading}
                </Label>
                {open ? (
                  <MinusIcon aria-hidden="true" className="ml-4 w-4 h-4 text-text-whiteDisabled" />
                ) : (
                  <PlusIcon aria-hidden="true" className="ml-4 w-4 h-4 text-text-whiteDisabled" />
                )}
              </Disclosure.Button>
            </dt>
            <Disclosure.Panel as="dd" className="mt-2 pr-12 pl-2 w-full">
              {visibleOptions.map((category: Category) => (
                <Link
                  to={`${categoryRoute}/${category.id}`}
                  key={category.id}
                  className="flex justify-between content-center items-center pt-3 mx-4 cursor-pointer"
                >
                  <Paragraph size="200" color="text-textIcon-blackSecondary">
                    {category.name}
                  </Paragraph>
                  <div className="h-full">
                    <ChevronRightIcon className="ml-4 w-4 h-4 text-text-whiteDisabled" />
                  </div>
                </Link>
              ))}
              {displayShowMoreButton && (
                <LinkButton
                  className="mt-4"
                  size="large"
                  variant="primary"
                  label={t("common.components.categoryFilter.showMore")}
                  onClick={expandList}
                />
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </dl>
  );
};
