import { Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/solid";
import { Fragment, useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { Category } from "@web/models";

import { RegularButton } from "../../atoms";
import { containerPadding } from "../../organisms/";

type CategorySelectorProps = {
  categories: Category[];
  subCategoryRoute: string;
  onSelectToggle?(state: boolean): void;
  onCategoryChange?(category: Category): void;
};

export const CategorySelector: React.FC<CategorySelectorProps> = ({
  categories,
  onSelectToggle,
  onCategoryChange,
  subCategoryRoute,
}) => {
  const categoriesWithProducts = categories.filter(
    (category) => category.productsNumber && category.productsNumber > 0
  );

  const [current, setCurrent] = useState(categoriesWithProducts[0]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    onSelectToggle && onSelectToggle(!isOpen);
  };

  const setCategory = useCallback(
    (cat: Category) => {
      setCurrent(cat);
      onCategoryChange && onCategoryChange(cat);
    },
    [onCategoryChange]
  );

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <>
        <div>
          <RegularButton
            variant="secondary"
            size="large"
            label={isOpen ? "Close" : "Categories"}
            LeadingIcon={isOpen ? XIcon : MenuIcon}
            onClick={toggleOpen}
          />
        </div>
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Menu.Items
            static
            className="origin-top-left fixed mt-2 left-0 w-screen shadow-lg bg-neutral_0"
          >
            <div className={`w-full ${containerPadding}`}>
              <div className="bg-neutral_0 left-0 w-full max-h-full py-4">
                <div>
                  <nav className="flex mb-3 overflow-y-auto min-h-min max-h-full" aria-label="Tabs">
                    {categoriesWithProducts.map((category) => {
                      return (
                        <a
                          key={category.id}
                          onClick={() => setCategory(category)}
                          className={`${
                            category === current
                              ? "text-textIcon-blackPrimary border-primaryDefault"
                              : "text-textIcon-blackSecondary hover:text-textIcon-blackSecondary hover:border-neutral_300"
                          }
                            whitespace-nowrap py-3 px-4 border-b-2 font-medium text-sm cursor-pointer
                          `}
                        >
                          {category.name}
                        </a>
                      );
                    })}
                  </nav>
                </div>
                <div className="flex flex-col max-h-[300px] flex-wrap -mx-2 w-full overflow-x-auto">
                  {categoriesWithProducts.map((category) => {
                    return (
                      category === current &&
                      category.children?.map((subCategory) => {
                        if (subCategory.productsNumber && subCategory.productsNumber > 0) {
                          return (
                            <Link
                              to={`${subCategoryRoute}/${subCategory.id}`}
                              key={subCategory.id}
                              onClick={toggleOpen}
                              className="my-1 px-2 w-1/4"
                            >
                              <div className="p-2 text-textIcon-blackPrimary flex hover:bg-neutral_200 rounded-md cursor-pointer whitespace-nowrap w-14">
                                <div className="text-sm">{subCategory.name}</div>
                              </div>
                            </Link>
                          );
                        }
                      })
                    );
                  })}
                </div>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </>
    </Menu>
  );
};
