import { CustomerFormType } from "../components/modals/EditCustomerModal/schema";
import { ApiResponse, handleResponse } from "../store/utils";
import { AppCustomer, MainAppService } from "../typegens";

export const updateCustomer = async (
  id: string,
  form: CustomerFormType
): Promise<ApiResponse<AppCustomer>> => {
  const result = await handleResponse(() =>
    MainAppService.saveOrUpdateCustomer({
      id,
      requestBody: {
        id: id,
        name: form.customerName,
        companyId: form.companyId,
        adminId: form.adminId,
        adminReferenceId: form.adminReferenceId,
      },
    })
  );

  return result;
};
