import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Vessel } from "@web/common/models/Vessel";
import { Label, Paragraph } from "@web/ui";

import { useVesselsQuery } from "../../../hooks/useVesselsQuery";
import Boat from "../../../icons/Boat.svg";
import trackingCategories from "../../../utils/trackingCategories";
import trackingEvents from "../../../utils/trackingEvents";

export const ShipSelector = () => {
  const { t } = useTranslation();
  const [currentVessel, setCurrentVessel] = useState<Vessel>();
  const vesselsQuery = useVesselsQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const onChangeHandler = (newVessel: Vessel | undefined) => {
    const currentParams = new URLSearchParams(location.search);
    newVessel
      ? currentParams.set("vesselId", newVessel.id || "") // Aligned to currently generated type
      : currentParams.delete("vesselId");
    navigate({
      pathname: location.pathname,
      search: currentParams.toString(),
    });
    ReactGA.event({
      category: trackingCategories.PURCHASER_APP,
      action: trackingEvents.VESSEL_CHANGED,
      label: newVessel?.id,
    });
    setCurrentVessel(newVessel);
  };

  return (
    <Listbox value={currentVessel} onChange={onChangeHandler} data-testid="shipSelector">
      {({ open }: { open: boolean }) => (
        <>
          <div className="relative flex items-center content-center w-20">
            <Listbox.Button className="w-full flex">
              <div
                data-testid="ship-selector"
                className="flex items-center pl-2 cursor-pointer flex-1 h-7"
              >
                {/* Rounded Icon */}

                <div
                  className={`${
                    currentVessel ? "bg-primaryDefault" : "bg-textIcon-blackSecondary"
                  } ${"p-2 rounded-full w-6 h-6 shrink-0"}`}
                >
                  <img src={Boat} className="w-4" alt="boat" />
                </div>
                {/* Label */}
                <div className="pl-3 text-center">
                  <Label size="200" className="cursor-pointer">
                    {currentVessel?.name || t("layouts.base.overview")}
                  </Label>
                  {!currentVessel && (
                    <Paragraph
                      size="300"
                      color="text-textIcon-blackSecondary"
                      className="cursor-pointer"
                    >
                      {t("layouts.base.allVessels")}
                    </Paragraph>
                  )}
                </div>
                <ChevronDownIcon
                  className={`h-5 w-5 text-textIcon-blackSecondary ml-auto transition duration-500 ${
                    open ? "rotate-180" : ""
                  }`}
                  aria-hidden="true"
                />
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute left-0 top-7 z-10 mt-1 w-full bg-neutral_0 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                <Listbox.Option className="select-none relative py-2 px-4" value={undefined}>
                  {({ selected }: { selected: boolean }) => (
                    <>
                      <div className="flex flex-col items-start cursor-pointer">
                        <div className="flex justify-between items-center w-full">
                          <div className="flex justify-center items-center mr-2">
                            <div className="bg-textIcon-blackSecondary p-2 rounded-full mr-4">
                              <img src={Boat} className="w-4" alt="boat" />
                            </div>
                            <Paragraph size="200" className="cursor-pointer">
                              {t("layouts.base.overview")}
                            </Paragraph>
                          </div>

                          {selected && (
                            <CheckIcon className="h-5 w-5 text-primaryDefault" aria-hidden="true" />
                          )}
                        </div>
                      </div>
                      <hr className="mt-2" />
                    </>
                  )}
                </Listbox.Option>

                {vesselsQuery.isSuccess &&
                  vesselsQuery.data.map((vessel, idx) => (
                    <Listbox.Option
                      key={idx}
                      className="select-none relative py-2 px-4"
                      value={vessel}
                    >
                      {({ selected }: { selected: boolean }) => (
                        <div className="flex flex-col items-start cursor-pointer">
                          <div className="flex justify-between items-center w-full">
                            <div className="flex justify-center items-center mr-2">
                              <div className="bg-primaryDefault p-2 rounded-full mr-4 shrink-0">
                                <img src={Boat} className="w-4" alt="boat" />
                              </div>
                              <Paragraph size="200" className="cursor-pointer">
                                {vessel.name}
                              </Paragraph>
                            </div>

                            {selected && (
                              <CheckIcon
                                className="h-5 w-5 text-primaryDefault"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
