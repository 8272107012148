import { SearchIcon, XIcon } from "@heroicons/react/solid";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Vessel } from "@web/common/models/Vessel";
import { CircledImage, Heading, Input, Loading, Paragraph, vesselPalette } from "@web/ui";
import { useDebounce } from "@web/utils";

import routes from "../../../config/routes";
import { useVesselsQuery } from "../../../hooks/useVesselsQuery";
import VesselIcon from "../../../icons/VesselIcon.svg";
import { clearGatherBasket, setGatherVessel, useAppDispatch } from "../../../store";

type VesselsListProps = {
  search: string;
  closeModal: () => void;
  inputValue: string;
};

const VesselsList: React.FC<VesselsListProps> = ({ search, closeModal, inputValue }) => {
  const vesselsQuery = useVesselsQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleVesselSelect = useCallback(
    (vessel: Vessel) => {
      dispatch(clearGatherBasket());
      dispatch(setGatherVessel(vessel));
      closeModal();
      navigate(routes.preconfigureOrderSetup);
    },
    [dispatch, closeModal, navigate]
  );

  if (vesselsQuery.isPending || vesselsQuery.isFetching) {
    return <Loading />;
  }

  const searchPhrase = search.toLowerCase();
  const vessels = vesselsQuery.data || [];
  const filtered =
    search === "" ? vessels : vessels.filter((v) => v.name.toLowerCase().includes(searchPhrase));

  return (
    <>
      {filtered.length > 0 && (
        <ul
          className="max-h-80 overflow-y-auto overflow-x-hidden"
          data-testid="selectVessel_container"
        >
          {filtered.map((vessel) => (
            <li
              className="flex items-center p-2 hover:bg-neutral_200 rounded-md cursor-pointer"
              data-testid="selectVessel_vessel"
              key={vessel.id}
              onClick={() => handleVesselSelect(vessel)}
            >
              <CircledImage
                Icon={VesselIcon}
                size={5}
                hashKey={vessel.id}
                palette={vesselPalette}
              />
              <div className="ml-3">
                <Paragraph size="200">{vessel.name}</Paragraph>
              </div>
            </li>
          ))}
        </ul>
      )}
      {filtered.length === 0 && (
        <div className="m-3">
          <Paragraph
            size="100"
            className="text-textIcon-blackPrimary"
          >{`No results for "${inputValue}"`}</Paragraph>
          <Paragraph
            size="200"
            className="mt-2"
            /* color="text-text-whiteDisabled" */
            // TODO #6444: Fixme, there is no such color in tailwind config
          >
            Try a different search term or check the spelling.
          </Paragraph>
        </div>
      )}
    </>
  );
};

const DEBOUNCE_MS = 150;

type Props = {
  closeModal: () => void;
};

export const SelectVesselModal: React.FC<Props> = ({ closeModal }) => {
  const [inputValue, setInputValue] = useState("");
  const searchValue = useDebounce(inputValue, DEBOUNCE_MS);

  return (
    <div className="inline-block bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={closeModal}
        >
          <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col w-130 px-3 py-4">
        <Heading size="200">Select a vessel</Heading>
        <Paragraph size="200" color="text-textIcon-blackSecondary" className="mt-2">
          Select the vessel you want to order for
        </Paragraph>
        <Input
          prefixIcon={
            <SearchIcon className="h-4 w-4 text-textIcon-blackSecondary" aria-hidden="true" />
          }
          placeholder="Search vessel"
          value={inputValue}
          onChange={(v) => setInputValue(v.target.value)}
        />
        <VesselsList search={searchValue} closeModal={closeModal} inputValue={inputValue} />
      </div>
    </div>
  );
};
