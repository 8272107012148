import { useEffect } from "react";

import { useAuth0WithRedirect } from "@web/common/contexts/Auth0WithRedirect";

export const useLogout = () => {
  const { logout: auth0Logout } = useAuth0WithRedirect();

  const logout = () => {
    auth0Logout({ returnTo: window.location.origin });
    localStorage.setItem("logout", String(Date.now()));
  };

  const forceLogout = () => {
    auth0Logout({ returnTo: `${window.location.origin}/logout` });
    localStorage.setItem("logout", String(Date.now()));
  };

  useEffect(() => {
    // force logout in other tabs
    const syncLogout = (event: StorageEvent) => {
      if (event.key === "logout") {
        auth0Logout({ returnTo: `${window.location.origin}/logout` });
      }
    };

    window.addEventListener("storage", syncLogout);

    return () => window.removeEventListener("storage", syncLogout);
  }, [auth0Logout]);

  return { forceLogout, logout };
};
