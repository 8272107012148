import { Heading, Paragraph } from "@web/ui";
import { formatMoney } from "@web/utils";

import { Vessel } from "../../models/Vessel";
import { OrderRequisition } from "../../network/model";

interface Props {
  order: Pick<OrderRequisition, "priceModifiers" | "items" | "totalGrossAmount"> & {
    vessel: Pick<Vessel, "name" | "imoNumber">;
  };
}

export const PriceBox = ({ order }: Props) => {
  const pricesBeforePriceModifiers: number[] = [];
  order.items.forEach((value) => pricesBeforePriceModifiers.push(value.totalAmount.amount));

  const totalPriceBeforePriceModifiers = pricesBeforePriceModifiers.reduce((a, b) => a + b, 0);

  return (
    <div className="flex flex-col px-5 py-4 mb-3 rounded-lg border bg-neutral_0 shadow">
      <div className="pb-4">
        <Heading size="300">Price breakdown</Heading>
      </div>
      <div>
        <div className="mb-5">
          <div>
            <Paragraph size="200" color="text-textIcon-blackSecondary" className="mt-3">
              Catalog Items
            </Paragraph>
            <div className="flex">
              <Paragraph size="200">
                {order.items.length} {order.items.length === 1 ? "Line item" : "Line items"}
              </Paragraph>
              <Paragraph size="200" className="m-auto mr-0">
                {formatMoney({
                  amount: totalPriceBeforePriceModifiers,
                  currencyCode: order.totalGrossAmount.currencyCode,
                })}
              </Paragraph>
            </div>
          </div>
          {order.priceModifiers &&
            order.priceModifiers.map((priceModifier) => {
              return (
                <div key={priceModifier.id} className="mt-3">
                  <div className="flex justify-between">
                    <Paragraph
                      size="200"
                      color="text-textIcon-blackSecondary"
                      className="text-clip overflow-hidden w-[70%]"
                      title={priceModifier.name}
                    >
                      {priceModifier.name}
                    </Paragraph>
                    <Paragraph size="200" className="mr-0 text-end">
                      {formatMoney({
                        amount:
                          priceModifier.changeType === "DISCOUNT"
                            ? -priceModifier.amount.amount
                            : priceModifier.amount.amount,
                        currencyCode: priceModifier.amount.currencyCode,
                      })}
                    </Paragraph>
                  </div>
                </div>
              );
            })}
        </div>
        <hr />
        <div className="flex">
          <Paragraph size="200" className="mt-3">
            Total
          </Paragraph>
          <Paragraph size="200" className="mt-3 m-auto mr-0">
            {formatMoney({
              amount: order.totalGrossAmount.amount,
              currencyCode: order.totalGrossAmount.currencyCode,
            })}
          </Paragraph>
        </div>
        <div className="mt-3"></div>
      </div>
    </div>
  );
};

export default PriceBox;
