import { PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

import { useModalContext } from "@web/common/contexts/ModalContext";
import { Vessel } from "@web/common/models/Vessel";
import { CircledImage, Heading, LinkButton, Loading, RegularButton, vesselPalette } from "@web/ui";

import { deleteVessel } from "../../api/deleteVessel";
import UpdateVesselModal from "../../components/modals/EditVesselModal/index";
import InviteVesselModal from "../../components/modals/InviteVesselModal";
import { RemoveItemModal } from "../../components/modals/RemoveItemModal";
import ManageTable from "../../components/tables/ManageTable";
import ManageTableEmpty from "../../components/tables/ManageTableEmpty";
import ManageTableHeaders from "../../components/tables/ManageTableHeaders";
import { useAppScopes } from "../../hooks/useAppScopes";
import { useFleetsQuery } from "../../hooks/useFleetsQuery";
import { useVesselsQuery } from "../../hooks/useVesselsQuery";
import VesselIcon from "../../icons/VesselIcon.svg";
import BaseLayout from "../../layouts/Base";
import { AppFleet } from "../../typegens";

interface ManageVesselRowProps {
  vessel: Vessel;
  fleets: AppFleet[];
  handleOnInviteClick: (vessel: Vessel) => void;
  handleOnUpdateClick: (vessel: Vessel) => void;
  handleOnRemoveClick: (vessel: Vessel) => void;
}

const ManageVesselRow: React.FC<ManageVesselRowProps> = ({
  vessel,
  fleets,
  handleOnInviteClick,
  handleOnUpdateClick,
  handleOnRemoveClick,
}) => {
  const { t } = useTranslation();
  const canWriteVessels = useAppScopes("write:vessels");
  const fleet = fleets.find((fleet) => fleet.id === vessel.fleetId);

  return (
    <tr>
      <td className="pl-5 py-4 h-full">
        <div className="flex items-center">
          <CircledImage Icon={VesselIcon} size={7} hashKey={vessel.id} palette={vesselPalette} />
          <div className="ml-4">
            <div className="text-sm font-medium text-textIcon-blackPrimary">{vessel.name}</div>
            <div className="text-sm text-textIcon-blackSecondary">{vessel.imoNumber}</div>
          </div>
        </div>
      </td>
      <td className="pl-5 py-4 max-w-0">
        <div className="text-sm text-textIcon-blackPrimary whitespace-nowrap truncate">
          {fleet?.name}
        </div>
        <div className="text-sm text-textIcon-blackSecondary whitespace-nowrap truncate">
          {fleet?.customerName}
        </div>
      </td>
      <td className="pl-5 py-4 whitespace-nowrap">
        <div className="text-sm text-textIcon-blackPrimary">{vessel.contactInformation?.name}</div>
        <div className="text-sm text-textIcon-blackSecondary">
          {vessel.contactInformation?.email}
        </div>
      </td>
      <td className="px-5 py-4 whitespace-nowrap">
        <span className="inline-flex text-xs leading-5 font-semibold min-h-full">
          <RegularButton
            variant="secondary"
            size="large"
            label={t("pages.manageVessels.vesselsList.invite")}
            onClick={() => handleOnInviteClick(vessel)}
          />
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        {canWriteVessels && (
          <div className="flex gap-2 justify-end content-center">
            <LinkButton
              size="large"
              variant="primary"
              label={t("pages.manageVessels.vesselsList.update")}
              onClick={() => handleOnUpdateClick(vessel)}
            />
            |
            <LinkButton
              size="large"
              variant="danger"
              label={t("pages.manageVessels.vesselsList.remove")}
              onClick={() => handleOnRemoveClick(vessel)}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

interface ManageHeaderProps {
  handleOnAddClick: () => void;
}

const ManageHeader: React.FC<ManageHeaderProps> = ({ handleOnAddClick }) => {
  const { t } = useTranslation();
  const canWriteVessels = useAppScopes("write:vessels");

  return (
    <div className="p-5 border-b border-neutral_300 flex justify-between items-center">
      <Heading size="300">{t("pages.manageVessels.vesselsList.header")}</Heading>
      {canWriteVessels && (
        <RegularButton
          variant="primary"
          size="large"
          width="content"
          label={t("pages.manageVessels.vesselsList.addVessel")}
          LeadingIcon={PlusIcon}
          onClick={handleOnAddClick}
        />
      )}
    </div>
  );
};

const ManageVessels = () => {
  const { openModal, closeModal } = useModalContext();
  const { t } = useTranslation();
  const vesselsQuery = useVesselsQuery();
  const fleetsQuery = useFleetsQuery();

  if (!(vesselsQuery.isSuccess && fleetsQuery.isSuccess)) {
    return <Loading />;
  }

  const vessels = vesselsQuery.data;
  const fleets = fleetsQuery.data;

  const headerNames = [
    t("pages.manageVessels.vesselsList.name"),
    t("pages.manageVessels.vesselsList.fleet"),
    t("pages.manageVessels.vesselsList.contact"),
    "",
    "",
  ];

  const onUpdate = () => {
    closeModal();
    vesselsQuery.refetch();
  };

  const onCancel = () => {
    closeModal();
  };

  const onRemoveItem = (vessel: Vessel) => {
    deleteVessel(vessel.id).then(() => vesselsQuery.refetch());
  };

  const handleOnAddClick = () => {
    openModal(<UpdateVesselModal fleets={fleets} onUpdate={onUpdate} onCancel={onCancel} />);
  };

  const handleOnInviteClick = (vessel: Vessel) => {
    openModal(<InviteVesselModal vessel={vessel} />);
  };

  const handleOnUpdateClick = (vessel: Vessel) => {
    openModal(
      <UpdateVesselModal vessel={vessel} fleets={fleets} onUpdate={onUpdate} onCancel={onCancel} />
    );
  };

  const handleOnRemoveClick = (vessel: Vessel) => {
    openModal(
      <RemoveItemModal
        itemName={vessel.name}
        onRemoveItem={() => onRemoveItem(vessel)}
        closeModal={closeModal}
      />
    );
  };

  return (
    <BaseLayout noSidebar>
      <div className="rounded-lg bg-neutral_0 shadow flex flex-col">
        <ManageHeader handleOnAddClick={handleOnAddClick} />
        {vessels.length > 0 ? (
          <ManageTable
            colgroup={
              <colgroup>
                <col span={1} className="w-1/2" />
                <col span={1} className="w-2/3" />
                <col span={3} className="w-1/3" />
              </colgroup>
            }
            headerRows={<ManageTableHeaders headerNames={headerNames} />}
            bodyRows={vessels.map((vessel) => (
              <ManageVesselRow
                key={vessel.id}
                vessel={vessel}
                fleets={fleets}
                handleOnInviteClick={handleOnInviteClick}
                handleOnUpdateClick={handleOnUpdateClick}
                handleOnRemoveClick={handleOnRemoveClick}
              />
            ))}
          />
        ) : (
          <ManageTableEmpty
            header={t("pages.manageVessels.vesselsList.noVesselsHeader")}
            message={t("pages.manageVessels.vesselsList.noVesselsMessage")}
          />
        )}
      </div>
    </BaseLayout>
  );
};

export default ManageVessels;
