import { useFlag } from "@unleash/proxy-client-react";
import groupBy from "lodash/groupBy";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { EmptyBasketUI, LineItemUI, SupplierSubBasket } from "@web/common/views/Basket/components";
import { containerPadding } from "@web/ui";

import { RootState, clearOrderComments, setOrderComments, useAppDispatch } from "src/store";

import { BasketUI } from "../../components/Basket";
import routes from "../../config/routes";
import { useSetQuantity } from "../../hooks/useSetQuantity";
import { BasketHeader } from "./components/BasketHeader";

export const GatherBasketPage: React.FC = () => {
  const { vessel, port, basket, orderComments } = useSelector((state: RootState) => state.gather);
  const dispatch = useAppDispatch();
  const hasMultiSupplierFeature = useFlag("multi-supplier");
  const lineItems = useMemo(() => Object.values(basket.catalogItems), [basket.catalogItems]);
  const lineItemsBySupplier = useMemo(
    () => groupBy(lineItems, (item) => item.sku.supplier?.supplierId),
    [lineItems]
  );

  const handleOrderCommentDelete = useCallback(() => {
    dispatch(clearOrderComments());
  }, [dispatch]);

  const handleOrderCommentSave = useCallback(
    (comment: string) => {
      dispatch(setOrderComments({ orderComments: comment }));
    },
    [dispatch]
  );

  const { setQuantity } = useSetQuantity();

  if (vessel === undefined || port === undefined) {
    return <Navigate to={routes.orders} />;
  }

  return (
    <div className="flex flex-col h-full">
      <BasketHeader />
      <div className="bg-neutral_100 h-full ">
        <div className={`py-5 ${containerPadding} flex justify-center`}>
          {lineItems.length > 0 ? (
            <BasketUI isRequisition={false} isRfqRequisition={false}>
              {hasMultiSupplierFeature
                ? Object.entries(lineItemsBySupplier).map(([supplierId, items]) => {
                    return (
                      <SupplierSubBasket
                        orderComment={orderComments}
                        key={supplierId}
                        lineItems={items}
                        onOrderCommentDelete={handleOrderCommentDelete}
                        onOrderCommentSave={handleOrderCommentSave}
                        productRoute={routes.gatherProduct}
                        setQuantity={setQuantity}
                        supplierId={supplierId}
                      />
                    );
                  })
                : lineItems.map((lineItem) => {
                    return (
                      <LineItemUI
                        initialQuantity={lineItem.quantity}
                        lineItem={lineItem}
                        key={lineItem.sku.id}
                        setQuantity={setQuantity}
                        productRoute={routes.gatherProduct}
                        isRestoreEnabled={false}
                      />
                    );
                  })}
            </BasketUI>
          ) : (
            <EmptyBasketUI homeRoute={routes.gatherCatalog} />
          )}
        </div>
      </div>
    </div>
  );
};
