import { useTranslation } from "react-i18next";

import { Paragraph } from "@web/ui";

import { ProductSku } from "../../../../models/ProductSku";

export interface Props {
  sku: ProductSku;
  isError?: boolean;
}

const MinimumOrderQuantity: React.FC<Props> = ({ sku, isError }) => {
  const { t } = useTranslation();

  const minimumOrderQuantity = sku.about?.generalInformation?.minimumOrderQuantity;
  const salesEntityQuantity = sku.salesEntityQuantity;
  const minimumQuantityNumber =
    minimumOrderQuantity !== undefined
      ? minimumOrderQuantity / salesEntityQuantity
      : 1 / salesEntityQuantity;

  if (!minimumOrderQuantity || minimumOrderQuantity <= 1) {
    return null;
  }

  const background = isError ? "bg-dangerBackground" : "bg-warningBackground";
  const textColor = isError ? "text-dangerDefault" : "text-warningDefault";

  return (
    <div>
      <div className={`${background} rounded-md my-1 px-1 py-2 flex flex-row justify-center`}>
        <Paragraph size="300" color={textColor} className="text-center">
          {isError
            ? t("common.cards.addToCart.minimumOrderQuantityError")
            : t("common.cards.addToCart.minimumOrderQuantity")}
          <br />
          <span className="text-textIcon-blackSecondary">{minimumQuantityNumber}</span> (
          {`${minimumOrderQuantity} ${sku.measurementUnit}`})
        </Paragraph>
      </div>
      {isError && (
        <Paragraph size="300" color="text-textIcon-blackSecondary" className="text-center">
          {t("common.cards.addToCart.pleaseSelectLargerQty")}
        </Paragraph>
      )}
    </div>
  );
};

export default MinimumOrderQuantity;
