import { useParams } from "react-router-dom";

import { IntersectionMonitor } from "@web/common";
import { ProductCard } from "@web/common/components/ProductCard";
import { Product } from "@web/common/models/Product";
import { Loading, Paragraph } from "@web/ui";

import routes from "../../../config/routes";
import useProducts from "../../../hooks/useProducts";

const ProductsList: React.FC = () => {
  const { id } = useParams() as { id: string };
  const productsResponse = useProducts(id);

  if (productsResponse.isFetching || productsResponse.isPending) {
    return <Loading />;
  }

  const items = productsResponse.isSuccess
    ? productsResponse.data.pages.flatMap((p) => p.items)
    : [];

  if (items.length === 0) {
    return (
      <div className="flex-grow flex items-center">
        <div className="flex flex-grow justify-center items-center flex-col">
          <div className="text-center">
            <Paragraph size="100">No Results</Paragraph>
            <Paragraph size="200" className="text-text-whiteDisabled pt-4">
              Try removing or adjusting your filters
            </Paragraph>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ul className="grid grid-cols-1 gap-2 lg:grid-cols-2 w-full pl-8">
        {items.map((product: Product) => (
          <ProductCard
            key={product.id}
            product={product}
            productDetailsRoute={routes.gatherProduct}
          />
        ))}
      </ul>
      {productsResponse.hasNextPage && (
        <IntersectionMonitor onEnter={() => productsResponse.fetchNextPage()}>
          <div className="h-13 max-w-6xl">{productsResponse.isFetchingNextPage && <Loading />}</div>
        </IntersectionMonitor>
      )}
    </>
  );
};

export default ProductsList;
