import { OrderRequisition } from "@web/common/network/model";

import { GetOrderRequisitionsListParams } from "../models/GetOrderRequisitionsListParams";
import { LocalOrderReqService } from "../services/LocalOrderReqService";
import { MainAppService } from "../typegens";

export const getOrderRequisitionsList = async ({
  cursor = "",
  vesselId,
  status,
  closed,
}: GetOrderRequisitionsListParams): Promise<{
  items: OrderRequisition[];
  cursor: string | undefined;
  requisitionsWaitingForApproval: number;
}> => {
  const result = await MainAppService.getAllOrderRequisitions({ status, cursor, vesselId, closed });

  return {
    items: result.items.map((o) => LocalOrderReqService.toOrderRequisition(o)),
    cursor: result.cursor,
    requisitionsWaitingForApproval: result.requisitionsWaitingForApproval,
  };
};
