import Breadcrumbs from "@web/common/components/Breadcrumbs";

import routes from "../../config/routes";
import { HomeCategories } from "./components/HomeCategories";

export const GatherCatalogPage: React.FC = () => {
  return (
    <>
      <Breadcrumbs catalogHomeRoute={routes.gatherCatalog} catalogIdRoute={routes.gatherCatalog} />
      <HomeCategories />
    </>
  );
};
