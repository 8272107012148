import { DefaultError, InfiniteData, QueryKey, useInfiniteQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import {
  Sorting,
  getSortingString,
  useSearchSortingHelper,
} from "@web/common/hooks/useSearchHelpers";

import { RootState } from "../store";
import { AppProduct, ProductsService } from "../typegens";

type ProductsListResponse = { items: Array<AppProduct>; cursor?: string };

const getProductsList = async ({
  portId,
  vesselId,
  categoryId,
  cursor,
  leadTime,
  priceMax,
  priceMin,
  sorting,
}: {
  cursor?: string;
  portId: string;
  vesselId: string;
  categoryId: string;
  leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
  priceMax?: string;
  priceMin?: string;
  sorting?: Sorting;
}) => {
  const resp = await ProductsService.getProductsByCategoryId({
    portId,
    vesselId,
    categoryId: encodeURIComponent(categoryId),
    sort: getSortingString(sorting),
    priceMin,
    priceMax,
    leadTime,
    cursor,
  });
  return resp;
};

export const useProductListQuery = (
  {
    portId,
    vesselId,
    categoryId,
    leadTime,
    priceMax,
    priceMin,
    sorting,
  }: {
    portId?: string;
    vesselId: string;
    categoryId: string;
    leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
    priceMax?: string;
    priceMin?: string;
    sorting?: Sorting;
  },
  settings: Record<string, unknown>
) =>
  useInfiniteQuery<
    ProductsListResponse,
    DefaultError,
    InfiniteData<ProductsListResponse>,
    QueryKey,
    string
  >({
    queryKey: ["productList", sorting, portId, categoryId, leadTime, priceMin, priceMax, vesselId],
    queryFn: ({ pageParam }) =>
      getProductsList({
        cursor: pageParam,
        portId: portId || "",
        vesselId,
        categoryId,
        leadTime,
        priceMax,
        priceMin,
        sorting,
      }),
    initialPageParam: "",
    ...settings,
    getNextPageParam: (lastPage) => {
      if (lastPage.cursor !== "") {
        return lastPage.cursor;
      }
    },
    enabled: !!portId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

const useProducts = (categoryId: string) => {
  const { port, vessel } = useSelector((state: RootState) => state.gather);
  const { leadTime, priceMin, priceMax, sorting } = useSearchSortingHelper();

  return useProductListQuery(
    {
      priceMax,
      leadTime,
      categoryId,
      portId: port?.id,
      vesselId: vessel?.id || "",
      priceMin,
      sorting,
    },
    { enabled: !!vessel?.id && !!port?.id }
  );
};

export default useProducts;
