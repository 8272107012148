import { useTranslation } from "react-i18next";

import { Comment } from "@web/common/components/Comment";
import SidebarBox from "@web/common/components/SidebarBox";

import { OrderRequisition, Requisition } from "../network/model";

interface Props {
  requisition?: Requisition;
  order?: Pick<OrderRequisition, "comments">;
}

export const NoteBox = ({ order }: Props) => {
  const { t } = useTranslation();

  const filteredComments = order?.comments?.filter((comment) => comment.text !== "");

  const hasComments = !!filteredComments && filteredComments.length > 0;
  const title = t("common.components.noteBox.orderNotes");

  if (!hasComments) {
    return null;
  }

  return (
    <SidebarBox title={title} className="mt-5">
      <div className="py-3">
        {filteredComments.map((comment) => (
          <Comment
            name={comment.name}
            date={comment.date}
            text={comment.text}
            key={`${comment.text}-${comment.date}`}
          />
        ))}
      </div>
    </SidebarBox>
  );
};
export default NoteBox;
