import { XIcon } from "@heroicons/react/outline";
import { ExclamationIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";

import { Paragraph, RegularButton } from "@web/ui";

import { createDraft } from "src/api/createDraft";
import { updateDraft } from "src/api/updateDraft";
import { useDraft } from "src/hooks/useDraft";
import { DraftRequest } from "src/typegens";

import { RootState } from "../../../store";

interface Props {
  onBackToOverview: () => void;
  closeModal: () => void;
}

const BackToOrderOverviewConfirmationModal: React.FC<Props> = ({
  onBackToOverview,
  closeModal,
}) => {
  const { getDraftFromState } = useDraft();
  const draftId = useSelector((state: RootState) => state.gather.draftId);

  const handleCreateDraft = (draftRequest: DraftRequest) => {
    const makeRequest = draftId
      ? () => updateDraft({ draftId, requestBody: draftRequest })
      : createDraft;

    makeRequest(draftRequest).then(() => {
      onBackToOverview();
    });
  };

  const saveDraftAndGoBackToOverview = () => {
    handleCreateDraft(getDraftFromState());
    closeModal();
  };

  const handleBackToOverviewWithoutSavingDraft = () => {
    onBackToOverview();
    closeModal();
  };

  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={closeModal}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start py-4 pl-2 pr-4">
        <div className="mx-auto shrink-0 flex items-center justify-center p-2 rounded-full bg-dangerBackground sm:mx-0">
          <ExclamationIcon className="w-5 text-dangerDefault" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Paragraph size="100">Save basket as draft for later?</Paragraph>
          <div className="mt-2">
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              Leaving without saving will discard all your items currently in the basket.
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 flex flex-col gap-3 sm:flex-row-reverse">
        <RegularButton
          variant="primary"
          size="large"
          label="Save Basket as Draft"
          onClick={saveDraftAndGoBackToOverview}
        />
        <RegularButton
          variant="secondary"
          size="large"
          label="Discard Basket"
          onClick={handleBackToOverviewWithoutSavingDraft}
        />
      </div>
    </div>
  );
};

export default BackToOrderOverviewConfirmationModal;
