import { AppPurchaserConfiguration, MainAppService } from "../typegens";

export const getPurchaserVessels = async ({
  id,
}: {
  id: string;
}): Promise<AppPurchaserConfiguration> => {
  const result = await MainAppService.getPurchaser({ purchaserId: id });

  return result;
};
