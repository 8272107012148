import { useTranslation } from "react-i18next";

import { SkuStorageInformation } from "../../models/ProductSku";
import { ContractableSection, Description, Row } from "./SkuAttributesUtils";

interface Props {
  storage?: SkuStorageInformation;
}

export const StorageSection = ({ storage }: Props) => {
  const { t } = useTranslation();

  if (!storage || !Object.values(storage).some((v) => Boolean(v))) {
    return null;
  }

  return (
    <ContractableSection title={t("common.views.productDetails.storage")}>
      <Description label="Storage Information" value={storage.storageInformation} />
      <Row label={t("common.views.productDetails.minimumShelfLife")} value={storage.shelfLife} />
    </ContractableSection>
  );
};
