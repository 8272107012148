import { Menu } from "@headlessui/react";
import { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useSearchHelpers } from "@web/common";
import { BasketButton } from "@web/common/components/BasketButton";
import { Vessel } from "@web/common/models/Vessel";
import { SearchBar, containerPadding } from "@web/ui";

import { useGatherBasket } from "../../hooks/useGatherBasket";
import { CategorySelector, PortSelector } from "./components";

type Props = {
  searchRoute: string;
  basketRoute: string;
  subCategoryRoute: string;
  vessel: Vessel;
};

export const GatherControlBar = ({ searchRoute, basketRoute, subCategoryRoute, vessel }: Props) => {
  const { t } = useTranslation();
  const { totalPrice } = useGatherBasket();
  const { query, setQuery } = useSearchHelpers(searchRoute);
  const [typedQuery, setTypedQuery] = useState(query);
  const navigate = useNavigate();

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTypedQuery(e.target.value);
  };

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (typedQuery.length > 0) {
      setQuery(typedQuery);
    }
  };

  return (
    <div className="bg-neutral_0 border-neutral_300 w-full sticky z-10 top-0.5">
      <div className={`${containerPadding}`}>
        <div className="flex justify-between items-center content-center w-full bg-neutral_0 py-2">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <CategorySelector subCategoryRoute={subCategoryRoute} />
            </div>
          </Menu>
          <SearchBar
            placeholder={t("common.layouts.gatherNav.searchBarPlaceholder")}
            onQueryChange={handleQueryChange}
            onSubmit={handleSearchSubmit}
            query={typedQuery}
          />
          <div className="h-6 w-1 bg-neutral_200 mr-4" />
          <PortSelector vessel={vessel} />
          <div className="ml-4">
            <BasketButton
              grandTotal={totalPrice}
              onClick={() => {
                navigate(basketRoute);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
