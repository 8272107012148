import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { GatherShipBar } from "@web/common/components/navs/GatherShipBar";
import { useModalContext } from "@web/common/contexts/ModalContext";
import { containerPadding } from "@web/ui";

import BackToOrderOverviewConfirmationModal from "src/components/modals/Modal/BackToOrderOverviewConfirmationModal";

import { GatherControlBar } from "../../components/GatherControlBar/GatherControlBar";
import routes from "../../config/routes";
import { RootState } from "../../store";

export const Gather = () => {
  const vessel = useSelector((state: RootState) => state.gather.vessel);
  const { basket } = useSelector((state: RootState) => state.gather);
  const { openModal, closeModal } = useModalContext();
  const navigate = useNavigate();
  const handleBackToOverview = () => {
    if (Object.keys(basket.catalogItems).length > 0) {
      openModal(
        <BackToOrderOverviewConfirmationModal
          onBackToOverview={() => navigate(routes.orders)}
          closeModal={closeModal}
        />
      );
    } else {
      navigate(routes.orders);
    }
  };

  if (vessel === undefined) {
    return <Navigate to={routes.orders} replace />;
  }

  const basketRoute = basket.requisitionId
    ? `${routes.orderDetails}/${basket.requisitionId}/edit`
    : routes.gatherBasket;

  return (
    <header className="w-full h-full flex flex-col ">
      <GatherShipBar onBackToOverview={handleBackToOverview} vessel={vessel} />
      <GatherControlBar
        searchRoute={routes.gatherSearch}
        basketRoute={basketRoute}
        subCategoryRoute={routes.gatherCatalogDetail}
        vessel={vessel}
      />
      <div className={`bg-neutral_100 flex-grow flex flex-col`}>
        <div className={`${containerPadding} flex flex-col flex-grow`}>
          <Outlet />
        </div>
      </div>
    </header>
  );
};
