import { QueryClient } from "@tanstack/react-query";

import { isParsingError, isStorageError } from "@web/utils";

import { isApiError } from "src/utils/isApiError";

const shouldQueryUseErrorBoundary = (error: unknown) =>
  (isApiError(error) && error.request.method === "GET") ||
  (isStorageError(error) && error.request.access === "read") ||
  isParsingError(error);

const shouldMutationUseErrorBoundary = (error: unknown) => isParsingError(error);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      throwOnError: shouldQueryUseErrorBoundary,
    },
    mutations: {
      throwOnError: shouldMutationUseErrorBoundary,
    },
  },
});
