import { DefaultError, useMutation } from "@tanstack/react-query";

import { approveQuotation } from "../api/approveQuotation";
import { ApiResponse } from "../store";
import { AppOrderRequisition, AppQuotationApproveRequest } from "../typegens";
import { useSuccessCreationCallback } from "./useSuccessCreationCallback";

export const useApproveQuotationMutation = () => {
  const successCb = useSuccessCreationCallback();

  return useMutation<
    ApiResponse<AppOrderRequisition>,
    DefaultError,
    { id: string; requestBody: AppQuotationApproveRequest }
  >({
    mutationKey: ["approveQuotation"],
    mutationFn: approveQuotation,
    onSuccess: successCb,
  });
};
