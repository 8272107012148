import { useSelector } from "react-redux";

import { getTotalPriceInBasket } from "@web/common/utils/basket";

import { RootState } from "../store";

export const useGatherBasket = () => {
  const basket = useSelector((state: RootState) => state.gather.basket);
  const totalPrice = getTotalPriceInBasket(basket.catalogItems);

  return { catalogItems: basket.catalogItems, rfqItems: basket.rfqItems, totalPrice };
};
