import { MainAppService } from "src/typegens";

export const exportOrderToExcel = ({ orderId }: { orderId: string }): Promise<Blob> =>
  MainAppService.exportOrderRequisitionByIdToExcel(
    {
      id: orderId,
    },
    {
      headers: {
        Accept: "*/*",
      },
    }
  );
