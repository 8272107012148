import { useTranslation } from "react-i18next";

import { Heading, Label, RfqBadge, Tooltip } from "@web/ui";

interface Props {
  isWaitingForQuote: boolean;
  totalGrossAmount: string;
}

export const RfqTotalPriceInfo: React.FC<Props> = ({ isWaitingForQuote, totalGrossAmount }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center">
      <Label size="200" color="text-textIcon-blackSecondary">
        {t("common.components.rfqTotalPriceInfo.initialTotal")}
      </Label>
      <Tooltip tooltipText={t("common.rfqTooltip")}>
        <RfqBadge className="mx-4" />
      </Tooltip>
      {!isWaitingForQuote && (
        <>
          <Label size="200" color="text-primaryDefault">
            {t("common.components.rfqTotalPriceInfo.updatedTotal")}
          </Label>
          <Heading size="200" color="text-primaryDefault" className="pl-3">
            {totalGrossAmount}
          </Heading>
        </>
      )}
    </div>
  );
};
