import { useQuery } from "@tanstack/react-query";

import { AppProductSku, MainAppService } from "src/typegens";

export const useOrderProductVariants = ({ orderId }: { orderId: string }) =>
  useQuery<AppProductSku[]>({
    queryKey: ["productVariants", orderId],
    queryFn: async () => {
      const response = await MainAppService.getAvailableProducts({ orderId });
      return response.items;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    throwOnError: false,
  });
