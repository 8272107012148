import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Heading, Loading, Paragraph } from "@web/ui";

import routes from "src/config/routes";
import { usePurchasersQuery } from "src/hooks/usePurchasersQuery";

import BaseLayout from "../../layouts/Base";

const ManagePurchasers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const purchasersQuery = usePurchasersQuery();

  if (!purchasersQuery.isSuccess) {
    return <Loading />;
  }

  const purchasers = purchasersQuery.data;

  return (
    <BaseLayout noSidebar>
      <div>
        <Heading size="200">{t("pages.manageVessels.vesselsList.purchasers")}</Heading>
        <div className="mt-4">
          {[purchasers.fleets].map((purchaser) =>
            purchaser.map((el) => (
              <div key={el.id}>
                <Paragraph size="100">{el.name}</Paragraph>
                <div className="flex mb-4 flex-col">
                  {el.purchasers.map((purchaser) => (
                    <div
                      key={purchaser.purchaserId}
                      onClick={() => navigate(`${routes.manageUsers}/${purchaser.purchaserId}`)}
                      className="w-1/2 h-10 border rounded-md m-4 bg-neutral_0 cursor-pointer pt-4 pl-6"
                    >
                      <Paragraph size="100">{purchaser.name}</Paragraph>
                      <Paragraph size="200" color="text-textIcon-blackSecondary" className="mt-2">
                        {purchaser.numberOfVesselsInFleet}{" "}
                        {t("pages.manageVessels.vesselsList.vessels")}
                      </Paragraph>
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

export default ManagePurchasers;
