import { ArchiveIcon, InboxIcon } from "@heroicons/react/outline";
import { useFlag } from "@unleash/proxy-client-react";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation, useSearchParams } from "react-router-dom";

import { SectionHeaderLink, SidebarLayout } from "@web/ui";
import { serialiseQueryParams } from "@web/utils";

import routes from "src/config/routes";

import { FoldableSection, Header, Link, ShipSelector } from "./components";

// Do not destruct, so that browser `process` polyfill works properly. This is crucial for Storybook to work properly.
const VITE_GIT_HASH = import.meta.env.VITE_GIT_HASH as string;

const quotationsLinks = [
  { status: "SUPPLIER_QUOTE_PENDING" },
  { status: "SUPPLIER_QUOTE_PROVIDED" },
  { status: "QUOTE_REJECTED" },
  { status: "QUOTE_EXPIRED" },
];

// TODO #9356: Remove CLOSED status related code, since it ceased to exist, and convert back to a simple array
const getOrdersLinks = (isCloseAttributeEnabled: boolean): { status: string }[] => [
  { status: "SUPPLIER_PENDING" },
  { status: "SUPPLIER_ACKNOWLEDGED" },
  { status: "SUPPLIER_CONFIRMED" },
  ...(isCloseAttributeEnabled ? [{ status: "SUPPLIER_DELIVERED" }] : []),
  { status: "PENDING_CANCELLATION" },
  { status: "CANCELLED" },
  { status: "SUPPLIER_REJECTED" },
  ...(isCloseAttributeEnabled ? [] : [{ status: "CLOSED" }]),
];

interface Props {
  children: React.ReactNode;
  noSidebar?: boolean;
  noPadding?: boolean;
  requisitionsWaitingForApproval?: number;
}

const withPath = (entries: { status: string }[], params: Record<string, string | null>): Link[] => {
  return entries.map((entry: { status: string }) => ({
    ...entry,
    path: serialiseQueryParams({
      vesselId: params.vesselId,
      status: entry.status.toLowerCase(),
    }),
  }));
};

const BaseLayout: React.FC<Props> = ({
  children,
  noSidebar,
  noPadding,
  requisitionsWaitingForApproval,
}) => {
  const location = useLocation();
  const hasRfQFeature = useFlag("rfq");
  const [searchParams] = useSearchParams();
  const vesselId = searchParams.get("vesselId");
  const isCloseAttributeEnabled = useFlag("close-attribute");

  const requestLinks = [
    {
      status: "PENDING_PURCHASER_APPROVAL",
      badge: requisitionsWaitingForApproval,
    },
    {
      status: "PURCHASER_REJECTED",
    },
  ];

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location.pathname, location.search]);

  return (
    <SidebarLayout
      header={<Header />}
      sidebar={
        !noSidebar && (
          <div className="py-6 pl-8 pr-6 bg-neutral_100" data-testid="layoutSidebar">
            <ShipSelector />
            <hr className="mt-4 mb-2" />
            <div className="flex flex-col gap-4">
              <SectionHeaderLink
                text={isCloseAttributeEnabled ? "Active Orders" : "All"}
                Icon={InboxIcon}
                pathname={routes.orders}
                search={serialiseQueryParams({
                  vesselId,
                })}
              />
              <FoldableSection
                sectionName="Requests"
                links={withPath(requestLinks, { vesselId })}
                data-testid="layoutSidebar_requestsSection"
              />
              {hasRfQFeature && (
                <FoldableSection
                  sectionName="Quotations"
                  links={withPath(quotationsLinks, { vesselId })}
                  data-testid="layoutSidebar_quotationsSection"
                />
              )}
              <FoldableSection
                sectionName={isCloseAttributeEnabled ? "POs" : "Orders"}
                links={withPath(getOrdersLinks(isCloseAttributeEnabled), { vesselId })}
                data-testid="layoutSidebar_ordersSection"
              />
              {isCloseAttributeEnabled && (
                <SectionHeaderLink
                  text="Closed Orders"
                  Icon={ArchiveIcon}
                  pathname={routes.orders}
                  search={serialiseQueryParams({
                    vesselId,
                    closed: "true",
                  })}
                />
              )}
              <div className="text-textIcon-whiteSecondary text-s py-4 pl-2">
                Version: {VITE_GIT_HASH ?? "development"}
              </div>
            </div>
          </div>
        )
      }
      noPadding={noPadding}
    >
      {children}
    </SidebarLayout>
  );
};

export default BaseLayout;
