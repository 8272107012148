import { handleResponse } from "../store/utils";
import { AppPurchaserVesselRequest, MainAppService } from "../typegens";

export const updatePucharserVessels = async (requestBody: AppPurchaserVesselRequest) => {
  const result = await handleResponse(() =>
    MainAppService.addVesselsToPurchaser({
      requestBody,
    })
  );

  if ("data" in result) {
    return { data: result.data };
  }
  return result;
};
