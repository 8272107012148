import { CategoryFilter as CommonCategoryFilter } from "@web/common/components/CategoryFilter";
import { Category } from "@web/common/models";

import routes from "../config/routes";

interface Props {
  heading: string;
  options: Array<Category>;
}

const CategoryFilter: React.FC<Props> = ({ heading, options }) => {
  return (
    <CommonCategoryFilter
      heading={heading}
      options={options}
      categoryRoute={routes.gatherCatalog}
    />
  );
};

export default CategoryFilter;
