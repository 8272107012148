import { MailIcon } from "@heroicons/react/solid";

import { Label } from "@web/ui";

import LeadingIcon from "../../../icons/LeadingIcon.svg";

interface VesselContactProps {
  vesselName?: string;
  requester: string;
  onContactClick: () => void;
}

export const RequesterContact: React.FC<VesselContactProps> = ({
  vesselName,
  requester,
  onContactClick,
}) => {
  return (
    <div className="flex justify-between pb-4">
      <div className="flex w-full">
        <img src={LeadingIcon} className="w-6" alt="Leading Icon" />
        <div className="flex flex-col pl-4 truncate">
          <Label size="200" className="truncate">
            {vesselName}
          </Label>
          <Label size="200" color="text-textIcon-blackSecondary">
            {requester}
          </Label>
        </div>
        <div className="ml-auto w-5 text-text-whiteDisabled cursor-pointer flex-shrink flex">
          <MailIcon onClick={onContactClick} width="20px" />
        </div>
      </div>
    </div>
  );
};
