interface Props {
  headerRows: React.ReactNode;
  bodyRows: React.ReactNode;
  colgroup?: React.ReactNode;
}

const ManageTable: React.FC<Props> = ({ headerRows, bodyRows, colgroup }) => (
  <div className="flex flex-col">
    <div className="align-middle inline-block min-w-full">
      <div className="shadow overflow-hidden border-b border-neutral_300 sm:rounded-lg">
        <table className="w-full min-w-full divide-y divide-neutral_200 break-words">
          {colgroup}
          <thead className="bg-neutral_100">{headerRows}</thead>
          <tbody className="bg-neutral_0 divide-y divide-neutral_200">{bodyRows}</tbody>
        </table>
      </div>
    </div>
  </div>
);

export default ManageTable;
