import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useSystemMessage } from "src/hooks/useSystemMessage";

import routes from "../config/routes";
import { ApiResponse, clearGatherBasket, useAppDispatch } from "../store";
import { AppOrderRequisition } from "../typegens";

export const useSuccessCreationCallback = () => {
  const navigate = useNavigate();
  const { setSystemMessage } = useSystemMessage();
  const dispatch = useAppDispatch();

  return useCallback(
    (data: ApiResponse<AppOrderRequisition>) => {
      if ("error" in data) {
        setSystemMessage({
          type: "failure",
          message: "There was an error while processing your request",
        });
      }

      if ("data" in data) {
        navigate(`${routes.orderSuccess}/${data.data.id}`);
        // NOTE(ted): we need to wait to make sure new route was rendered
        setTimeout(() => dispatch(clearGatherBasket()), 0);
      }
    },
    [dispatch, navigate, setSystemMessage]
  );
};
