import { z } from "zod";

export const VesselSchema = z.object({
  vesselName: z.string().nonempty({ message: "Vessel name is required" }),
  imoNumber: z
    .string()
    .nonempty({ message: "IMO number is required" })
    .regex(RegExp("^(\\d{7})$"), "IMO number should be 7 digits long"),
  fleet: z.object({ label: z.string(), value: z.string() }),
  contactName: z.string().nonempty({ message: "Contact name is required" }),
  contactEmail: z.string().email(),
});

export type VesselFormType = z.infer<typeof VesselSchema>;
