import { DefaultError, useMutation } from "@tanstack/react-query";

import { approveRequisition } from "../api/approveRequisition";
import { ApiResponse } from "../store";
import { AppOrderRequisition, AppRequisitionApproveRequest } from "../typegens";
import { useSuccessCreationCallback } from "./useSuccessCreationCallback";

export const useApproveRequisitionMutation = () => {
  const successCb = useSuccessCreationCallback();

  return useMutation<
    ApiResponse<AppOrderRequisition>,
    DefaultError,
    { id: string; requestBody: AppRequisitionApproveRequest }
  >({
    mutationKey: ["approveRequisition"],
    mutationFn: approveRequisition,
    onSuccess: successCb,
  });
};
