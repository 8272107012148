import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Label } from "@web/ui";

import { ProductCategoryNode } from "../models/Product";

export interface Fragment {
  title: string;
  url?: string;
}

interface Props {
  path?: Array<Fragment>;
  categoryTree?: Array<ProductCategoryNode>;
  catalogHomeRoute: string;
  catalogIdRoute: string;
}

const Breadcrumbs: React.FC<Props> = ({ path, categoryTree, catalogHomeRoute, catalogIdRoute }) => {
  const { t } = useTranslation();
  const getUrl = (node?: ProductCategoryNode) =>
    node ? `${catalogIdRoute}/${node.id}` : catalogHomeRoute;

  const toPath = (nodes: Array<ProductCategoryNode>): Array<Fragment> =>
    nodes.map((node) => ({ title: node.name, url: getUrl(node) }));

  const getLink = (fragment: Fragment, last?: boolean) => {
    return (
      <Label
        size="200"
        className={last ? "text-textIcon-blackSecondary" : "text-text-whiteDisabled"}
      >
        {fragment.url ? <Link to={fragment.url}>{fragment.title}</Link> : fragment.title}
      </Label>
    );
  };

  const fragments = path || (categoryTree && toPath(categoryTree));

  return (
    <div className="mb-4 mt-2" aria-label="breadcrumbs">
      <Label size="200" className="text-text-whiteDisabled">
        <Link to={catalogHomeRoute}>{t("common.components.breadcrumb.pageTitle")}</Link>
      </Label>

      {fragments
        ? fragments.map((fragment, index) => (
            <Fragment key={fragment.title}>
              <Label size="300" className="text-text-whiteDisabled px-3">
                {">"}
              </Label>
              {getLink(fragment, index === fragments.length - 1)}
            </Fragment>
          ))
        : null}
    </div>
  );
};

export default Breadcrumbs;
