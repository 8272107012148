import { PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

import { Heading, RegularButton } from "@web/ui";

interface ManageHeaderProps {
  handleOnAddClick: () => void;
}

export const ManageHeader: React.FC<ManageHeaderProps> = ({ handleOnAddClick }) => {
  const { t } = useTranslation();

  return (
    <div className="p-5 border-b border-neutral_300 flex justify-between items-center">
      <Heading size="300">{t("pages.manageFleets.fleetsList.header")}</Heading>
      <RegularButton
        variant="primary"
        size="large"
        label={t("pages.manageFleets.fleetsList.addFleet")}
        LeadingIcon={PlusIcon}
        onClick={handleOnAddClick}
      />
    </div>
  );
};
