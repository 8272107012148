import { useCallback } from "react";

import { OrderRequisition } from "@web/common/network/model";

import {
  clearGatherBasket,
  setGatherBasketForRequisition,
  setGatherBasketForRfq,
  useAppDispatch,
} from "../store";

export const useFillBasketFromRequisition = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (orderRequisition: OrderRequisition) => {
      dispatch(clearGatherBasket());

      const basePayload = {
        requisitionId: orderRequisition.id,
        port: orderRequisition.port,
        vessel: orderRequisition.vessel,
      };

      if (orderRequisition.isRfqRequisition) {
        dispatch(
          setGatherBasketForRfq({
            items: orderRequisition.rfqItems || [],
            ...basePayload,
          })
        );
      } else {
        dispatch(
          setGatherBasketForRequisition({
            isQuotation: orderRequisition.type === "QUOTATION",
            items: orderRequisition.items,
            ...basePayload,
          })
        );
      }
    },
    [dispatch]
  );
};
