import { createReducer } from "@reduxjs/toolkit";

import { setScopeAction, setUserInactivitySpanAction } from "..";
import { AuthState } from "../models";

export const defaultAuthState: AuthState = {
  scope: [],
  userInactivitySpan: 0,
};

export const authReducer = createReducer(defaultAuthState, (builder) => {
  builder
    .addCase(setUserInactivitySpanAction, (state, action) => {
      state.userInactivitySpan = action.payload;
    })
    .addCase(setScopeAction, (state, action) => {
      state.scope = action.payload;
    });
});
