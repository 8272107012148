import { PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const MapHashLocationToRoute = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const navigate = useNavigate();
  const [wasHashMapped, setWasHashMapped] = useState(false);

  useEffect(() => {
    if (location.hash.startsWith("#/")) {
      navigate(location.hash.replace("#", ""), { replace: true });
    }

    setWasHashMapped(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return wasHashMapped ? <>{children}</> : null;
};
