import { Fragment } from "react";

import { ProductChange } from "@web/common/network/";
import { Label } from "@web/ui";

import { ChangedItemTotal } from "./ChangedItemTotal";

interface Props {
  changedItems?: Array<ProductChange>;
}

const tdStyle = "p-2 px-4 text-left shadow-sm relative";

const OrderChangeRow = ({ item }: { item: ProductChange }) => {
  let replacedItem = null;

  if (item.changeType === "REPLACED") {
    replacedItem = (
      <Fragment>
        <tr className="border bg-neutral_200">
          <td className={tdStyle}>
            <div className="flex flex-col">
              <Label size="200" className="text-textIcon-blackSecondary">
                {item.oldName}
              </Label>
            </div>
          </td>
          <td className={tdStyle}>
            <ChangedItemTotal item={item} isReplacedItem={true} />
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="h-2"></td>
        </tr>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <tr className={`border ${item.changeType === "REMOVED" ? "bg-neutral_200" : "bg-neutral_0"}`}>
        <td className={tdStyle}>
          <Label
            size="200"
            className="absolute right-full top-1/2 w-6 flex items-center justify-center -translate-y-1/2"
          >
            {item.lineNumber}
          </Label>
          <div className="flex flex-col">
            <Label
              size="200"
              className={`${item.changeType === "REMOVED" && "text-textIcon-blackSecondary"}`}
            >
              {item.name}
            </Label>
          </div>
        </td>
        <td className={tdStyle}>
          <ChangedItemTotal item={item} isReplacedItem={false} />
        </td>
      </tr>
      {replacedItem}
    </Fragment>
  );
};

export const OrderChangesTable = ({ changedItems }: Props) => {
  const hasItemWithLineItemNumber = changedItems?.find((item) => item.lineNumber) || "";

  return (
    <table
      className={`border-separate w-full pt-1 ${hasItemWithLineItemNumber ? "pl-5" : ""}`}
      style={{ borderSpacing: "0 0.25rem" }}
      data-testid="orderChangesTable"
    >
      <tbody>
        {changedItems?.map((item) => (
          <OrderChangeRow item={item} key={item.id} />
        ))}
      </tbody>
    </table>
  );
};
