import {
  CheckCircleIcon as CheckCircleIconOutline,
  DotsCircleHorizontalIcon as DotsCircleHorizontalIconOutline,
} from "@heroicons/react/outline";
import {
  ArchiveIcon,
  CheckCircleIcon,
  ClockIcon,
  DotsCircleHorizontalIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { Label, TextColors } from "@web/ui";

import { OrderRequisitionStatus } from "../network/model";

export interface PillProps {
  status: OrderRequisitionStatus;
  className?: string;
}

// TODO #9356: Remove CLOSED status related code, since it ceased to exist
const pillStyles: Record<OrderRequisitionStatus, React.ReactNode> = {
  PENDING_PURCHASER_APPROVAL: "bg-dangerBackground border-dangerDefault border border-dashed", // Requisition
  PURCHASER_REJECTED: "text-dangerDefault bg-dangerBackground", // Requisition
  CANCELLED: "text-warningDefault bg-warningBackground",
  CLOSED: "text-textIcon-blackSecondary bg-neutral_100",
  PENDING_CANCELLATION: "text-textIcon-blackSecondary bg-neutral_100",
  SUPPLIER_ACKNOWLEDGED:
    "text-primaryDefault bg-primaryBackground border-primaryDefault border border-dashed",
  SUPPLIER_CONFIRMED: "text-successDefault bg-successBackground",
  SUPPLIER_PENDING:
    "text-primaryDefault bg-primaryBackground border-primaryDefault border border-dashed",
  SUPPLIER_REJECTED: "text-dangerDefault bg-dangerBackground",
  SUPPLIER_DELIVERED: "text-successDefault bg-successBackground",
  QUOTE_EXPIRED: "text-textIcon-blackSecondary bg-neutral_100",
  QUOTE_REJECTED: "text-dangerDefault bg-dangerBackground",
  SUPPLIER_QUOTE_PENDING:
    "text-primaryDefault bg-primaryBackground border-primaryDefault border border-dashed",
  SUPPLIER_QUOTE_PROVIDED: "text-textIcon-blackSecondary bg-neutral_100",
  SUPPLIER_QUOTE_ACKNOWLEDGED:
    "text-primaryDefault bg-primaryBackground border-primaryDefault border border-dashed",
  SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED: "text-dangerDefault bg-dangerBackground",
  DRAFT_CREATED: "bg-primaryBackground",
  DRAFT_DELETED: "",
};

// TODO #9356: Remove CLOSED status related code, since it ceased to exist
const pillImage: Record<OrderRequisitionStatus, React.ReactNode> = {
  PENDING_PURCHASER_APPROVAL: (
    <DotsCircleHorizontalIcon className="text-dangerDefault rounded-full w-4 h-4" />
  ), // Requisition
  PURCHASER_REJECTED: (
    <XCircleIcon className="text-dangerDefault rounded-full bg-neutral_0 w-4 h-4" />
  ), // Requisition
  CANCELLED: <XCircleIcon className="text-warningDefault bg-transparent rounded-full w-4" />,
  CLOSED: <ArchiveIcon className="text-textIcon-blackSecondary w-4 h-4" />,
  PENDING_CANCELLATION: (
    <CheckCircleIconOutline className="text-transparent bg-transparent rounded-full border border-textIcon-blackDisabled w-3 h-3" />
  ),
  SUPPLIER_ACKNOWLEDGED: (
    <CheckCircleIconOutline className="rounded-full bg-transparent text-primaryDefault w-4 h-4" />
  ),
  SUPPLIER_CONFIRMED: (
    <CheckCircleIcon className="text-successDefault rounded-full bg-neutral_0 w-4 h-4" />
  ),
  SUPPLIER_PENDING: (
    <CheckCircleIconOutline className="rounded-full bg-transparent text-primaryDefault w-4 h-4" />
  ),
  SUPPLIER_REJECTED: (
    <XCircleIcon className="text-dangerDefault rounded-full bg-neutral_0 w-4 h-4" />
  ),
  SUPPLIER_DELIVERED: (
    <CheckCircleIcon className="text-successDefault rounded-full bg-neutral_0 w-4 h-4" />
  ),
  QUOTE_EXPIRED: <ClockIcon className="text-textIcon-blackDisabled w-4 h-4" />,
  QUOTE_REJECTED: <XCircleIcon className="text-dangerDefault rounded-full bg-neutral_0 w-4 h-4" />,
  SUPPLIER_QUOTE_PENDING: (
    <CheckCircleIconOutline className="text-transparent bg-transparent rounded-full border border-primaryDefault w-3 h-3" />
  ),
  SUPPLIER_QUOTE_PROVIDED: (
    <CheckCircleIconOutline className="text-textIcon-blackSecondary rounded-full w-4 h-4" />
  ),
  SUPPLIER_QUOTE_ACKNOWLEDGED: (
    <CheckCircleIconOutline className="text-transparent bg-transparent rounded-full border border-primaryDefault w-3 h-3" />
  ),
  SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED: (
    <XCircleIcon className="text-dangerDefault rounded-full bg-neutral_0 w-4 h-4" />
  ),
  DRAFT_CREATED: <DotsCircleHorizontalIconOutline className="w-4 h-4 text-primaryDefault" />,
  DRAFT_DELETED: "",
};

// TODO #9356: Remove CLOSED status related code, since it ceased to exist
const backgroundStyles: Record<OrderRequisitionStatus, string> = {
  PENDING_PURCHASER_APPROVAL: "bg-dangerBackground",
  PURCHASER_REJECTED: "bg-dangerBackground",
  CANCELLED: "bg-warningBackground",
  SUPPLIER_CONFIRMED: "bg-successBackground",
  SUPPLIER_REJECTED: "bg-dangerBackground",
  SUPPLIER_DELIVERED: "bg-successBackground",

  CLOSED: "",
  PENDING_CANCELLATION: "",
  SUPPLIER_PENDING: "",
  SUPPLIER_ACKNOWLEDGED: "",
  QUOTE_EXPIRED: "",
  QUOTE_REJECTED: "",
  SUPPLIER_QUOTE_PENDING: "",
  SUPPLIER_QUOTE_PROVIDED: "",
  SUPPLIER_QUOTE_ACKNOWLEDGED: "",
  SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED: "",
  DRAFT_CREATED: "",
  DRAFT_DELETED: "",
};

// TODO #9356: Remove CLOSED status related code, since it ceased to exist
const textStyles: Record<OrderRequisitionStatus, TextColors | ""> = {
  PENDING_PURCHASER_APPROVAL: "text-dangerDefault", // Requisition
  PURCHASER_REJECTED: "text-dangerDefault", // Requisition

  CANCELLED: "text-warningDefault",
  CLOSED: "text-textIcon-blackSecondary",
  PENDING_CANCELLATION: "text-neutral_700",
  SUPPLIER_ACKNOWLEDGED: "text-primaryDefault",
  SUPPLIER_CONFIRMED: "text-successDefault",
  SUPPLIER_PENDING: "text-primaryDefault",
  SUPPLIER_REJECTED: "text-dangerDefault",
  SUPPLIER_DELIVERED: "text-successDefault",
  QUOTE_EXPIRED: "text-textIcon-blackSecondary",
  QUOTE_REJECTED: "",
  SUPPLIER_QUOTE_PENDING: "",
  SUPPLIER_QUOTE_PROVIDED: "",
  SUPPLIER_QUOTE_ACKNOWLEDGED: "",
  SUPPLIER_QUOTE_REJECTION_ACKNOWLEDGED: "",
  DRAFT_CREATED: "text-primaryDefault",
  DRAFT_DELETED: "",
};

export const Pill: React.FC<PillProps> = ({ status, className = "" }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`rounded-3xl px-4 py-1 flex items-center w-max h-5 ${pillStyles[status]}`}
      data-testid="orderStatusPill"
    >
      {pillImage[status]}
      <Label
        size="300"
        color={textStyles[status]}
        className={classnames("pl-2", className, backgroundStyles[status])}
      >
        {t(`status.${status}`)}
      </Label>
    </div>
  );
};
