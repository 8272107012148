import { formatMoney } from "@web/utils";

import { OrderRequisition } from "../network/model";

export const OrderReqService = {
  getTotalGrossAmount: (
    order: Pick<OrderRequisition, "totalGrossAmount">,
    omitCurrency?: boolean
  ): string => formatMoney(order.totalGrossAmount, omitCurrency),
  getAmountZeroInOrderCurrency: (order: Pick<OrderRequisition, "totalGrossAmount">): string =>
    formatMoney({ amount: 0, currencyCode: order.totalGrossAmount.currencyCode }),
};
