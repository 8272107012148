import { useQuery } from "@tanstack/react-query";

import { getPurchasers } from "../api/getPurchasers";
import { AppPurchasersFleets } from "../typegens";

export const usePurchasersQuery = () =>
  useQuery<AppPurchasersFleets>({
    queryKey: ["purchasers"],
    queryFn: getPurchasers,
    refetchOnMount: false,
  });
