import { OrderRequisition } from "@web/common/network/model";

import { LEGACY_GetOrderRequisitionsListParams } from "../models/LEGACY_GetOrderRequisitionsListParams";
import { LocalOrderReqService } from "../services/LocalOrderReqService";
import { MainAppService } from "../typegens";

// TODO #9356: Remove CLOSED status related code, since it ceased to exist (remove this file)
export const LEGACY_getOrderRequisitionsList = async ({
  cursor = "",
  vesselId,
  status,
}: LEGACY_GetOrderRequisitionsListParams): Promise<{
  items: OrderRequisition[];
  cursor: string | undefined;
  requisitionsWaitingForApproval: number;
}> => {
  const result = await MainAppService.getAllOrderRequisitions({ status, cursor, vesselId });

  return {
    items: result.items.map((o) => LocalOrderReqService.toOrderRequisition(o)),
    cursor: result.cursor,
    requisitionsWaitingForApproval: result.requisitionsWaitingForApproval,
  };
};
