import { AppVesselConfiguration, HomeService } from "../typegens";

export const getVesselConfiguration = async ({
  vesselId,
}: {
  vesselId: string;
}): Promise<AppVesselConfiguration> => {
  const result = await HomeService.getConfigurationByVessel({ vesselId });

  return result;
};
