import SearchIcon from "@heroicons/react/solid/SearchIcon";
import classnames from "classnames";
import { ChangeEvent, FormEvent, useMemo } from "react";

import { Tooltip } from "../../atoms";

interface Props {
  placeholder: string;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onQueryChange: (e: ChangeEvent<HTMLInputElement>) => void;
  query: string;
  disabled?: boolean;
  isOnline?: boolean;
  isOfflineSearchEnabled?: boolean;
  removeOuterPaddings?: boolean;
}

export const SearchBar: React.FC<Props> = ({
  isOnline = true,
  isOfflineSearchEnabled = false,
  onQueryChange,
  onSubmit,
  query,
  disabled,
  placeholder,
  removeOuterPaddings = false,
}) => {
  const InputComponent = useMemo(
    () =>
      function SearchInput({
        className,
        query,
        placeholder,
        onQueryChange,
        ...rest
      }: {
        query: string;
        placeholder: string;
        onQueryChange: (e: ChangeEvent<HTMLInputElement>) => void;
        className?: string;
      } & React.InputHTMLAttributes<HTMLInputElement>) {
        return (
          <input
            id="search"
            name="search"
            className={classnames(
              className,
              "pl-7 py-2 border rounded-md leading-5 focus:outline-none sm:text-sm bg-neutral_100 border-neutral_200 w-full flex-grow"
            )}
            placeholder={placeholder}
            type="search"
            value={query}
            onChange={onQueryChange}
            data-testid="search-input"
            {...rest}
          />
        );
      },
    []
  );
  return (
    <form className="flex-grow w-full" onSubmit={onSubmit} data-testid="search-form">
      <div
        className={classnames(
          "flex-1 items-center justify-center lg:justify-end flex flex-grow w-full",
          {
            "px-4": !removeOuterPaddings,
          }
        )}
      >
        <div className="flex flex-grow">
          <label htmlFor="search" className="sr-only">
            {placeholder}
          </label>
          <div className="relative flex flex-grow">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center cursor-pointer">
              <SearchIcon className="h-5 w-5 text-textIcon-blackSecondary" aria-hidden="true" />
            </div>
            {isOnline || isOfflineSearchEnabled ? (
              <InputComponent
                placeholder={placeholder}
                onQueryChange={onQueryChange}
                query={query}
                disabled={disabled}
              />
            ) : (
              <Tooltip className="w-full" tooltipText="Search is unavailable in offline mode">
                <InputComponent
                  placeholder={placeholder}
                  onQueryChange={onQueryChange}
                  query={query}
                  className="cursor-not-allowed"
                  disabled
                />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
