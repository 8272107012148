import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Pill } from "@web/common/components";
import { OrderContextMenuActions } from "@web/common/contexts/ModalContext";
import { getOrderDate } from "@web/common/utils";
import { IconButton, Label, Paragraph, containerPadding } from "@web/ui";
import { LEGACY_formatDate } from "@web/utils";

import { OrderRequisition } from "../../../network/model";
import { DotsButton } from "./DotsButton";

interface Props {
  order: OrderRequisition;
  contextMenuActions?: OrderContextMenuActions;
  goBackRoute: string;
  topbarButtons: React.ReactNode;
}

const getOrderTypeFormatted = (order: OrderRequisition) => {
  const { type, closed } = order;
  const isDraft = type === "DRAFT";
  const isClosed = closed;

  if (isDraft) {
    return "Draft";
  }

  if (isClosed) {
    return "Order Closed";
  }

  return "Order";
};

// This component is only used in LEGACY_OrderLayout, which is used only in Main App
const OrderTopbar: React.FC<Props> = ({
  order,
  contextMenuActions,
  goBackRoute,
  topbarButtons,
}) => {
  const { id, status, customerOrderId, updatedAt } = order;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDraft = order.type === "DRAFT";
  const isQuotation = order.type === "QUOTATION";
  const orderDate = getOrderDate(order);

  return (
    <div
      className={`${containerPadding} flex w-full py-4 px-9 h-min items-center bg-neutral_0 justify-between`}
    >
      <div className="flex items-center">
        <IconButton
          variant="secondary"
          size="large"
          shape="circle"
          label="Go back"
          Icon={ChevronLeftIcon}
          onClick={() => {
            navigate(goBackRoute);
          }}
        />
        <div className="flex-col">
          <div className="flex align-start pl-5">
            <Label size="200" data-testid="orderTopbar_customerOrderId">
              {customerOrderId}
            </Label>
            <div className="pl-4" data-testid="orderTopbar_orderStatus">
              <Pill status={status} />
            </div>
          </div>
          <div className="flex pt-2 pl-5">
            <Paragraph
              size="300"
              color={`${
                isQuotation
                  ? "text-primaryDefault"
                  : isDraft
                  ? "text-primaryDefault"
                  : "text-textIcon-blackSecondary"
              }`}
              data-testid="orderTopbar_orderType"
            >
              {getOrderTypeFormatted(order)}
            </Paragraph>
            <Paragraph
              size="300"
              color="text-textIcon-blackSecondary"
              className="pl-4"
              data-testid="orderTopbar_orderDate"
            >
              {isDraft
                ? `Last updated: ${LEGACY_formatDate(updatedAt)}`
                : orderDate
                ? `${t("common.topbar.orderDate")} ${LEGACY_formatDate(orderDate)}`
                : null}
            </Paragraph>
            {!!customerOrderId && (
              <Paragraph
                size="300"
                color="text-textIcon-blackSecondary"
                className="pl-4"
                data-testid="orderTopbar_orderId"
              >
                {t("common.topbar.orderId")} {id}
              </Paragraph>
            )}
          </div>
        </div>
      </div>
      {topbarButtons}
      <DotsButton order={order} contextMenuActions={contextMenuActions} />
    </div>
  );
};

export default OrderTopbar;
