import { ApiResponse, handleResponse } from "../store/utils";
import { AppOrderCreationRequest, AppOrderRequisition, MainAppService } from "../typegens";

export const createOrder = async (
  requestBody: AppOrderCreationRequest
): Promise<ApiResponse<AppOrderRequisition>> => {
  const result = await handleResponse(() => MainAppService.createOrder({ requestBody }));

  return result;
};
