import { useQuery } from "@tanstack/react-query";

import { Category } from "@web/common/models";

import { handleResponse } from "../store";
import { ApiError, HomeService } from "../typegens";

const useCategoriesQuery = (
  { portId, vesselId }: { portId: string; vesselId: string },
  settings = {}
) =>
  useQuery<Array<Category>, ApiError>({
    ...settings,
    queryKey: ["categories", portId, vesselId],
    queryFn: async () => {
      const response = await handleResponse(() =>
        HomeService.getConfigurationCategories({ portId, vesselId })
      );
      // Note(ted): I dont like this handleResponse anymore.
      if ("data" in response) {
        return response.data.items;
      }
      throw response.error;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export default useCategoriesQuery;
