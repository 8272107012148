import { PencilIcon } from "@heroicons/react/solid";
import { ChangeEventHandler, useCallback } from "react";

import { IconButton, Label, Paragraph, RegularButton, Textarea } from "@web/ui";

export type SupplierOrderCommentState = "EMPTY" | "SUBMITTED" | "EDITING";

type Props = {
  setComment: (comment?: string) => void;
  comment?: string;
  commentState: SupplierOrderCommentState;
  onOrderCommentDelete: () => void;
  onOrderCommentSave: (comment: string) => void;
  setCommentState: (comentState: SupplierOrderCommentState) => void;
  supplierName: string;
};

export const SupplierOrderComment = ({
  comment,
  commentState,
  onOrderCommentDelete,
  onOrderCommentSave,
  setComment,
  setCommentState,
  supplierName,
}: Props) => {
  const handleOrderCommentChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      setComment(e.target.value);
    },
    [setComment]
  );

  const handleCommentDelete = useCallback(() => {
    setComment(undefined);
    setCommentState("EMPTY");
    onOrderCommentDelete();
  }, [onOrderCommentDelete, setComment, setCommentState]);

  const handleOrderCommentsSave = useCallback(() => {
    if (!comment) {
      return;
    }

    setCommentState("SUBMITTED");
    onOrderCommentSave(comment);
  }, [comment, onOrderCommentSave, setCommentState]);

  const handleEditCommentClick = useCallback(() => {
    setCommentState("EDITING");
  }, [setCommentState]);

  switch (commentState) {
    case "EDITING":
      return (
        <>
          <Label size="200" color="text-textIcon-blackSecondary">
            Order comments for <span className="capitalize">{supplierName}</span>:
          </Label>
          <div className="flex justify-between w-full">
            <Textarea
              className="text-sm h-[115px]"
              parentClassName="w-[80%]"
              value={comment}
              onChange={handleOrderCommentChange}
            />
            <div className="my-4">
              <RegularButton
                className="mr-3"
                variant="primary"
                size="large"
                label="Save"
                disabled={!comment}
                onClick={handleOrderCommentsSave}
              />
              <RegularButton
                variant="danger"
                size="large"
                label="Delete"
                onClick={handleCommentDelete}
              />
            </div>
          </div>
        </>
      );
    case "SUBMITTED":
      return (
        <div className="w-full min-h-[115px] flex justify-between bg-neutral_0 rounded-lg shadow-sm p-2 mb-4">
          <div className="flex">
            <div className="w-0.5 h-full bg-neutral_300 mr-2" role="presentation" />
            <Paragraph size="200">
              <span className="font-bold">
                Order comments for <span className="capitalize">{supplierName}</span>:
              </span>
              <br />
              <span>{comment}</span>
            </Paragraph>
          </div>
          <IconButton
            variant="secondary"
            size="large"
            shape="circle"
            label="Edit comment"
            Icon={PencilIcon}
            onClick={handleEditCommentClick}
          />
        </div>
      );
    case "EMPTY":
      return null;
  }
};
