import { z } from "zod";

export const CustomerSchema = z.object({
  customerName: z.string().nonempty({ message: "Customer name is required" }),
  companyId: z.string(),
  adminId: z.string(),
  adminReferenceId: z.string(),
});

export type CustomerFormType = z.infer<typeof CustomerSchema>;
