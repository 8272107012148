import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Combobox, FormSection } from "@web/ui";
import { useFuzzySearch } from "@web/utils";

import { WarehouseAddress } from "../../../layouts";
import { Warehouse } from "../../../models/Warehouse";

type Props = {
  warehouses: Warehouse[];
  onSelectWarehouse: (warehouse: { id: string } | null) => void;
  preselectedWarehouse?: Warehouse;
  errors?: string;
};

const messages = {
  placeholder: "Search",
  noValueLabel: "Select warehouse",
  noValueSubLabel: "No warehouse selected",
};

export const DeliveryAddress = ({
  onSelectWarehouse,
  preselectedWarehouse,
  errors,
  warehouses,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { filteredCollection: filteredWarehouses, handleSearchPatternChange } = useFuzzySearch({
    collection: warehouses,
    keys: ["line1"],
  });

  useEffect(() => {
    if (preselectedWarehouse) {
      onSelectWarehouse(preselectedWarehouse);
    }
  }, [onSelectWarehouse, preselectedWarehouse]);

  return (
    <FormSection
      subtitle={t("components.contactInfo.deliveryAddress.subtitle")}
      title={t("components.contactInfo.deliveryAddress.title")}
      testId="deliveryAddress"
    >
      <Combobox
        filteredOptions={filteredWarehouses}
        hasError={!!errors}
        messages={messages}
        onSearchPatternChange={handleSearchPatternChange}
        onSelectOption={onSelectWarehouse}
        options={warehouses}
        optionComponent={(props) => (
          <WarehouseAddress dataTestId={"deliveryAddress-selected"} {...props} />
        )}
        preselectedOption={preselectedWarehouse}
        {...rest}
      />
      {errors && <p className="text-dangerDefault text-sm font-normal">{errors}</p>}
    </FormSection>
  );
};
