import { Paragraph } from "@web/ui";

interface WarningProps {
  warningMessage: string;
  minimumOrderQuantity: number;
  customerOrderQuantity?: number;
  minimumQuantityNumber: number;
  measurementUnit: string;
}

const WarningLabel: React.FC<WarningProps> = ({
  warningMessage,
  minimumOrderQuantity,
  customerOrderQuantity,
  minimumQuantityNumber,
  measurementUnit,
}) => {
  return (
    <div className="px-2 py-1 rounded-md bg-warningBackground mt-1 border border-warningDefault">
      <Paragraph size="300" className="text-warningDefault">
        {warningMessage}{" "}
        <strong className="text-textIcon-blackPrimary">{minimumQuantityNumber}</strong> (
        {`${
          customerOrderQuantity === undefined ? minimumOrderQuantity : customerOrderQuantity
        } ${measurementUnit}`}
        )
      </Paragraph>
    </div>
  );
};

export default WarningLabel;
