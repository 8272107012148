import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { Toast } from "@web/ui";

import { RootState, clearSystemMessage, useAppDispatch } from "../store";

const SystemMessagesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    systemMessages: { systemMessages },
  } = useSelector((state: RootState) => state);
  const currentMessage = systemMessages[0];
  const dispatch = useAppDispatch();
  const clearMessage = (id: string) => dispatch(clearSystemMessage({ id }));

  return (
    <>
      {currentMessage && <Toast toastMessage={currentMessage} onDismissToast={clearMessage} />}
      {children}
    </>
  );
};

export default SystemMessagesProvider;
