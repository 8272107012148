import classnames from "classnames";
import { FieldErrors, UseFormRegister } from "react-hook-form";

import { FormValues } from "@web/common/views";
import { Heading, Label, Paragraph } from "@web/ui";

type Props = {
  errors: FieldErrors<FormValues>;
  isDutyFree?: "yes" | "no";
  register: UseFormRegister<FormValues>;
};

export const DutyFreeDeclaration = ({ register, errors, isDutyFree }: Props) => {
  return (
    <div className="flex flex-col gap-4">
      <Heading size="200">Order is eligible for purchasing duty-free/tax-free goods</Heading>
      <Paragraph size="200" color="text-textIcon-blackSecondary">
        Please be aware that if you are not eligible for duty-free goods, CW status item will not
        delivered
      </Paragraph>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <label>
            <div
              className={classnames(
                "cursor-pointer box-border mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm border-grey-300",
                {
                  "outline outline-2 outline-blue-500": isDutyFree === "no",
                }
              )}
              data-testid="dutyFreeDeclaration_dutyFreeEligible_no"
            >
              No
            </div>
            <input
              className="hidden"
              type="radio"
              value="no"
              {...register("dutyFreeDeclaration.dutyFree", {
                required: {
                  value: true,
                  message: "Please select an option",
                },
              })}
            />
          </label>
          <label className="flex flex-col justify-center align-middle">
            <div
              className={classnames(
                "cursor-pointer box-border mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm border-grey-300",
                {
                  "outline outline-2 outline-blue-500": isDutyFree === "yes",
                }
              )}
              data-testid="dutyFreeDeclaration_dutyFreeEligible_yes"
            >
              Yes
            </div>
            <input
              className="hidden"
              type="radio"
              value="yes"
              {...register("dutyFreeDeclaration.dutyFree", {
                required: {
                  value: true,
                  message: "Please select an option",
                },
              })}
            />
          </label>
        </div>
        <Paragraph size="200" color="text-dangerDefault">
          {errors.dutyFreeDeclaration?.dutyFree?.message}
        </Paragraph>
      </div>
      <div className="flex flex-row gap-4">
        <div className="flex flex-col gap-2 w-1/2 relative cursor-pointer">
          {isDutyFree === "yes" && (
            <>
              <Label size="200" color="text-textIcon-blackSecondary">
                Name
              </Label>
              <input
                data-testid="dutyFreeDeclaration_name"
                {...register("dutyFreeDeclaration.name", {
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                })}
                className={`shadow-sm w-full border border-solid focus:border-solid rounded-md py-2 pl-3 focus:outline-none cursor-pointer text-sm leading-5 font-light`}
              />
            </>
          )}
          <Paragraph size="200" color="text-dangerDefault">
            {errors.dutyFreeDeclaration?.name?.message}
          </Paragraph>
        </div>
        {isDutyFree === "yes" && (
          <div className="flex flex-col gap-2 w-1/2 relative cursor-pointer">
            <Label size="200" color="text-textIcon-blackSecondary">
              Position
            </Label>
            <input
              data-testid="dutyFreeDeclaration_position"
              {...register("dutyFreeDeclaration.position", {
                required: {
                  value: true,
                  message: "Position is required",
                },
              })}
              className={`shadow-sm w-full border border-solid focus:border-solid rounded-md py-2 pl-3 focus:outline-none cursor-pointer text-sm leading-5 font-light`}
            />
            <Paragraph size="200" color="text-dangerDefault">
              {errors.dutyFreeDeclaration?.position?.message}
            </Paragraph>
          </div>
        )}
      </div>
    </div>
  );
};
