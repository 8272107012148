import { LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import { useFlag } from "@unleash/proxy-client-react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "react-next-dates/dist/style.css";

import { isDateNotInPast } from "@web/common/validators";
import { AgentInformation, InvoiceAccount, Money, Port } from "@web/models";
import { Badge, FormSection, Heading, Input, Paragraph } from "@web/ui";
import { convertTimeToISODateString, extractFromISODateString, isDefined } from "@web/utils";

import {
  AgentInformation as AgentInformationInfo,
  ContactInformation,
  DeliveryDate,
  SummaryBox,
} from "../../components";
import { CatalogConfiguration } from "../../models";
import { OrderType } from "../../models/OrderType";
import { Vessel } from "../../models/Vessel";
import { Warehouse } from "../../models/Warehouse";
import { DutyFreeDeclarationType, OrderRequisition } from "../../network/model";
import { getBadgeSettingsFromOrderType, shouldRenderOrderType } from "../../utils";
import { BillingInformation, DeliveryAddress, DeliveryMethod, OrderComment } from "./components";
import { StorageLabelInput } from "./components/StorageLabelInput";
import { CreationMode, OrderInfoFormValues } from "./models";

interface Props {
  port?: Port;
  creationMode: CreationMode;
  defaultEmail?: string;
  defaultISODate?: string;
  defaultName?: string;
  deliveryDate?: string;
  grandTotal: Money;
  invoiceAccounts?: InvoiceAccount[];
  loading: boolean;
  nrLineItems: number;
  nrRfqItems?: number;
  orderRequisition?: OrderRequisition;
  submitForm: (orderSummary: OrderInfoFormValues) => void;
  vessel: Pick<Vessel, "id" | "name" | "contactInformation">;
  warehouses?: Warehouse[];
  withOrderComment: boolean;
  dutyFreeDeclaration?: DutyFreeDeclarationType;
  isCustomerOrderIdRequired?: boolean;
  renderAttentionInfo?: () => React.ReactElement | null;
  orderType?: OrderType;
  orderTypes?: CatalogConfiguration[];
}

type OrderInfoFormInputs = {
  agentInformation?: Required<AgentInformation>;
  basketId?: string;
  catalogItems?: Array<{
    variantId: string;
    quantity: number;
  }>;
  consolidated: boolean;
  customerOrderId?: string;
  deliveryDate: {
    time: string;
    date: string;
  };
  draftId?: string;
  dutyFreeDeclaration?: DutyFreeDeclarationType;
  invoiceAccount: InvoiceAccount | null;
  orderNotes?: string;
  requesterInformation: {
    name: string;
    email: string;
  };
  rfqItems?: Array<{
    name: string;
    quantity: number;
    measurementUnit: string;
    description?: string;
    exampleUrl?: string;
  }>;
  storageLabel?: string;
  warehouse: Warehouse | null;
};

export const OrderInfo: React.FC<Props> = ({
  deliveryDate,
  port,
  creationMode,
  grandTotal,
  loading,
  nrLineItems,
  nrRfqItems,
  orderRequisition,
  submitForm,
  vessel,
  withOrderComment,
  invoiceAccounts = [],
  warehouses = [],
  dutyFreeDeclaration,
  isCustomerOrderIdRequired = false,
  renderAttentionInfo,
  orderType,
  orderTypes,
}) => {
  /* Hooks */
  const { t } = useTranslation();

  /* Feature Flags */

  const hasInvoiceAccountsFeature = useFlag("invoice-accounts");
  const hasConsolidatedOrdersFeature = useFlag("consolidated-orders");

  /* State from App */
  const defaultEmail = vessel.contactInformation?.email;
  const defaultName = vessel.contactInformation?.name;
  const portAgentInformation = port?.agentInformation;
  const defaultAgentInformation = portAgentInformation
    ? {
        firstName: portAgentInformation.firstName || "",
        lastName: portAgentInformation.lastName || "",
        email: portAgentInformation.email || "",
        phoneNumber: portAgentInformation.phoneNumber || "",
        company: portAgentInformation.company || "",
      }
    : undefined;

  /* Form */
  const defaultValues: OrderInfoFormInputs = {
    agentInformation: defaultAgentInformation ||
      orderRequisition?.agentInformation || {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        company: "",
      },
    consolidated: orderRequisition?.consolidated ?? false,
    requesterInformation: {
      email: defaultEmail || orderRequisition?.requesterInformation.email || "",
      name: defaultName || orderRequisition?.requesterInformation.name || "",
    },
    dutyFreeDeclaration: isDefined(dutyFreeDeclaration)
      ? {
          dutyFree: dutyFreeDeclaration.dutyFree,
          name: dutyFreeDeclaration.name,
          position: dutyFreeDeclaration.position,
        }
      : undefined,
    deliveryDate: {
      time: extractFromISODateString(orderRequisition?.deliveryDate ?? deliveryDate, "justTime"),
      date: extractFromISODateString(orderRequisition?.deliveryDate ?? deliveryDate, "yearFirst"),
    },
    invoiceAccount: orderRequisition?.invoiceAccount ?? null,
    orderNotes: "",
    storageLabel: orderRequisition?.storageLabel || "",
    customerOrderId: orderRequisition?.customerOrderId || "",
    warehouse: null,
  };

  /* Form */

  const form = useForm<OrderInfoFormInputs>({
    defaultValues: defaultValues,
    mode: "onBlur",
    shouldUnregister: true,
  });

  const { handleSubmit, formState, control, register, setValue, watch } = form;

  /* Conditions */

  const shouldDisplayRequesterInformation = ["ORDER_CREATION", "PURCHASER_ORDER_CREATION"].includes(
    creationMode as CreationMode
  );
  const shouldDisplayBillingInformation = hasInvoiceAccountsFeature && invoiceAccounts?.length > 0;
  const shouldDisplayDeliveryAddress = watch("consolidated");
  const shouldDisplayDeliveryMethod = hasConsolidatedOrdersFeature && warehouses.length > 0;

  const shouldDisplayOrderComment = withOrderComment;
  const { errors } = formState;

  const submit = async () => {
    const { invoiceAccount, warehouse, ...values } = form.getValues();
    const data: OrderInfoFormValues = {
      ...values,
      consolidated: shouldDisplayDeliveryAddress
        ? watch("consolidated")
        : orderRequisition?.consolidated,
      warehouseId: warehouse?.id,
      deliveryDate: convertTimeToISODateString(
        values.deliveryDate?.time,
        values.deliveryDate?.date
      ),
      ...(shouldDisplayBillingInformation ? { invoiceAccountId: invoiceAccount?.id } : {}),
    };
    const filtered = Object.fromEntries(
      Object.entries(data).filter(([, value]) => value != undefined)
    );
    submitForm(filtered as OrderInfoFormValues);
  };

  const getTitle = (
    mode: CreationMode
  ): {
    title: string;
    description: string;
  } => {
    if (mode === "ORDER_CREATION") {
      return {
        title: t("pages.orderSummary.title"),
        description: "",
      };
    }
    if (mode === "REQUISITION_APPROVAL") {
      return {
        title: t("pages.orderSummary.title"),
        description: "",
      };
    }
    if (mode === "QUOTATION_CREATION") {
      return {
        title: t("common.components.orderInfo.quotation.title"),
        description: "",
      };
    }
    return { title: t("pages.orderSummary.title"), description: "" };
  };

  const Divisor = () => <hr className="my-2" />;

  const requesterName = shouldDisplayRequesterInformation
    ? watch("requesterInformation.name")
    : orderRequisition?.requesterInformation.name;
  const requesterEmail = shouldDisplayRequesterInformation
    ? watch("requesterInformation.email")
    : orderRequisition?.requesterInformation.email;

  return (
    <>
      <div className="flex-grow">
        <div className="mb-6">
          <Heading size="300">
            {getTitle(creationMode).title}
            {orderType && shouldRenderOrderType(orderTypes, orderType) && (
              <Badge {...getBadgeSettingsFromOrderType({ orderType, orderTypes })} size="s" />
            )}
          </Heading>
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            {getTitle(creationMode).description}
          </Paragraph>
        </div>
        <form className="flex flex-col flex-grow gap-6" name="contact_info">
          {shouldDisplayRequesterInformation && (
            <>
              <ContactInformation
                emailField={
                  <Controller
                    name="requesterInformation.email"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <Input
                        {...rest}
                        prefixIcon={<MailIcon className="text-textIcon-blackSecondary h-4 w-4" />}
                        type="email"
                        id="Email"
                        label={t("components.contactInfo.emailLabel")}
                        placeholder={t("components.contactInfo.emailPlaceholder")}
                        maxLength={320}
                        value={value}
                        onInputChange={onChange}
                        testId="requesterEmail"
                        errorMessage={errors.requesterInformation?.email?.message}
                      />
                    )}
                  />
                }
                fullNameField={
                  <Controller
                    name="requesterInformation.name"
                    control={control}
                    render={({ field: { value, onChange, ...rest }, formState: { errors } }) => (
                      <Input
                        {...rest}
                        label={t("components.contactInfo.fullNamePlaceholder")}
                        prefixIcon={
                          <LockClosedIcon className="text-textIcon-blackSecondary h-4 w-4" />
                        }
                        placeholder={t("components.contactInfo.internalRequesterLabel")}
                        maxLength={100}
                        value={value}
                        onInputChange={onChange}
                        testId="requesterName"
                        errorMessage={errors.requesterInformation?.name?.message}
                      />
                    )}
                  />
                }
              />
              <Divisor />
            </>
          )}
          <FormSection title={"Delivery date"} subtitle={t("Enter the delivery date")}>
            <DeliveryDate
              initialValue={convertTimeToISODateString(
                defaultValues?.deliveryDate.time,
                defaultValues?.deliveryDate.date
              )}
              errors={errors}
              datePickerAdditionalProps={register("deliveryDate.date", {
                required: {
                  value: true,
                  message: t("components.contactInfo.dateEmpty"),
                },
                validate: {
                  notInPast: (value) =>
                    isDateNotInPast(value, t("components.contactInfo.dateNotInTheFuture")),
                },
              })}
              timePickerAdditionalProps={register("deliveryDate.time", {
                pattern: {
                  value: /^(0?[0-9]|1[0-9]|(2[0-3])):[0-5][0-9]$/gm,
                  message: t("components.contactInfo.timeInvalid"),
                },
                required: {
                  value: true,
                  message: t("components.contactInfo.timeEmpty"),
                },
              })}
              onChange={(time: string, date: string) => {
                setValue("deliveryDate.date", date, {
                  shouldDirty: true,
                  shouldValidate: (() => date != "")(),
                });
                setValue("deliveryDate.time", time, {
                  shouldDirty: true,
                  shouldValidate: (() => time != "")(),
                });
              }}
            />
          </FormSection>
          <Divisor />
          {shouldDisplayDeliveryMethod && (
            <>
              <Controller
                name="consolidated"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DeliveryMethod
                    isConsolidatedOrder={value}
                    onChangeIsConsolidatedOrder={onChange}
                  />
                )}
              />
              <Divisor />
            </>
          )}
          {shouldDisplayDeliveryAddress && (
            <>
              <Controller
                name="warehouse"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("components.contactInfo.deliveryAddress.validation.required"),
                  },
                }}
                render={({ field: { onChange, ...rest }, formState: { errors } }) => (
                  <DeliveryAddress
                    warehouses={warehouses}
                    onSelectWarehouse={onChange}
                    preselectedWarehouse={orderRequisition?.warehouse}
                    errors={errors.warehouse?.message}
                    {...rest}
                  />
                )}
              />
              <Divisor />
            </>
          )}
          {shouldDisplayBillingInformation && (
            <>
              <Controller
                name="invoiceAccount"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t("components.contactInfo.billingInformation.validationError"),
                  },
                }}
                render={({ field: { onChange, ...rest }, fieldState }) => (
                  <>
                    <BillingInformation
                      invoiceAccounts={invoiceAccounts}
                      onSelectAccount={(account) => {
                        onChange(account);
                      }}
                      preselectedAccount={orderRequisition?.invoiceAccount}
                      validationError={fieldState.error?.message}
                      {...rest}
                    />
                  </>
                )}
              />
              <Divisor />
            </>
          )}
          <Controller
            name="agentInformation"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <AgentInformationInfo
                  defaultAgentInformation={value}
                  onChange={onChange}
                  register={register}
                  required={{
                    firstName: false,
                    lastName: false,
                    company: true,
                    email: true,
                    phoneNumber: true,
                  }}
                  errors={errors}
                />
                <Divisor />
              </>
            )}
          />
          <Controller
            name="storageLabel"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <StorageLabelInput onChange={onChange} defaultValue={value} />
                <Divisor />
              </>
            )}
          />
          {shouldDisplayOrderComment && (
            <>
              <Controller
                name="orderNotes"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <OrderComment comment={value} onChange={onChange} />
                )}
              />
              <Divisor />
            </>
          )}
        </form>
      </div>
      <div className="ml-8 w-1/3">
        <Controller
          name="customerOrderId"
          control={control}
          render={({ field: { onChange, value }, formState: { errors } }) => {
            return (
              <>
                <SummaryBox
                  creationMode={creationMode}
                  email={requesterEmail}
                  grandTotal={grandTotal}
                  isLoading={loading}
                  name={requesterName}
                  nrLineItems={nrLineItems}
                  nrRfqItems={nrRfqItems}
                  onPoNumberChange={onChange}
                  onSubmit={handleSubmit(submit)}
                  poNumber={value}
                  poNumberError={errors?.customerOrderId?.message}
                  port={port}
                  register={register}
                  registrationName={"customerOrderId"}
                  isPoNumberRequired={isCustomerOrderIdRequired}
                  vessel={vessel}
                  renderAttentionInfo={renderAttentionInfo}
                />
              </>
            );
          }}
        />
      </div>
    </>
  );
};
