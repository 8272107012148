import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Product } from "@web/common/models/Product";
import { getLowestPriceSku, getSalesEntityPrice } from "@web/common/utils";
import { Label, Paragraph } from "@web/ui";
import { formatMoney, imagor } from "@web/utils";

import ProductImageSVG from "../icons/ProductImage.svg";

interface Props {
  product: Product;
  productDetailsRoute: string;
}

export const ProductCard = ({ product, productDetailsRoute }: Props) => {
  const { t } = useTranslation();
  if (!Array.isArray(product.skuList) || product.skuList.length === 0) {
    console.error(
      `Catalog doesn't provide enough information for product ${product.id}:${product.name}`
    );
    return null;
  }

  const sku = getLowestPriceSku(product.skuList);

  const cost = sku ? `${formatMoney(sku.price.costPrice)}` : 0;
  const salesEntityPrice = sku ? `${formatMoney(getSalesEntityPrice(sku))}` : 0;
  const unit = sku ? sku.measurementUnit : "-";
  const costPerUnit = `${cost}/${unit}`;
  const imageUrl = product.images[0]?.imageUrl;

  return (
    <Link to={`${productDetailsRoute}/${product.id}`} data-testid="product-card" className="flex">
      <li className="bg-neutral_0 px-4 py-3 shadow-sm flex flex-grow rounded-md">
        <img
          src={(imageUrl && imagor(imageUrl)) || ProductImageSVG}
          className="whitespace-nowrap h-9 w-9"
        />
        <div className="flex flex-col pl-3">
          <div className="pb-2 break-normal">
            <Label size="200">{product.name}</Label>
          </div>
          <div className="flex items-baseline">
            <div className="flex items-baseline">
              <Label size="100">{salesEntityPrice}</Label>
            </div>
            <div className="pl-2 flex items-baseline">
              <Paragraph size="300" color="text-textIcon-blackSecondary">
                {costPerUnit}
              </Paragraph>
            </div>
          </div>
          {product?.supplierInformation?.name && (
            <Paragraph size="300" color="text-textIcon-blackSecondary" className="mt-1">
              {t("common.components.productCard.suppliedBy")}{" "}
              <span className="capitalize">{product.supplierInformation.name}</span>
            </Paragraph>
          )}
        </div>
      </li>
    </Link>
  );
};
