import { ApiResponse, handleResponse } from "../store/utils";
import { MainAppService } from "../typegens";

export const deleteVessel = async (id: string): Promise<ApiResponse<string>> => {
  const result = await handleResponse(() =>
    MainAppService.deleteVessel({
      id,
    })
  );

  return result;
};
