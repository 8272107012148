import { useTranslation } from "react-i18next";

import { Label, Paragraph } from "@web/ui";
import { formatMoney } from "@web/utils";

import { ProductItem } from "../../network/model";
import { PriceTypeFlag } from "../PriceTypeFlag";

interface Props {
  item: ProductItem;
  lineThrough?: boolean;
}

export const ItemTotal: React.FC<Props> = ({ item, lineThrough = false }) => {
  const { t } = useTranslation();
  return (
    <div className="flex text-right">
      <div className="flex items-center ml-auto my-auto mr-3 whitespace-nowrap">
        <Paragraph size="300" color="text-textIcon-blackSecondary">
          {t("common.requisitionTable.total")}
        </Paragraph>
        <Label size="300" className={`ml-2 border py-1 px-2  ${lineThrough ? "line-through" : ""}`}>
          {item.entityQuantity} {item?.measurementUnit}
        </Label>
      </div>
      <div className="flex flex-col text-right">
        {item.originalSingleGrossPrice &&
          item.originalSingleGrossPrice.amount !== item.singleGrossPrice.amount && (
            <>
              <Label size="200" className={`ml-2 line-through`}>
                {formatMoney({
                  amount: item.originalSingleGrossPrice.amount * item.entityQuantity,
                  currencyCode: item.singleGrossPrice.currencyCode,
                })}
              </Label>
              <Paragraph
                size="300"
                color="text-textIcon-blackSecondary"
                className="pt-1 line-through"
              >
                {`${formatMoney({
                  amount: item.originalSingleGrossPrice.amount,
                  currencyCode: item.singleGrossPrice.currencyCode,
                })} / ${item.measurementUnit}`}
              </Paragraph>
            </>
          )}
      </div>
      <div className="flex flex-col text-right ml-2 whitespace-nowrap">
        <div className="flex space-x-2 items-center">
          <div>
            <Label size="200" className={`${lineThrough ? "line-through" : ""}`}>
              {formatMoney(item.totalAmount)}
            </Label>
            <Paragraph size="300" color="text-textIcon-blackSecondary" className="pt-1">
              {`${formatMoney(item.singleGrossPrice)} / ${item.measurementUnit}`}
            </Paragraph>
          </div>
          <PriceTypeFlag priceType={item.skuDetails?.priceType} />
        </div>
      </div>
    </div>
  );
};
