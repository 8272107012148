import { isAfter, isEqual } from "date-fns";
import { format } from "date-fns-tz";

export const isDateNotInPast = (value: string, errorMessage: string): boolean | string => {
  if (!value) {
    return true;
  }

  try {
    const currentDate = new Date();
    const currentDateString = format(currentDate, "yyyy-MM-dd");
    const currentDateStartOfDay = new Date(currentDateString);

    const chosenDateStartOfDay = new Date(value);

    if (
      !isAfter(chosenDateStartOfDay, currentDateStartOfDay) &&
      !isEqual(chosenDateStartOfDay, currentDateStartOfDay)
    ) {
      return errorMessage;
    }
  } catch (e) {
    // If invalid date, we cannot check if it is not in the past, so bypass the validation
    console.error(e);
    return true;
  }

  return true;
};
