import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { GatherShipBar } from "@web/common/components/navs/GatherShipBar";
import { mergePortsWithConfiguration } from "@web/common/utils";
import { Form, PreconfigureOrderSetup } from "@web/common/views";
import { Port } from "@web/models";
import { Loading } from "@web/ui";

import { useVesselConfigurationQuery } from "src/hooks/useVesselConfiguration";

import routes from "../../config/routes";
import {
  RootState,
  clearGatherBasketEntries,
  setPreconfigureOrderSetup,
  useAppDispatch,
} from "../../store";
import { AppPortConfiguration } from "../../typegens";

export const PortSelection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const vessel = useSelector((state: RootState) => state.gather.vessel);
  const vesselConfigurationQuery = useVesselConfigurationQuery(vessel?.id);
  const handleBackToOverview = () => {
    navigate(routes.orders);
  };

  if (!vessel || vesselConfigurationQuery.isFetching || vesselConfigurationQuery.isPending) {
    return <Loading />;
  }

  if (!vesselConfigurationQuery.data) {
    return null;
  }

  const onSubmit = (form: Form) => {
    dispatch(setPreconfigureOrderSetup(form));
    dispatch(clearGatherBasketEntries());
    navigate(routes.gatherCatalog);
  };

  return (
    <div className="flex flex-col h-full">
      <GatherShipBar onBackToOverview={handleBackToOverview} vessel={vessel} />
      <PreconfigureOrderSetup
        ports={mergePortsWithConfiguration<AppPortConfiguration, Port>(
          vesselConfigurationQuery.data.portConfiguration,
          vesselConfigurationQuery.data.ports
        )}
        onSubmitForm={onSubmit}
      />
    </div>
  );
};
