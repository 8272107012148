import { useTranslation } from "react-i18next";

import { SkuCareAndUse } from "@web/common/models/ProductSku";

import { ContractableSection, Description, RowGroup } from "./SkuAttributesUtils";

interface Props {
  care?: SkuCareAndUse;
}

export const CareAndUseSection = ({ care }: Props) => {
  const { t } = useTranslation();

  if (!care) {
    return null;
  }

  const careValues = Object.values(care).filter((value) => value);

  if (!careValues.length) {
    return null;
  }

  return (
    <ContractableSection title={t("common.views.productDetails.careAndUse")}>
      <Description
        label={t("common.views.productDetails.allergyInformation")}
        value={care?.allergicIngredients}
      />
      <RowGroup rows={getCareAndUsesRows(care)} />
      <Description label="Ingredients" value={care.ingredients} />
      <RowGroup
        rows={{
          "Per Serving Or Per 100g": care.nutrition.perServing,
          "Serving Size": care.nutrition.servingSize,
          calories: care.nutrition.calories,
          protein: care.nutrition.protein,
          fat: care.nutrition.fat,
          "Saturated Fat": care.nutrition.saturatedFat,
          Carbohydrates: care.nutrition.carbohydrates,
          sugar: care.nutrition.sugar,
          cholesterol: care.nutrition.cholesterol,
          sodium: care.nutrition.sodium,
          salt: care.nutrition.salt,
          fiber: care.nutrition.fiber,
        }}
      />
    </ContractableSection>
  );
};

export function getCareAndUsesRows(care: SkuCareAndUse) {
  const careAndUseRows: Record<string, string> = {};

  if (care.allergicIngredients) {
    careAndUseRows["Allergy Information"] = care.allergicIngredients;
  }
  if (care.alcoholPercentage) {
    careAndUseRows["Alcohol Percentage"] = `${care.alcoholPercentage}%`;
  }
  if (care.isHalal) {
    careAndUseRows["halal"] = "Yes";
  }
  if (care.isKosher) {
    careAndUseRows["kosher"] = "Yes";
  }
  if (care.isVegan) {
    careAndUseRows["vegan"] = "Yes";
  }
  if (care.isVegetarian) {
    careAndUseRows["vegetarian"] = "Yes";
  }

  return careAndUseRows;
}
