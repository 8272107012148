import { PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

import { useModalContext } from "@web/common/contexts/ModalContext";
import { Heading, LinkButton, Loading, RegularButton } from "@web/ui";

import { deleteCustomer } from "../../api/deleteCustomer";
import EditCustomerModal from "../../components/modals/EditCustomerModal";
import { RemoveItemModal } from "../../components/modals/RemoveItemModal";
import ManageTable from "../../components/tables/ManageTable";
import ManageTableEmpty from "../../components/tables/ManageTableEmpty";
import ManageTableHeaders from "../../components/tables/ManageTableHeaders";
import { useCustomersQuery } from "../../hooks/useCustomersQuery";
import BaseLayout from "../../layouts/Base";
import { AppCustomer } from "../../typegens";

interface ManageCustomerRowProps {
  customer: AppCustomer;
  handleOnUpdateClick: (customer: AppCustomer) => void;
  handleOnRemoveClick: (customer: AppCustomer) => void;
}

const ManageCustomerRow: React.FC<ManageCustomerRowProps> = ({
  customer,
  handleOnUpdateClick,
  handleOnRemoveClick,
}) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td className="px-6 py-4 text-sm font-medium text-textIcon-blackPrimary">{customer.name}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-textIcon-blackSecondary">
        {customer.companyId}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-textIcon-blackSecondary truncate max-w-0">
        {customer.adminId}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-textIcon-blackSecondary truncate max-w-0">
        {customer.adminReferenceId}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex justify-end content-center gap-2">
          <LinkButton
            size="large"
            variant="primary"
            label={t("pages.manageCustomers.customersList.update")}
            onClick={() => handleOnUpdateClick(customer)}
          />
          |
          <LinkButton
            size="large"
            variant="danger"
            label={t("pages.manageCustomers.customersList.remove")}
            onClick={() => handleOnRemoveClick(customer)}
          />
        </div>
      </td>
    </tr>
  );
};

interface ManageHeaderProps {
  handleOnAddClick: () => void;
}

const ManageHeader: React.FC<ManageHeaderProps> = ({ handleOnAddClick }) => {
  const { t } = useTranslation();

  return (
    <div className="p-5 border-b border-neutral_300 flex justify-between items-center">
      <Heading size="300">{t("pages.manageCustomers.customersList.header")}</Heading>
      <RegularButton
        variant="primary"
        size="large"
        width="content"
        label={t("pages.manageCustomers.customersList.addCustomer")}
        LeadingIcon={PlusIcon}
        onClick={handleOnAddClick}
      />
    </div>
  );
};

const ManageCustomers = () => {
  const { openModal, closeModal } = useModalContext();
  const { t } = useTranslation();
  const customersQuery = useCustomersQuery();

  if (!customersQuery.data) {
    return <Loading />;
  }

  const customers = customersQuery.data;

  const headerNames = [
    t("pages.manageCustomers.customersList.name"),
    t("pages.manageCustomers.customersList.companyId"),
    t("pages.manageCustomers.customersList.adminId"),
    t("pages.manageCustomers.customersList.adminReferenceId"),
    "",
  ];

  const onUpdate = () => {
    closeModal();
    customersQuery.refetch();
  };

  const onCancel = () => {
    closeModal();
  };

  const onRemoveItem = (customer: AppCustomer) => {
    deleteCustomer(customer.id).then(() => customersQuery.refetch());
  };

  const handleOnAddClick = () => {
    openModal(<EditCustomerModal onUpdate={onUpdate} onCancel={onCancel} />);
  };

  const handleOnUpdateClick = (customer: AppCustomer) => {
    openModal(<EditCustomerModal customer={customer} onUpdate={onUpdate} onCancel={onCancel} />);
  };

  const handleOnRemoveClick = (customer: AppCustomer) => {
    openModal(
      <RemoveItemModal
        itemName={customer.name}
        onRemoveItem={() => onRemoveItem(customer)}
        closeModal={onCancel}
      />
    );
  };

  return (
    <BaseLayout noSidebar>
      <div className="rounded-lg bg-neutral_0 shadow flex flex-col">
        <ManageHeader handleOnAddClick={handleOnAddClick} />
        {customers && customers.length > 0 ? (
          <ManageTable
            headerRows={<ManageTableHeaders headerNames={headerNames} />}
            colgroup={
              <colgroup>
                <col span={1} className="w-1/3" />
                <col span={1} className="w-1/3" />
                <col span={1} className="w-1/3" />
                <col span={2} className="w-1/3" />
              </colgroup>
            }
            bodyRows={customers.map((customer) => (
              <ManageCustomerRow
                key={customer.id}
                customer={customer}
                handleOnUpdateClick={handleOnUpdateClick}
                handleOnRemoveClick={handleOnRemoveClick}
              />
            ))}
          />
        ) : (
          <ManageTableEmpty
            header={t("pages.manageCustomers.customersList.noCustomersHeader")}
            message={t("pages.manageCustomers.customersList.noCustomersMessage")}
          />
        )}
      </div>
    </BaseLayout>
  );
};

export default ManageCustomers;
