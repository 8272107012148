import { Route, Routes as RoutesSwitch } from "react-router-dom";

import { RedirectToLoginPage } from "src/pages/RedirectToLogin";

import routes from "../config/routes";
import { Logout } from "../pages/Logout";
import { NoAccessPage } from "../pages/NoAccess";

export const NonAuthenticatedApp = () => {
  return (
    <RoutesSwitch>
      <Route path={routes.noAccess} element={<NoAccessPage />} />
      <Route path={routes.logout} element={<Logout />} />
      <Route path="*" element={<RedirectToLoginPage />} />
    </RoutesSwitch>
  );
};
