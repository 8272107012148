import { PaperAirplaneIcon } from "@heroicons/react/solid";

import { isDefined } from "@web/utils";

import { Heading, Label, Paragraph, RegularButton, RfqBadge, Tooltip } from "../../atoms";

export interface PriceModifierUi {
  name: string;
  amount: string;
}

interface Props {
  title?: string;
  ctaLabel?: string;
  totalPrice?: string;
  totalItemsPrice: string;
  vatPrice: string;
  poInput?: React.ReactNode;
  onSubmit?: () => void;
  submitDisabled?: boolean;
  email?: string;
  nrLineItems: number;
  nrRfqItems?: number;
  isLoading?: boolean;
  additionalCosts?: PriceModifierUi[];
  discounts?: PriceModifierUi[];
  showCtaButton?: boolean;
  validationErrorMessage?: string;
  renderAttentionInfo?: () => React.ReactElement | null;
}

export const SummaryBoxUI: React.FC<Props> = ({
  title = "Summary",
  ctaLabel = "",
  poInput,
  onSubmit = () => undefined,
  submitDisabled = false,
  totalPrice,
  totalItemsPrice,
  vatPrice,
  email,
  nrLineItems,
  nrRfqItems,
  isLoading = false,
  additionalCosts = [],
  discounts = [],
  showCtaButton = false,
  validationErrorMessage,
  renderAttentionInfo,
}) => {
  return (
    <div
      className="px-6 py-4 border rounded-lg shadow bg-neutral_0 flex flex-col"
      data-testid="summary-box"
    >
      <Heading size="300">{title}</Heading>
      {nrLineItems > 0 && (
        <>
          <div className="mt-4 pb-4">
            <Label size="300" color="text-textIcon-blackSecondary">
              Catalog Items
            </Label>

            <div className="flex justify-between items-center pt-1">
              <Label size="300">
                {nrLineItems} {`Item${nrLineItems > 1 ? "s" : ""}`}
              </Label>
              <Label size="300">{totalItemsPrice}</Label>
            </div>

            {additionalCosts.map((additionalCost, index) => (
              <div key={index} className="flex justify-between items-center pt-4">
                <Label
                  size="300"
                  color="text-textIcon-blackSecondary"
                  className="block max-w-[60%]"
                >
                  <span className="block truncate max-w-full">{additionalCost.name}</span>
                </Label>
                <Label size="300">{additionalCost.amount}</Label>
              </div>
            ))}
            {discounts.map((discount, index) => (
              <div key={index} className="flex justify-between items-center pt-4">
                <Label
                  size="300"
                  color="text-textIcon-blackSecondary"
                  className="block max-w-[60%]"
                >
                  <span className="block truncate max-w-full">{discount.name}</span>
                </Label>
                <Label size="300">{discount.amount}</Label>
              </div>
            ))}
          </div>
          <div className="pb-4 border-t">
            <div className="flex justify-between items-center pt-4">
              <Label size="300" color="text-textIcon-blackSecondary">
                Sales Tax VAT 0%
              </Label>
              <Label size="300">{vatPrice}</Label>
            </div>
          </div>
          {!!totalPrice && (
            <div className="flex flex-row justify-between items-center h-7 border-t">
              <Label size="200">Total</Label>
              <Heading size="300">{totalPrice}</Heading>
            </div>
          )}
        </>
      )}

      {isDefined(nrRfqItems) && nrRfqItems > 0 && (
        <div className="bg-neutral_100 px-4 py-2 border-neutral_300 rounded-md mt-4 border-1">
          <div>
            <Paragraph
              size="300" /* color="text-text-whiteDisabled" */
              // TODO #6444: Fixme, there is no such color in tailwind config
            >
              Requested Items
            </Paragraph>
          </div>
          <div className="pt-1 flex justify-between items-center">
            <Label size="300" data-testid="rfqCount">
              {nrRfqItems} {`Item${nrRfqItems > 1 ? "s" : ""}`}
            </Label>
            <Label size="300">
              <Tooltip tooltipText={"This item will need to be requested for a quote"}>
                <RfqBadge />
              </Tooltip>
            </Label>
          </div>
        </div>
      )}

      {poInput}

      {renderAttentionInfo && renderAttentionInfo()}

      {showCtaButton && (
        <RegularButton
          className="mt-4"
          variant="primary"
          size="large"
          label={ctaLabel}
          width="container"
          LeadingIcon={PaperAirplaneIcon}
          disabled={submitDisabled || isLoading}
          loading={isLoading}
          onClick={onSubmit}
          data-testid="summaryBoxCTA"
        />
      )}

      {validationErrorMessage && (
        <Label
          data-testid="summaryBox_validationError"
          className="mt-4"
          color="text-dangerDefault"
          size="300"
        >
          {validationErrorMessage}
        </Label>
      )}

      {!!email && (
        <div className="pt-4 text-center flex flex-col">
          <Paragraph size="300" color="text-textIcon-blackSecondary" className="leading-5">
            Your order will be sent via
          </Paragraph>
          <Paragraph size="300" className="leading-5" data-testid="summaryBox_requesterEmail">
            {email}
          </Paragraph>
        </div>
      )}
    </div>
  );
};
