import { XIcon } from "@heroicons/react/solid";
import { MouseEventHandler, useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation, useNavigate } from "react-router-dom";

import { IconButton, containerPadding } from "@web/ui";

interface Props {
  children: React.ReactNode;
  header?: React.ReactElement;
}

const FormLayout: React.FC<Props> = ({ children, header: customHeader }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname, location.search]);

  const handleNavigateBack: MouseEventHandler<HTMLButtonElement> = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col h-full">
      <div className={`${containerPadding} flex justify-between py-4`}>
        {customHeader ? (
          customHeader
        ) : (
          <IconButton
            size="large"
            variant="secondary"
            shape="circle"
            label="Go back"
            Icon={XIcon}
            onClick={handleNavigateBack}
          />
        )}
      </div>
      <div className="flex-grow bg-neutral_100">
        <div className={`${containerPadding} flex pt-8`}>{children}</div>
      </div>
    </div>
  );
};

export default FormLayout;
