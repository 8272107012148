import { useEffect } from "react";

import { useAuth0WithRedirect } from "@web/common/contexts/Auth0WithRedirect";
import { Loading } from "@web/ui";

export const NoAccessPage: React.FC = () => {
  const { logout } = useAuth0WithRedirect();

  // TED:
  // This logic is added to handle problems with logged supplier user
  // In the future we should show info that this user has no access
  // and ask to change user
  useEffect(() => {
    logout();
  });

  return <Loading />;
};
