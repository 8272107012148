import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";

const DEFAULT_DEBOUNCE_MS = 200;

export const useDebounce = (value: string, delay: number = DEFAULT_DEBOUNCE_MS) => {
  const [searchValue, setSearchValue] = useState(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce((v) => {
      setSearchValue(v);
    }, delay),
    [setSearchValue]
  );

  useEffect(() => {
    debounceCallback(value);
  }, [debounceCallback, value]);

  return searchValue;
};
