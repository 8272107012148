import { ApiResponse, handleResponse } from "../store/utils";
import { AppOrderRequisition, AppRequisitionApproveRequest, MainAppService } from "../typegens";

export const approveRequisition = async ({
  id,
  requestBody,
}: {
  id: string;
  requestBody: AppRequisitionApproveRequest;
}): Promise<ApiResponse<AppOrderRequisition>> => {
  const result = await handleResponse(() =>
    MainAppService.approveRequisition({ requisitionId: id, requestBody })
  );

  return result;
};
