import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Breadcrumbs from "@web/common/components/Breadcrumbs";
import {
  AboutSection,
  AttachmentsSection,
  CareAndUseSection,
  DimensionsAndDeliverySection,
  ProductDetailsHeader,
  ProductVariants,
  StorageSection,
} from "@web/common/components/ProductDetails";
import { Loading } from "@web/ui";

import routes from "../../config/routes";
import { useProductQuery } from "../../hooks/useProductQuery";
import { RootState } from "../../store";
import { AddToBasket } from "./components/AddToBasket";

export const GatherProductDetails: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { port, vessel } = useSelector((state: RootState) => state.gather);
  const productQuery = useProductQuery({
    id,
    portId: port?.id || "",
    vesselId: vessel?.id || "",
  });
  const [skuIndex, setSkuIndex] = useState(0);

  if (productQuery.isFetching || productQuery.isPending) {
    return <Loading />;
  }

  if (!productQuery.isSuccess) {
    return null;
  }

  const product = productQuery.data;

  return (
    <>
      <div className="flex justify-between items-baseline">
        <Breadcrumbs
          categoryTree={product.categoryTree}
          catalogHomeRoute={routes.gatherCatalog}
          catalogIdRoute={routes.gatherCatalog}
        />
      </div>
      <div className="grid grid-cols-4 pt-4" data-testid="product-details-wrapper">
        <div className="col-span-3">
          <ProductDetailsHeader
            brandName={product.brand?.name || ""}
            sku={product.skuList[skuIndex]}
            supplierName={product.supplierInformation?.name}
            supplierItemId={product.supplierInformation?.supplierItemId}
          />
          <ProductVariants
            activeSkuIndex={skuIndex}
            setSkuIndex={setSkuIndex}
            skuList={product.skuList}
          />
          <div className="w-full pt-7 pl-1">
            <AboutSection
              about={product.skuList[skuIndex].about}
              manufacture={product.skuList[skuIndex].manufacture}
            />
            <DimensionsAndDeliverySection
              dimensions={product.skuList[skuIndex].dimensionsAndDelivery}
            />
            <CareAndUseSection care={product.skuList[skuIndex].careAndUse} />
            <AttachmentsSection attachments={product.skuList[skuIndex].attachments} />
            <StorageSection storage={product.skuList[skuIndex].storage} />
          </div>
        </div>
        <div>
          <AddToBasket sku={product.skuList[skuIndex]} />
        </div>
      </div>
    </>
  );
};
