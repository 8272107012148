import copy from "copy-to-clipboard";
import { useState } from "react";

import { Vessel } from "@web/common/models/Vessel";
import { RegularButton } from "@web/ui";

import { MainAppService } from "../../../typegens";

interface ShareLinkButtonProps {
  vessel: Vessel;
}

type ShareButtonStateType = "INITIAL" | "PENDING" | "COPIED";

const ShareLinkButton: React.FC<ShareLinkButtonProps> = ({ vessel }) => {
  const [shareButtonState, setShareButtonState] = useState<ShareButtonStateType>("INITIAL");
  const [tokenLink, setTokenLink] = useState("(reveal URL)");

  const getVesselToken = () => {
    setShareButtonState("PENDING");
    MainAppService.getVesselToken({ id: vessel.id }).then((token) => {
      copyLink(token.liteAppUrl);
    });
  };

  const getShareButtonLabel = () => {
    switch (shareButtonState) {
      case "INITIAL":
        return "Reveal and copy";
      case "PENDING":
        return "Downloading...";
      case "COPIED":
        return "Copied!";
      default:
        return "Share link";
    }
  };

  const copyLink = (liteAppLink: string) => {
    setTokenLink(liteAppLink);
    copy(liteAppLink);
    setShareButtonState("COPIED");
    setTimeout(() => {
      setShareButtonState("INITIAL");
    }, 3000);
  };

  return (
    <div className="w-full flex p-1 rounded-md bg-neutral_100 my-4 justify-between">
      <span className="text-xs mr-2 pl-3 whitespace-nowrap overflow-x-hidden flex items-center italic">
        {tokenLink}
      </span>
      <RegularButton
        variant="secondary"
        size="small"
        width="content"
        label={getShareButtonLabel()}
        onClick={() => getVesselToken()}
      />
    </div>
  );
};

export default ShareLinkButton;
