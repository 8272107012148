import classnames from "classnames";

import { Label } from "@web/ui";

import { Warehouse } from "../../../models/Warehouse";

type Props = {
  dataTestId?: string;
  className?: string;
  value?: Warehouse;
};

const warehouseLineBaseStyles = "block cursor-pointer uppercase my-0.5";

export const WarehouseAddress = ({ dataTestId, className, value: warehouse }: Props) => {
  return (
    <div data-testid={dataTestId}>
      <Label
        size="300"
        className={classnames(
          "block cursor-pointer text-textIcon-blackSecondary uppercase",
          className
        )}
      >
        {warehouse?.line1}
      </Label>
      {warehouse?.line2 && (
        <Label size="300" className={classnames(warehouseLineBaseStyles, className)}>
          {warehouse.line2}
        </Label>
      )}
      {warehouse?.line3 && (
        <Label size="300" className={classnames(warehouseLineBaseStyles, className)}>
          {warehouse.line3}
        </Label>
      )}
      {warehouse?.line4 && (
        <Label size="300" className={classnames(warehouseLineBaseStyles, className)}>
          {warehouse.line4}
        </Label>
      )}
    </div>
  );
};
