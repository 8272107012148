import { useQuery } from "@tanstack/react-query";

import { getPorts } from "../api/getPorts";
import { AppPortsResponse } from "../typegens";

export const usePortsQuery = ({ fleetId }: { fleetId?: string } = {}) =>
  useQuery<AppPortsResponse>({
    queryKey: ["ports", fleetId],
    queryFn: () => getPorts(fleetId),
    refetchOnMount: false,
  });
