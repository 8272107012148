import { ApiResponse, handleResponse } from "../store/utils";
import { AppOrderRequisition, AppQuotationRejectRequest, MainAppService } from "../typegens";

export const rejectQuotation = async ({
  id,
  requestBody,
}: {
  id: string;
  requestBody: AppQuotationRejectRequest;
}): Promise<ApiResponse<AppOrderRequisition>> => {
  const result = await handleResponse(() =>
    MainAppService.rejectQuotation({ quotationId: id, requestBody })
  );

  return result;
};
