import { IntersectionMonitor } from "@web/common";
import { ProductCard } from "@web/common/components/ProductCard";
import { Product } from "@web/common/models/Product";
import { Loading, Paragraph } from "@web/ui";

import routes from "../../../config/routes";
import { useSearch } from "../../../hooks/useSearch";

const ProductsList: React.FC = () => {
  const searchResponse = useSearch();

  if (searchResponse.isFetching || searchResponse.isPending) {
    return <Loading />;
  }

  if (searchResponse.status === "error") {
    return <div>Error here </div>;
  }

  const items = searchResponse.data.pages.flatMap((p) => p.items);

  if (items.length === 0) {
    return (
      <div className="flex-grow flex items-center">
        <div className="flex flex-grow justify-center items-center flex-col">
          <div className="text-center">
            <Paragraph size="100">No Results</Paragraph>
            <Paragraph size="200" className="text-text-whiteDisabled pt-4">
              Try removing or adjusting your filters
            </Paragraph>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ul className="grid grid-cols-1 gap-2 lg:grid-cols-2 w-full pl-8">
        {items.map((product: Product) => (
          <ProductCard
            key={product.id}
            product={product}
            productDetailsRoute={routes.gatherProduct}
          />
        ))}
      </ul>
      {searchResponse.hasNextPage && (
        <IntersectionMonitor onEnter={() => searchResponse.fetchNextPage()}>
          <div className="h-13 max-w-6xl">{searchResponse.isFetchingNextPage && <Loading />}</div>
        </IntersectionMonitor>
      )}
    </>
  );
};

export default ProductsList;
