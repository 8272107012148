import { useQuery } from "@tanstack/react-query";

import { OrderRequisition } from "@web/common/network/model";

import { getOrderRequisition } from "../api/getOrderRequisition";

export const useOrderRequisitionQuery = ({ orderId }: { orderId: string }, options = {}) =>
  useQuery<OrderRequisition>({
    ...options,
    queryKey: ["orderRequisition", orderId],
    queryFn: () => getOrderRequisition({ id: orderId }),
  });
