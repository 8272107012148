import { ApiResponse, handleResponse } from "../store/utils";
import { AppOrderRequisition, AppRequisitionRejectRequest, MainAppService } from "../typegens";

export const rejectRequisition = async ({
  id,
  requestBody,
}: {
  id: string;
  requestBody: AppRequisitionRejectRequest;
}): Promise<ApiResponse<AppOrderRequisition>> => {
  const result = await handleResponse(() =>
    MainAppService.rejectRequisition({ requisitionId: id, requestBody })
  );

  return result;
};
