import { containerPadding } from "@web/ui";

import NoteBox from "../../components/NoteBox";
import { OrderContextMenuActions } from "../../contexts/ModalContext/ModalContextProvider";
import { OrderRequisition } from "../../network/model";
import ActivityBox from "../ActivityBox";
import Banner from "../Banner";
import PriceBox from "../PriceBox";
import OrderTopbar from "./components/OrderTopbar";

interface Props {
  attachments?: React.ReactNode;
  children: React.ReactNode;
  contextMenuActions?: OrderContextMenuActions;
  goBackRoute: string;
  order: OrderRequisition;
  shouldDisplayContactCard: boolean;
  topbarButtons?: React.ReactNode;
  actionBar?: React.ReactNode;
  renderAttentionInfo?: () => React.ReactElement | null;
}

// This component is only used in Main App
export const LEGACY_OrderLayout: React.FC<Props> = ({
  children,
  order,
  contextMenuActions,
  goBackRoute,
  shouldDisplayContactCard,
  topbarButtons,
  actionBar,
  renderAttentionInfo,
}) => {
  return (
    <div className="flex flex-col min-h-full">
      <OrderTopbar
        order={order}
        contextMenuActions={contextMenuActions}
        goBackRoute={goBackRoute}
        topbarButtons={topbarButtons}
      />
      <Banner status={order.status} />
      <div className="bg-neutral_100 min-h-full flex flex-col flex-grow">
        {actionBar}
        <div className={`${containerPadding} flex py-6`}>
          <div className="w-4/6 mr-3">{children}</div>
          <div className="ml-7 h-min w-1/3">
            <PriceBox order={order} />
            <ActivityBox
              order={order}
              shouldDisplayContactCard={shouldDisplayContactCard}
              renderAttentionInfo={renderAttentionInfo}
            />
            <NoteBox order={order} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LEGACY_OrderLayout;
