import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Vessel } from "@web/common/models/Vessel";
import { Port } from "@web/models";
import { Modal, PortSelector as PortSelectorUI } from "@web/ui";

import { ChangePortConfirmationModal } from "src/components/modals/Modal";

import routes from "../../../config/routes";
import { usePortsQuery } from "../../../hooks/usePortsQuery";
import { RootState, setGatherPort, useAppDispatch } from "../../../store";

type Props = {
  vessel: Vessel;
};

export const PortSelector = ({ vessel }: Props) => {
  const portsQuery = usePortsQuery({ fleetId: vessel.fleetId });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const port = useSelector((state: RootState) => state.gather.port);
  const basket = useSelector((state: RootState) => state.gather.basket);
  const [changePortConfirmationModalOpen, setChangePortConfirmationModalOpen] = useState(false);
  const [newPortSelected, setNewPortSelected] = useState<Port | undefined>();

  if (!portsQuery.isSuccess) {
    return null;
  }

  const openChangePortConfirmationModal = () => {
    setChangePortConfirmationModalOpen(true);
  };

  const closeChangePortConfirmationModal = () => {
    setChangePortConfirmationModalOpen(false);
  };

  const handlePortChange = (newPort: Port) => {
    dispatch(setGatherPort(newPort));
    navigate(routes.gatherCatalog);
  };

  const setPortHandler = (newPort: Port) => {
    if (Object.keys(basket.catalogItems).length > 0 && newPort.id !== port?.id) {
      setNewPortSelected(newPort);
      openChangePortConfirmationModal();
    } else {
      handlePortChange(newPort);
    }
  };

  return (
    <>
      {newPortSelected && (
        <Modal
          isOpen={changePortConfirmationModalOpen}
          closeModal={closeChangePortConfirmationModal}
        >
          <ChangePortConfirmationModal
            onPortChange={() => handlePortChange(newPortSelected)}
            closeModal={closeChangePortConfirmationModal}
          />
        </Modal>
      )}
      <PortSelectorUI
        setPortHandler={setPortHandler}
        configPorts={portsQuery.data.ports}
        selectedPort={port}
      />
    </>
  );
};
