import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";

import { Input, Paragraph, RegularButton } from "@web/ui";

import { createCustomer } from "../../../api/createCustomer";
import { updateCustomer } from "../../../api/updateCustomer";
import { AppCustomer } from "../../../typegens";
import { CustomerFormType, CustomerSchema } from "./schema";

interface Props {
  customer?: AppCustomer;
  onUpdate: () => void;
  onCancel: () => void;
}

export const EditCustomerModal: React.FC<Props> = ({ customer, onUpdate, onCancel }) => {
  const onSubmit = (data: CustomerFormType) => {
    (customer ? updateCustomer(customer.id, data) : createCustomer(data)).then(() => onUpdate());
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customerName: customer ? customer.name : "",
      companyId: customer ? customer.companyId : "",
      adminId: customer ? customer.adminId : "",
      adminReferenceId: customer ? customer.adminReferenceId : "",
    },
    resolver: zodResolver(CustomerSchema),
  });

  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg py-4 px-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="sm:flex sm:items-start py-4 pr-4">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <Paragraph size="100">
            {customer ? "Update Customer Information" : "Customer Information"}
          </Paragraph>
          <div className="mt-2">
            {!!customer && (
              <Paragraph size="200" color="text-textIcon-blackSecondary">
                Change the information of this Customer
              </Paragraph>
            )}
          </div>
        </div>
      </div>
      <form>
        <div className="flex">
          <div className="flex-1">
            <Controller
              name="customerName"
              control={control}
              render={({ field }) => (
                <Input
                  label="Customer Name*"
                  errorMessage={errors.customerName?.message}
                  {...field}
                />
              )}
            />
          </div>
          <div className="flex-1 ml-3 w-1/2">
            <Controller
              name="companyId"
              control={control}
              render={({ field }) => (
                <Input label="Company ID" errorMessage={errors.companyId?.message} {...field} />
              )}
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex-1">
            <Controller
              name="adminId"
              control={control}
              render={({ field }) => (
                <Input label="Admin ID" errorMessage={errors.adminId?.message} {...field} />
              )}
            />
          </div>
          <div className="flex-1 ml-3 w-1/2">
            <Controller
              name="adminReferenceId"
              control={control}
              render={({ field }) => (
                <Input
                  label="Admin Reference ID"
                  errorMessage={errors.adminId?.message}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <hr />
        <div className="flex items-end justify-end mt-5">
          <div className="ml-auto">
            <RegularButton
              className="ml-auto"
              variant="secondary"
              size="large"
              label="Cancel"
              onClick={() => {
                onCancel();
              }}
            />
          </div>
          <div className="ml-4">
            <RegularButton
              variant="primary"
              size="large"
              label={customer ? "Update Customer" : "Add Customer"}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
