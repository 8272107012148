import { parse } from "date-fns";
import { format } from "date-fns-tz";

export const convertTimeToISODateString = (time = "", date = "") => {
  const isoDate = new Date();
  const parsedDate = parse(date, "yyyy-MM-dd", isoDate);
  const parsedDateAndTime = parse(time, "HH:mm", parsedDate);
  try {
    return format(parsedDateAndTime, "yyyy-MM-dd'T'HH:mm:ss'Z'");
  } catch (e) {
    return "";
  }
};
