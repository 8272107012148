export const toBoolean = (value: unknown) => {
  if (typeof value === "string") {
    const normalizedValue = value.toLowerCase();
    return normalizedValue === "true";
  } else if (typeof value === "boolean") {
    return value;
  } else if (typeof value === "number") {
    return !!value;
  }
  return false;
};
