import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Port } from "@web/models";
import { Modal } from "@web/ui";

import { deleteFleet } from "src/api/deleteFleet";
import EditFleetModal from "src/components/modals/EditFleetModal";
import { RemoveItemModal } from "src/components/modals/RemoveItemModal";
import ManageTable from "src/components/tables/ManageTable";
import ManageTableEmpty from "src/components/tables/ManageTableEmpty";
import ManageTableHeaders from "src/components/tables/ManageTableHeaders";
import { useFleetsQuery } from "src/hooks/useFleetsQuery";
import BaseLayout from "src/layouts/Base";
import { AppCustomer, AppFleet } from "src/typegens";

import { ManageFleetRow } from "./ManageFleetRow";
import { ManageHeader } from "./ManageHeader";

interface Props {
  fleets: AppFleet[];
  customers: AppCustomer[];
  ports: Record<string, Port[]>;
}

export const ManageFleets: React.FC<Props> = ({ fleets, customers, ports }) => {
  const [showEditFleetModal, setShowEditFleetModal] = useState(false);
  const [showRemoveFleetModal, setShowRemoveFleetModal] = useState(false);
  const [fleetToModify, setFleetToModify] = useState<AppFleet | undefined>(undefined);

  const { t } = useTranslation();
  const fleetsQuery = useFleetsQuery();
  const headerNames = [
    t("pages.manageFleets.fleetsList.name"),
    t("pages.manageFleets.fleetsList.customer"),
    t("pages.manageFleets.fleetsList.permissions"),
    "",
  ];

  const onCancel = () => {
    setShowEditFleetModal(false);
    setShowRemoveFleetModal(false);
    setFleetToModify(undefined);
  };

  const onUpdate = () => {
    onCancel();
    fleetsQuery.refetch();
  };

  const onRemoveItem = () => {
    if (fleetToModify) {
      deleteFleet(fleetToModify.id).then(() => {
        onCancel();
        fleetsQuery.refetch();
      });
    }
  };

  const handleOnAddClick = () => {
    setShowEditFleetModal(true);
  };

  const handleOnUpdateClick = (fleet: AppFleet) => {
    setFleetToModify(fleet);
    setShowEditFleetModal(true);
  };

  const handleOnRemoveClick = (fleet: AppFleet) => {
    setFleetToModify(fleet);
    setShowRemoveFleetModal(true);
  };

  return (
    <BaseLayout noSidebar>
      <div className="rounded-lg bg-neutral_0 shadow flex flex-col">
        <ManageHeader handleOnAddClick={handleOnAddClick} />
        {fleets && fleets.length > 0 ? (
          <ManageTable
            headerRows={<ManageTableHeaders headerNames={headerNames} />}
            bodyRows={fleets.map((fleet) => (
              <ManageFleetRow
                key={fleet.id}
                fleet={fleet}
                handleOnUpdateClick={handleOnUpdateClick}
                handleOnRemoveClick={handleOnRemoveClick}
              />
            ))}
            colgroup={
              <colgroup>
                <col span={1} className="w-1/4" />
                <col span={1} className="w-1/3" />
                <col span={1} className="w-1/4" />
                <col span={1} className="w-1/12" />
              </colgroup>
            }
          />
        ) : (
          <ManageTableEmpty
            header={t("pages.manageFleets.fleetsList.noFleetsHeader")}
            message={t("pages.manageFleets.fleetsList.noFleetsMessage")}
          />
        )}
      </div>
      <Modal isOpen={showEditFleetModal} closeModal={onCancel}>
        {showEditFleetModal && (
          <EditFleetModal
            fleet={fleetToModify}
            customers={customers}
            ports={ports}
            onUpdate={onUpdate}
            onCancel={onCancel}
          />
        )}
      </Modal>
      <Modal isOpen={showRemoveFleetModal} closeModal={onCancel}>
        <RemoveItemModal
          itemName={fleetToModify?.name || ""}
          onRemoveItem={onRemoveItem}
          closeModal={onCancel}
        />
      </Modal>
    </BaseLayout>
  );
};
