import { useNavigate } from "react-router-dom";

import { OrderRequisition } from "@web/common/network/model";
import { RegularButton } from "@web/ui";

import { deleteDraft } from "src/api/deleteDraft";
import {
  clearGatherBasket,
  setGatherBasketEntry,
  setGatherDraftId,
  setGatherVessel,
  setPreconfigureOrderSetup,
  useAppDispatch,
} from "src/store";
import { AppProductSku } from "src/typegens";

import routes from "../../../../config/routes";

interface Props {
  availableProducts: AppProductSku[];
  draft: OrderRequisition;
  closeModal: () => void;
}

export const DraftModalCTAButtons = ({ availableProducts, draft, closeModal }: Props) => {
  const noItemsAvailable = availableProducts.length === 0;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const updateBasket = (products: AppProductSku[]) => {
    products.map((el, qty) =>
      dispatch(setGatherBasketEntry({ quantity: draft.items[qty].quantity, sku: el }))
    );
  };

  const handleAddingProductsToBasket = () => {
    dispatch(clearGatherBasket());
    dispatch(setGatherVessel(draft.vessel));
    dispatch(
      setPreconfigureOrderSetup({
        port: draft.port,
        deliveryDate: draft.deliveryDate,
        dutyFreeDeclaration: draft.dutyFreeDeclaration,
      })
    );
    dispatch(setGatherDraftId(draft.id));
    updateBasket(availableProducts);
    closeModal();
    navigate(routes.gatherBasket);
  };

  const handleDeletingDraft = async () => {
    closeModal();
    await deleteDraft(draft.id);
    dispatch(clearGatherBasket());
    navigate(routes.orders);
  };

  return (
    <>
      <RegularButton
        className="ml-auto mr-5"
        variant="secondary"
        size="large"
        label="Cancel"
        onClick={closeModal}
      />
      <RegularButton
        variant="primary"
        size="large"
        label={noItemsAvailable ? "Delete Draft and Continue" : "Update Draft with Available Items"}
        onClick={noItemsAvailable ? handleDeletingDraft : handleAddingProductsToBasket}
      />
    </>
  );
};
