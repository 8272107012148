import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { ItemTotal } from "@web/common/components/lists";
import NoImage from "@web/common/icons/NoImage.svg";
import { OrderRequisition } from "@web/common/network/model";
import { Label, Paragraph } from "@web/ui";
import { imagor } from "@web/utils";

interface Props {
  requisition: OrderRequisition;
}

const RequestTable = ({ requisition }: Props) => {
  const { t } = useTranslation();
  const tdStyle = "p-2 px-4 text-left";
  const hasItemWithLineItemNumber =
    requisition?.items.find((item) => Number.isInteger(item.lineNumber)) ?? "";

  return (
    <table
      className={`border-separate w-full pt-1 table-fixed ${
        hasItemWithLineItemNumber ? "pl-5" : ""
      }`}
      style={{ borderSpacing: "0 0.25rem", border: "none" }}
    >
      <tbody>
        {requisition.items.map((item, index) => {
          const image = item?.skuDetails?.images[0];
          return (
            <Fragment key={index}>
              <tr className="border bg-neutral_0">
                <td className="w-8 relative">
                  {!!item.lineNumber && (
                    <Label
                      size="200"
                      className="absolute right-full top-1/2 w-6 flex items-center justify-center"
                      style={{ transform: "translateY(-50%)" }}
                    >
                      {item.lineNumber}
                    </Label>
                  )}
                  <div className="flex justify-center items-center w-8 h-8 pl-4">
                    <img
                      alt="product"
                      src={(image?.imageUrl && imagor(image.imageUrl)) || NoImage}
                      className="h-7 object-scale-down"
                    />
                  </div>
                </td>
                <td className={tdStyle}>
                  <div className="flex flex-col">
                    <Label size="200">{item.name}</Label>
                    {!!item.impaCode && (
                      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pt-1">
                        {t("pages.requisition.IMPA")} {item.impaCode}
                      </Paragraph>
                    )}
                  </div>
                </td>
                <td className={tdStyle}>
                  <ItemTotal item={item} />
                </td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default RequestTable;
