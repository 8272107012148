import { ApiResponse, handleResponse } from "../store/utils";
import { AppOrderRequisition, MainAppService } from "../typegens";

export const cancelOrder = async ({
  orderId,
}: {
  orderId: string;
}): Promise<ApiResponse<AppOrderRequisition>> => {
  const result = await handleResponse(() => MainAppService.cancelOrder({ orderId }));

  return result;
};
