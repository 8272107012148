import { PlusIcon } from "@heroicons/react/outline";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import { Heading, RegularButton } from "@web/ui";

import routes from "../../config/routes";

interface Props {
  isRequisition: boolean;
  isRfqRequisition: boolean;
}

export const BasketUI: React.FC<PropsWithChildren<Props>> = ({
  isRequisition,
  isRfqRequisition,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between">
        <Heading size="300" className="text-xl font-semibold mb-4">
          {isRequisition || isRfqRequisition ? "Requested Items" : "Line Items"}
        </Heading>
        {!isRfqRequisition && (
          <RegularButton
            variant="primary"
            size="large"
            label="Add Item"
            LeadingIcon={PlusIcon}
            onClick={() => {
              navigate(routes.gatherCatalog);
            }}
          />
        )}
      </div>
      <div data-testid="basket-container" className="min-w-full">
        {children}
      </div>
    </div>
  );
};
