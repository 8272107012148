import classnames from "classnames";
import { useState } from "react";

type Props = {
  label: string | React.ReactElement;
  badge?: string | React.ReactElement;
  errorMessage?: string | React.ReactElement;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  dataTestId?: string;
  initialValue?: string | null;
};

export const EmptyField: React.FC<Props> = (props: Props) => {
  const { label, badge, errorMessage, inputProps, dataTestId, initialValue } = props;

  const [value, setValue] = useState(
    (() => {
      if (initialValue === null) {
        return undefined;
      }
      return initialValue;
    })()
  );

  return (
    <div className="flex flex-col gap-2">
      {label}
      <div className="flex items-center justify-end relative">
        <input
          {...inputProps}
          type="time"
          onChange={(e) => {
            setValue(e.target.value);
            inputProps?.onChange?.(e);
          }}
          value={value}
          data-testid={dataTestId}
          className={classnames(
            "shadow-sm w-full px-3 border border-solid focus:border-solid rounded-md py-2 pl-3 focus:outline-none cursor-pointer text-sm leading-5 font-light h-6.5",
            {
              "border-dangerDefault": errorMessage !== undefined,
              "border-neutral_300": errorMessage === undefined,
            }
          )}
        />
        <div className="absolute w-4 h-4">{badge}</div>
        <div className="absolute left-0 top-7 whitespace-nowrap">{errorMessage}</div>
      </div>
    </div>
  );
};
