import { Port } from "@web/models";

export const mergePortsWithConfiguration = <T, S extends Port = Port>(
  portConfiguration: Record<string, T>,
  ports: Record<string, S[]>
): S[] =>
  Object.values(ports ? ports : {})
    .reduce((total, current) => [...total, ...current], [])
    .map((port) => ({
      ...port,
      _search: `${port.locationCode} ${port.name}`,
    }))
    .map((port) => ({ ...port, ...portConfiguration[port.locationCode] }));
