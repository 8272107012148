import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RfqLineItem } from "@web/common/components/RfqLineItem";
import { BasketRfqEntry } from "@web/common/models/basket";
import { LineItemUI } from "@web/common/views/Basket/components/LineItemUI";
import { LineItemQuantity, Loading, containerPadding } from "@web/ui";

import { BasketUI } from "../../components/Basket";
import routes from "../../config/routes";
import { useFillBasketFromRequisition } from "../../hooks/useFillBasketFromRequisition";
import { useOrderRequisitionQuery } from "../../hooks/useOrderRequisitionQuery";
import { useSetQuantity } from "../../hooks/useSetQuantity";
import { RootState, setGatherBasketRfqEntry, useAppDispatch } from "../../store";
import { EditOrderRequisitionHeader } from "./components";

export const EditOrderRequisition: React.FC = () => {
  const { orderRequisitionId } = useParams() as { orderRequisitionId: string };
  const orderQuery = useOrderRequisitionQuery({ orderId: orderRequisitionId });

  const { basket } = useSelector((state: RootState) => state.gather);
  const dispatch = useAppDispatch();
  const fillBasketFromRequisition = useFillBasketFromRequisition();

  const { setQuantity } = useSetQuantity();

  const setRfqQuantity = useCallback(
    (entry: BasketRfqEntry) => (quantity: number) => {
      dispatch(setGatherBasketRfqEntry({ quantity, item: entry.item }));
    },
    [dispatch]
  );
  const lineItems = Object.values(basket.catalogItems);
  const isRequisition = Boolean(basket.requisitionId);
  const rfqEntries = Object.values(basket.rfqItems) as BasketRfqEntry[];

  useEffect(() => {
    if (orderQuery.status === "success" && lineItems.length === 0) {
      fillBasketFromRequisition(orderQuery.data);
    }
  }, [fillBasketFromRequisition, lineItems.length, orderQuery.data, orderQuery.status]);

  if (orderQuery.isPending || orderQuery.isFetching) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col h-full">
      <EditOrderRequisitionHeader />
      <div className="bg-neutral_100 h-full ">
        <div className={`py-5 ${containerPadding} flex justify-center`}>
          <BasketUI isRequisition={isRequisition} isRfqRequisition={basket.isRfqRequisition}>
            {lineItems.map((lineItem) => {
              return (
                <LineItemUI
                  initialQuantity={lineItem.initialQuantity || lineItem.quantity}
                  lineItem={lineItem}
                  key={lineItem.sku.id}
                  setQuantity={setQuantity}
                  productRoute={routes.gatherProduct}
                  isRestoreEnabled={Boolean(lineItem.initialQuantity)}
                />
              );
            })}
            {rfqEntries.map((rfqEntry) => {
              return (
                <RfqLineItem
                  key={rfqEntry.item.id}
                  isRemoved={rfqEntry.quantity === 0}
                  quantity={rfqEntry.quantity}
                  rfqfItem={rfqEntry.item}
                >
                  <LineItemQuantity
                    initialQuantity={rfqEntry.initialQuantity}
                    isRestorationEnabled
                    min={1}
                    onQuantityChange={setRfqQuantity(rfqEntry)}
                  />
                </RfqLineItem>
              );
            })}
          </BasketUI>
        </div>
      </div>
    </div>
  );
};
