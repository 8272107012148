import { ProductChange, isReplacedItemDataValid } from "@web/common/network/";
import { Label, Paragraph } from "@web/ui";
import { formatMoney } from "@web/utils";

interface Props {
  item: ProductChange;
  isReplacedItem: boolean;
}

export const ChangedItemTotal: React.FC<Props> = ({ item, isReplacedItem }) => {
  const isReplacedItemModelValid = isReplacedItemDataValid(item);

  if (item.changeType === "ADDED" || (item.changeType === "REPLACED" && !isReplacedItem)) {
    return (
      <div className="flex text-right whitespace-nowrap">
        <div className="flex flex-col justify-center ml-auto my-auto mr-3">
          <div>
            <Label size="300">Total</Label>
            <Label size="300" className="ml-2 border py-1 px-2 rounded">
              {item.entityQuantity} {item.measurementUnit}
            </Label>
          </div>
        </div>
        <div className="flex flex-col text-right ml-2">
          <Label size="200">{formatMoney(item.totalAmount)}</Label>
          <Paragraph size="300" color="text-textIcon-blackSecondary" className="pt-1">
            {`${formatMoney(item.singleGrossPrice)} / ${item.measurementUnit}`}
          </Paragraph>
        </div>
      </div>
    );
  }

  if (item.changeType === "REPLACED" && isReplacedItem && isReplacedItemModelValid) {
    return (
      <div className="flex text-right whitespace-nowrap">
        <div className="flex flex-col justify-center ml-auto my-auto mr-3">
          <div>
            <Label size="300">Total </Label>
            <Label size="300" className="ml-2 border py-1 px-2 rounded line-through">
              {item.oldEntityQuantity} {item.oldMeasurementUnit}
            </Label>
          </div>
        </div>
        <div className="flex flex-col text-right ml-2">
          <Label size="200" className="line-through">
            {formatMoney(item.oldTotalAmount)}
          </Label>
          <Paragraph size="300" color="text-textIcon-blackSecondary" className="pt-1">
            {`${formatMoney(item.oldSingleGrossPrice)} / ${item.oldMeasurementUnit}`}
          </Paragraph>
        </div>
      </div>
    );
  }

  if (item.changeType === "REMOVED") {
    return (
      <div className="flex text-right whitespace-nowrap">
        <div className="flex flex-col justify-center ml-auto my-auto mr-3">
          <div>
            <Label size="300">Total </Label>
            <Label size="300" className="ml-2 border py-1 px-2 rounded line-through">
              {item.entityQuantity} {item.measurementUnit}
            </Label>
          </div>
        </div>
        <div className="flex flex-col text-right ml-2">
          <Label size="200" className="line-through">
            {formatMoney(item.totalAmount)}
          </Label>
          <Paragraph size="300" color="text-textIcon-blackSecondary" className="pt-1">
            {`${formatMoney(item.singleGrossPrice)} / ${item.measurementUnit}`}
          </Paragraph>
        </div>
      </div>
    );
  }

  if (item.changeType === "UPDATED") {
    return (
      <div className="flex text-right whitespace-nowrap" data-testid="updated-status-container">
        <div className="flex flex-col justify-center ml-auto my-auto mr-3">
          {item.oldEntityQuantity && item.oldEntityQuantity !== item.entityQuantity && (
            <Paragraph size="300" color="text-textIcon-blackSecondary" className="px-2 pb-1">
              Initial{" "}
              <span className="ml-2 line-through" data-testid="initial-quantity">
                {item.oldEntityQuantity} {item.measurementUnit}
              </span>
            </Paragraph>
          )}
          <div>
            <Label size="300">Updated </Label>
            <Label
              size="300"
              className="ml-2 border py-1 px-2 rounded bg-successBackground border-successDefault text-successDefault"
            >
              {item.entityQuantity} {item.measurementUnit}
            </Label>
          </div>
        </div>
        <div className="flex flex-col text-right ml-2">
          {item.oldTotalAmount && (
            <Paragraph
              size="300"
              color="text-textIcon-blackSecondary"
              className="line-through pt-1"
              data-testid="old-total-price"
            >
              {formatMoney(item.oldTotalAmount)}
            </Paragraph>
          )}
          <Label size="200">{formatMoney(item.totalAmount)}</Label>
          <Paragraph size="300" color="text-textIcon-blackSecondary" className="pt-1">
            {`${formatMoney(item.singleGrossPrice)} / ${item.measurementUnit}`}
          </Paragraph>
        </div>
      </div>
    );
  }

  return null;
};
