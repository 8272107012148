import { useNavigate, useParams } from "react-router-dom";

import { Loading, NotFoundErrorPage } from "@web/ui";

import routes from "../../config/routes";
import { useOrderRequisitionQuery } from "../../hooks/useOrderRequisitionQuery";
import { OrderDetails } from "./OrderDetails";
import QuotationDetails from "./QuotationDetails";
import RequisitionDetails from "./RequisitionDetails";

const OrderRequisitionDetails: React.FC = () => {
  const { orderRequisitionId } = useParams() as { orderRequisitionId: string };
  const navigate = useNavigate();
  const orderQuery = useOrderRequisitionQuery({ orderId: orderRequisitionId });

  if (orderQuery.isPending || orderQuery.isFetching) {
    return <Loading />;
  }

  if (orderQuery.status === "success") {
    if (orderQuery.data.type === "REQUISITION") {
      return <RequisitionDetails requisition={orderQuery.data} />;
    }

    if (orderQuery.data?.type === "ORDER") {
      return <OrderDetails refetch={orderQuery.refetch} order={orderQuery.data} />;
    }

    if (orderQuery.data?.type === "QUOTATION") {
      return <QuotationDetails quotation={orderQuery.data} />;
    }

    if (orderQuery.data?.type === "DRAFT") {
      return <OrderDetails refetch={orderQuery.refetch} order={orderQuery.data} />;
    }
  }
  return <NotFoundErrorPage onButtonClick={() => navigate(routes.orders)} />;
};

export default OrderRequisitionDetails;
