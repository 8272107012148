import { DefaultError, InfiniteData, QueryKey, useInfiniteQuery } from "@tanstack/react-query";

import { OrderRequisition } from "@web/common/network/model";

import { LEGACY_getOrderRequisitionsList } from "../api/LEGACY_getOrderRequisitionsList";
import { LEGACY_GetOrderRequisitionsListParams } from "../models/LEGACY_GetOrderRequisitionsListParams";

type OrderRequisitionsListResponse = {
  items: OrderRequisition[];
  cursor: string | undefined;
  requisitionsWaitingForApproval: number;
};

// TODO #9356: Remove CLOSED status related code, since it ceased to exist (remove this file)
export const LEGACY_useOrderRequisitionsListQuery = (
  { status, vesselId }: Omit<LEGACY_GetOrderRequisitionsListParams, "cursor">,
  settings = {}
) =>
  useInfiniteQuery<
    OrderRequisitionsListResponse,
    DefaultError,
    InfiniteData<OrderRequisitionsListResponse>,
    QueryKey,
    string
  >({
    queryKey: ["LEGACY_orderRequisitionInfinite", status, vesselId],
    queryFn: ({ pageParam }) =>
      LEGACY_getOrderRequisitionsList({
        cursor: pageParam,
        status,
        vesselId,
      }),
    initialPageParam: "",
    ...settings,
    getNextPageParam: (lastPage) => {
      if (lastPage.cursor !== "") {
        return lastPage.cursor;
      }
    },
    refetchOnWindowFocus: false,
  });
