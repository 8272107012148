import { Route, Routes as RoutesSwitch, useNavigate } from "react-router-dom";

import { NotFoundErrorPage } from "@web/ui";

import "./App.css";
import routes from "./config/routes";
import { EditOrderRequisition } from "./pages/EditOrderRequisition";
import { Gather } from "./pages/Gather";
import { GatherBasketPage } from "./pages/GatherBasket";
import { GatherCatalogPage } from "./pages/GatherCatalog/GatherCatalog";
import { GatherCategoriesPage } from "./pages/GatherCategories";
import { GatherProductDetails } from "./pages/GatherProductDetails";
import { GatherSearchPage } from "./pages/GatherSearch";
import ManageCustomers from "./pages/ManageCustomers";
import ManageFleets from "./pages/ManageFleets";
import ManagePurchaserVessels from "./pages/ManagePurchaserVessels";
import ManagePurchasers from "./pages/ManagePurchasers";
import ManageVessels from "./pages/ManageVessels";
import OrderRequisitionDetails from "./pages/OrderRequisitionDetails";
import OrderSuccess from "./pages/OrderSuccess";
import { OrderSummaryPage } from "./pages/OrderSummary";
import Orders from "./pages/Orders";
import { PortSelection } from "./pages/PortSelection/PortSelection";

export const Routes = () => {
  const navigate = useNavigate();

  return (
    <RoutesSwitch>
      <Route path={routes.orders} element={<Orders />} />
      <Route path={routes.orderInfo} element={<OrderSummaryPage />} />
      <Route path={`${routes.orderSuccess}/:orderId`} element={<OrderSuccess />} />
      <Route path={routes.manageVessels} element={<ManageVessels />} />
      <Route path={routes.manageCustomers} element={<ManageCustomers />} />
      <Route path={routes.manageUsers} element={<ManagePurchasers />} />
      <Route path={`${routes.manageUsers}/:id`} element={<ManagePurchaserVessels />} />
      <Route path={routes.manageFleets} element={<ManageFleets />} />
      <Route path={routes.preconfigureOrderSetup} element={<PortSelection />} />
      <Route path={routes.gatherBasket} element={<GatherBasketPage />} />
      <Route
        path={`${routes.orderDetails}/:orderRequisitionId`}
        element={<OrderRequisitionDetails />}
      />
      <Route
        path={`${routes.orderDetails}/:orderRequisitionId/edit`}
        element={<EditOrderRequisition />}
      />
      <Route
        path={`${routes.orderDetails}/:orderRequisitionId/approve`}
        element={<OrderSummaryPage />}
      />

      <Route path="gather" element={<Gather />}>
        <Route path={"catalog"} element={<GatherCatalogPage />} />
        <Route path={"catalog/:id"} element={<GatherCategoriesPage />} />
        <Route path={"product/:id"} element={<GatherProductDetails />} />
        <Route path={"search"} element={<GatherSearchPage />} />
      </Route>
      <Route
        path="*"
        element={<NotFoundErrorPage onButtonClick={() => navigate(routes.orders)} />}
      />
    </RoutesSwitch>
  );
};
