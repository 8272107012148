import { ApiResponse, handleResponse } from "../store/utils";
import { AppOrderRequisition, AppQuotationApproveRequest, MainAppService } from "../typegens";

export const approveQuotation = async ({
  id,
  requestBody,
}: {
  id: string;
  requestBody: AppQuotationApproveRequest;
}): Promise<ApiResponse<AppOrderRequisition>> => {
  const result = await handleResponse(() =>
    MainAppService.approveQuotation({ quotationId: id, requestBody })
  );

  return result;
};
