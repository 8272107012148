import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DatePicker, Paragraph, TimePicker } from "@web/ui";
import { extractFromISODateString } from "@web/utils";

interface Props {
  errors?: {
    deliveryDate?: {
      time?: {
        message?: string;
      };
      date?: {
        message?: string;
      };
    };
  };
  initialValue?: string;
  onChange: (time: string, date: string) => void;
  optional?: boolean;
  placeholder?: string;
  datePickerAdditionalProps?: object;
  timePickerAdditionalProps?: object;
  title?: React.ReactNode;
}

export const DeliveryDate: React.FC<Props> = ({
  datePickerAdditionalProps = {},
  title,
  errors = {},
  initialValue = "",
  onChange,
  optional,
  placeholder,
  timePickerAdditionalProps = {},
}) => {
  const { t } = useTranslation();
  const [time, setTime] = useState<string>(extractFromISODateString(initialValue, "justTime"));
  const [date, setDate] = useState<string>(extractFromISODateString(initialValue, "yearFirst"));

  const onTimeChange = (currentTime: string) => {
    setTime(currentTime);
    onChange(currentTime, date);
  };

  const onDateChange = (currentDate: string) => {
    setDate(currentDate);
    onChange(time, currentDate);
  };

  return (
    <div className="flex flex-col gap-4">
      {title}
      <div className="flex gap-4">
        <DatePicker
          label={
            optional
              ? t("components.contactInfo.etaDateOptional")
              : t("components.contactInfo.etaDate")
          }
          additionalProps={datePickerAdditionalProps}
          onChange={onDateChange}
          dataTestId="deliveryDate_datePicker"
          initialValue={extractFromISODateString(initialValue, "yearFirst")}
          error={
            errors?.deliveryDate?.date?.message && (
              <Paragraph size="200" color="text-dangerDefault">
                {errors?.deliveryDate?.date?.message}
              </Paragraph>
            )
          }
          placeholder={placeholder ?? t("components.contactInfo.etaDatePlaceholder")}
        />
        <TimePicker
          label={
            optional
              ? t("components.contactInfo.etaTimeOptional")
              : t("components.contactInfo.etaTime")
          }
          additionalProps={timePickerAdditionalProps}
          onChange={onTimeChange}
          initialValue={extractFromISODateString(initialValue, "justTime")}
          dataTestId="deliveryDate_timePicker"
          description={t("components.contactInfo.timePort")}
          placeholder={t("components.contactInfo.etaTimePlaceholder")}
          error={
            errors?.deliveryDate?.time?.message && (
              <Paragraph size="200" color="text-dangerDefault">
                {errors?.deliveryDate?.time?.message}
              </Paragraph>
            )
          }
        />
      </div>
    </div>
  );
};
