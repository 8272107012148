/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AgentInformation as IAgentInformation } from "@web/models";
import { FormSection, Input } from "@web/ui";

interface AgentInformationProps {
  defaultAgentInformation?: IAgentInformation;
  onChange?: (agentInformation: IAgentInformation) => void;
  required?: Record<string, boolean>;
  register?: UseFormRegister<any>;
  errors?: Record<string, any>;
}

interface Props {
  register: any;
  errors?: Record<string, any>;
  required?: Record<string, boolean>;
}

const FirstName = ({ register, errors, required }: Props) => {
  const { t } = useTranslation();

  return (
    <Input
      {...register("agentInformation.firstName", {
        required: {
          value: required?.firstName,
          message: t("components.contactInfo.firstNameEmpty"),
        },
      })}
      label={t("components.contactInfo.firstNamePlaceholder")}
      placeholder={t("components.contactInfo.firstNamePlaceholder")}
      errorMessage={errors?.agentInformation?.firstName?.message}
      withBorder
    />
  );
};

const LastName = ({ register, errors, required }: Props) => {
  const { t } = useTranslation();
  return (
    <Input
      {...register("agentInformation.lastName", {
        required: {
          value: required?.lastName,
          message: t("components.contactInfo.lastNameEmpty"),
        },
      })}
      label={t("components.contactInfo.lastNameLabel")}
      placeholder={t("components.contactInfo.lastNamePlaceholder")}
      errorMessage={errors?.agentInformation?.lastName?.message}
      withBorder
    />
  );
};

const Company = ({ register, errors, required }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Input
        {...register("agentInformation.company", {
          required: {
            value: required?.company,
            message: t("components.contactInfo.companyEmpty"),
          },
        })}
        label={t("components.contactInfo.companyLabel")}
        placeholder={t("components.contactInfo.companyPlaceholder")}
        errorMessage={errors?.agentInformation?.company?.message}
        withBorder
        testId="agentInformation_company"
      />
    </>
  );
};

const Email = ({ register, errors, required }: Props) => {
  const { t } = useTranslation();
  return (
    <Input
      {...register("agentInformation.email", {
        required: {
          value: required?.email,
          message: t("components.contactInfo.agentEmailEmpty"),
        },
      })}
      label={t("components.contactInfo.agentEmailLabel")}
      placeholder={t("components.contactInfo.agentEmailPlaceholder")}
      errorMessage={errors?.agentInformation?.email?.message}
      withBorder
      testId="agentInformation_email"
    />
  );
};

const PhoneNumber = ({ register, errors, required }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Input
        {...register("agentInformation.phoneNumber", {
          required: {
            value: required?.phoneNumber,
            message: t("components.contactInfo.phoneNumberEmpty"),
          },
        })}
        label={t("components.contactInfo.phoneNumberLabel")}
        placeholder={t("components.contactInfo.phoneNumberLabel")}
        errorMessage={errors?.agentInformation?.phoneNumber?.message}
        withBorder
        testId="agentInformation_phone"
      />
    </>
  );
};

export const AgentInformation: React.FC<AgentInformationProps> = ({
  required,
  register = () => ({}),
  errors,
}) => {
  const { t } = useTranslation();

  const props = { errors, register, required };

  return (
    <FormSection
      title={t("components.contactInfo.agentInformation")}
      subtitle={t("components.contactInfo.agentInfoDescription")}
      testId="agentInformation"
    >
      <div className="flex flex-col gap-6 bg-neutral_0 border rounded pr-4 pl-4 pt-6 pb-8">
        <div className="flex space-between w-full gap-2">
          <div className="w-full">
            <FirstName {...props} />
          </div>
          <div className="w-full">
            <LastName {...props} />
          </div>
        </div>
        <Company {...props} />
        <Email {...props} />
        <PhoneNumber {...props} />
      </div>
    </FormSection>
  );
};
