import { ApiError } from "../typegens";

export type ApiResponse<D> =
  | {
      data: D;
    }
  | {
      error: ApiError | unknown;
    };

export const handleResponse = async <D>(
  fetchFunction: () => Promise<D>
): Promise<ApiResponse<D>> => {
  try {
    const response = await fetchFunction();
    return { data: response };
  } catch (err: unknown) {
    if (err instanceof TypeError) {
      console.error("ERROR: INTERNET DISCONNECTED");
    }
    return { error: err };
  }
};
