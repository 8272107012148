/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import { ApiRequestOptions } from "../core/ApiRequestOptions";
import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import type { ApiListAppCategory } from "../models/ApiListAppCategory";
import type { AppConfiguration } from "../models/AppConfiguration";
import type { AppVesselConfiguration } from "../models/AppVesselConfiguration";

export class HomeService {
  /**
   * @returns ApiListAppCategory OK
   * @throws ApiError
   */
  public static getConfigurationCategories(
    {
      vesselId,
      portId,
      portLocationCode,
    }: {
      vesselId: string;
      portId?: string;
      portLocationCode?: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<ApiListAppCategory> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/configuration/{vesselId}/categories",
      path: {
        vesselId: vesselId,
      },
      query: {
        portId: portId,
        portLocationCode: portLocationCode,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppVesselConfiguration OK
   * @throws ApiError
   */
  public static getConfigurationByVessel(
    {
      vesselId,
    }: {
      vesselId: string;
    },
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppVesselConfiguration> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/configuration/{vesselId}",
      path: {
        vesselId: vesselId,
      },
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }

  /**
   * @returns AppConfiguration OK
   * @throws ApiError
   */
  public static index(
    options?: Pick<ApiRequestOptions, "headers" | "signal">
  ): CancelablePromise<AppConfiguration> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/app/configuration",
      ...(options?.headers ? { headers: options.headers } : {}),
      ...(options?.signal ? { signal: options.signal } : {}),
    });
  }
}
