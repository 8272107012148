export const AnchorWhite = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 4.6C7.99411 4.6 8.8 3.79411 8.8 2.8C8.8 1.80589 7.99411 1 7 1C6.00589 1 5.2 1.80589 5.2 2.8C5.2 3.79411 6.00589 4.6 7 4.6ZM7 4.6V13M7 13C5.4087 13 3.88258 12.3679 2.75736 11.2426C1.63214 10.1174 1 8.5913 1 7H2.8M7 13C8.5913 13 10.1174 12.3679 11.2426 11.2426C12.3679 10.1174 13 8.5913 13 7H11.2"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
