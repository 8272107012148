import { Loading } from "@web/ui";

import { usePortsQuery } from "src/hooks/usePortsQuery";

import { useCustomersQuery } from "../../hooks/useCustomersQuery";
import { useFleetsQuery } from "../../hooks/useFleetsQuery";
import { ManageFleets } from "./ManageFleets";

export const ManageFleetsQuery = () => {
  const fleetsQuery = useFleetsQuery();
  const customersQuery = useCustomersQuery();
  const portsQuery = usePortsQuery();

  if (!(fleetsQuery.isSuccess && customersQuery.isSuccess && portsQuery.isSuccess)) {
    return <Loading />;
  }

  const fleets = fleetsQuery.data;
  const customers = customersQuery.data;

  return <ManageFleets fleets={fleets} customers={customers} ports={portsQuery.data.ports} />;
};
