import { DefaultError, useMutation } from "@tanstack/react-query";

import { approveRfq } from "../api/approveRfq";
import { ApiResponse } from "../store";
import { AppOrderRequisition, AppQuotationApproveRequest } from "../typegens";
import { useSuccessCreationCallback } from "./useSuccessCreationCallback";

export const useApproveRfqMutation = () => {
  const successCb = useSuccessCreationCallback();

  return useMutation<
    ApiResponse<AppOrderRequisition>,
    DefaultError,
    { id: string; requestBody: AppQuotationApproveRequest }
  >({
    mutationKey: ["approveRfq"],
    mutationFn: approveRfq,
    onSuccess: successCb,
  });
};
