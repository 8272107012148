import { useTranslation } from "react-i18next";

import { OrderRequisitionType } from "@web/common/network/model";
import { Paragraph } from "@web/ui";

export const TypeLabel = ({
  className,
  type,
}: {
  className?: string;
  type: OrderRequisitionType;
}) => {
  const { t } = useTranslation();
  const color =
    type === "QUOTATION"
      ? "text-primaryDefault"
      : type === "DRAFT"
      ? "text-primaryDefault"
      : "text-textIcon-blackSecondary";

  return (
    <Paragraph size="300" color={color} className={className}>
      {t(`common.orderType.${type}`)}
    </Paragraph>
  );
};
