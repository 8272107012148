import { DocumentTextIcon, DownloadIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

import { Attachment } from "@web/common/models/Attachment";
import { attachmentTypeToAttachmentNameMap } from "@web/common/utils";
import { Paragraph } from "@web/ui";
import { formatFileSize } from "@web/utils";

import { ContractableSection } from "./SkuAttributesUtils";

interface Props {
  attachments?: Array<Attachment>;
}

export const AttachmentsSection = ({ attachments }: Props) => {
  const { t } = useTranslation();

  if (!attachments) {
    return null;
  }

  if (Object.values(attachments).length === 0) {
    return null;
  }

  return (
    <ContractableSection title={t("common.views.productDetails.attachments")}>
      {attachments.map((attachment, index) => {
        return (
          <div key={index} className="flex border-b my-6 pb-3">
            <div className="w-8 h-8 bg-neutral_200 flex content-center justify-center mr-6">
              <DocumentTextIcon className="w-6 text-primaryDefault" />
            </div>
            <div className="flex flex-col">
              <Paragraph size="100">
                {attachmentTypeToAttachmentNameMap.get(attachment.type)}
              </Paragraph>
              <Paragraph size="200" className="text-grey-400">
                {attachment.description}
              </Paragraph>
            </div>
            <div className="ml-auto flex flex-col">
              <a target="_blank" href={attachment.attachmentUrl}>
                <div className="inline-flex justify-center items-center py-3 overflow-visible border rounded-md shadow-sm whitespace-nowrap focus-visible:ring-2 focus-visible:ring-primaryDefault outline-none h-6.5 text-base font-medium px-4 text-primaryDefault bg-primaryBackground hover:bg-primaryDefault border-transparent ml-auto">
                  <DownloadIcon className="w-5" />
                  Download
                </div>
              </a>
              <Paragraph size="200" className="text-center text-grey-400">
                {formatFileSize(attachment.fileSize)}
              </Paragraph>
            </div>
          </div>
        );
      })}
    </ContractableSection>
  );
};
