import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getTotalPriceInBasket } from "@web/common/utils/basket";
import { OrderConfiguratorHeader, OrderConfiguratorSummary } from "@web/ui";

import { createDraft } from "src/api/createDraft";
import { updateDraft } from "src/api/updateDraft";
import { useDraft } from "src/hooks/useDraft";
import { DraftRequest } from "src/typegens";

import routes from "../../../config/routes";
import { RootState, clearGatherBasket, useAppDispatch } from "../../../store";

export const BasketHeader: React.FC = () => {
  const navigate = useNavigate();
  const { basket, vessel, draftId } = useSelector((state: RootState) => state.gather);
  const { getDraftFromState } = useDraft();

  const dispatch = useAppDispatch();

  const handleProceedToOrderClick = () => {
    navigate(routes.orderInfo);
  };

  const onCancelClick = () => {
    dispatch(clearGatherBasket());
    navigate(routes.orders);
  };

  const handleCreateDraft = (draftRequest: DraftRequest) => {
    const makeRequest = draftId
      ? () => updateDraft({ draftId, requestBody: draftRequest })
      : createDraft;

    makeRequest(draftRequest).then((res) => {
      navigate(`${routes.orderDetails}/${res.id}`);
    });
  };

  const itemsLength = Object.keys(basket.catalogItems).length;
  const currentGrandTotal = getTotalPriceInBasket(basket.catalogItems);

  const isEmpty = currentGrandTotal.amount === 0;

  // TODO #2441: Add E2E tests confirming labels and presence of components
  return (
    <OrderConfiguratorHeader
      headerLabel={`Edit ${vessel?.name} basket`}
      submitButtonLabel="Proceed to Order"
      isSubmitButtonDisabled={itemsLength === 0}
      hasSubmitButtonIcon={true}
      onSubmit={handleProceedToOrderClick}
      cancelButtonLabel="Cancel"
      onCancel={onCancelClick}
      hasOrderSummary={!isEmpty}
      renderSaveDraftButton={Object.keys(basket.catalogItems).length > 0}
      onSaveDraft={() => handleCreateDraft(getDraftFromState())}
      saveDraftLabel="Save Draft"
      renderOrderSummary={() => (
        <OrderConfiguratorSummary
          itemsQuantity={itemsLength}
          originalGrandTotal={currentGrandTotal}
          isGrandTotalVisible={true}
        />
      )}
      testId="basketHeader"
    />
  );
};
