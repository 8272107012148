import { useQuery } from "@tanstack/react-query";

import { Product } from "@web/common/models/Product";

import { handleResponse } from "../store";
import { ApiError, ProductsService } from "../typegens";

export const useProductQuery = (
  { id, portId, vesselId }: { id: string; portId: string; vesselId: string },
  settings = {}
) =>
  useQuery<Product, ApiError>({
    ...settings,
    queryKey: ["product", id, portId, vesselId],
    queryFn: async () => {
      const response = await handleResponse(() =>
        ProductsService.getProductSku({ id: encodeURIComponent(id), portId, vesselId })
      );
      if ("data" in response) {
        return response.data;
      }
      throw response.error;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
