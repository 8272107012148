import { XIcon } from "@heroicons/react/outline";
import { ArchiveIcon } from "@heroicons/react/solid";

import { Paragraph, RegularButton } from "@web/ui";

interface Props {
  isLoading: boolean;
  onCloseModal: () => void;
  onCloseOrder: () => void;
}

export const CloseOrderModal: React.FC<Props> = ({ isLoading, onCloseModal, onCloseOrder }) => {
  return (
    <div
      className="inline-block align-bottom bg-neutral_0 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      data-testid="closeOrderModal"
    >
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-text-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={onCloseModal}
          data-testid="closeOrderModal_closeButton"
        >
          <span className="sr-only">Cancel & Close Modal</span>
          <XIcon className="h-5 w-5 text-text-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start py-4 pl-2 pr-4">
        <div className="mx-auto shrink-0 flex items-center justify-center p-2 rounded-full bg-neutral_200 sm:mx-0">
          <ArchiveIcon className="w-5 text-text-whiteDisabled" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Paragraph size="100">Are you sure you want to close the order?</Paragraph>
          <div className="mt-2">
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              Your order will be marked as closed. This action cannot be undone.
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 flex flex-col gap-3 sm:flex-row-reverse">
        <RegularButton
          variant="primary"
          size="large"
          label="Close Order"
          loading={isLoading}
          onClick={onCloseOrder}
        />
        <RegularButton
          variant="secondary"
          size="large"
          label="Cancel"
          onClick={onCloseModal}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};
