import { XIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Paragraph, TextColors } from "@web/ui";

import { OrderRequisitionStatus, OrderStatus } from "../network/model";

interface Props {
  status: OrderRequisitionStatus;
}

const Banner: React.FC<Props> = ({ status }) => {
  let bgColor;
  let textColor: TextColors | undefined;
  let iconColor;
  let bannerText;
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState<boolean>(false);

  switch (status) {
    case OrderStatus.PENDING_CANCELLATION:
      bgColor = "bg-warningDefault";
      textColor = "text-neutral_0";
      iconColor = "text-neutral_0";
      bannerText = "pendingCancellation";
      break;
    case OrderStatus.CANCELLED:
      bgColor = "bg-warningDefault";
      textColor = "text-neutral_0";
      iconColor = "text-neutral_0";
      bannerText = "canceled";
      break;
  }

  return (
    <>
      {!isHidden &&
        (status === OrderStatus.PENDING_CANCELLATION || status === OrderStatus.CANCELLED) && (
          <div className={`flex items-center justify-between ${bgColor} py-3 px-4`}>
            <Paragraph size="200" color={textColor} className="px-1 m-auto">
              {t(`common.layouts.banner.${bannerText}`)}
            </Paragraph>
            <XIcon
              onClick={() => setIsHidden(true)}
              className={`w-5 ${iconColor} cursor-pointer`}
            />
          </div>
        )}
    </>
  );
};

export default Banner;
