import { ProductSku } from "@web/common/models";
import { OrderItem } from "@web/common/network/model";

export const applyVariantPriceToProducts = ([product, variant]: [
  product: OrderItem,
  variant: ProductSku
]) => ({
  ...product,
  totalAmount: {
    ...product.singleGrossPrice,
    amount: variant.price.costPrice.amount * product.entityQuantity,
  },
  singleGrossPrice: variant.price.costPrice,
  originalSingleGrossPrice: product.singleGrossPrice,
});
