import { useTranslation } from "react-i18next";

import { Label, Paragraph } from "@web/ui";

import Avatars from "../components/Avatars";
import WhatsApp from "../icons/WhatsApp.svg";

const ContactCard = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center pt-2 pb-1">
      <Avatars />
      <Paragraph
        size="200"
        className="pb-2"
        /* color="text-text-whiteDisabled" */
        // TODO #6444: Fixme, there is no such color in tailwind config
      >
        {t("common.contactCard.inquiriesText")}
      </Paragraph>
      <div className="flex py-1">
        <img src={WhatsApp} alt="Whatsapp" />
        <Label size="200" color="text-textIcon-blackSecondary" className="pl-2">
          {t("common.contactCard.mobile")}
        </Label>
      </div>
      <div className="py-2 flex flex-col items-center">
        <Label size="200" color="text-textIcon-blackSecondary">
          {t("common.contactCard.or")}
        </Label>
        <Paragraph size="200" color="text-textIcon-blackSecondary" className="underline pt-1">
          <a href={`mailto:${t("common.contactCard.email")}`}>{t("common.contactCard.email")}</a>
        </Paragraph>
      </div>
    </div>
  );
};

export default ContactCard;
