import { InformationCircleIcon } from "@heroicons/react/solid";
import { useFlag } from "@unleash/proxy-client-react";
import { useTranslation } from "react-i18next";
import "react-popper-tooltip/dist/styles.css";

import { Tooltip, Heading as TypographyHeading } from "@web/ui";

import { headingMap } from "./utils";

type Props = {
  status: string;
  closed: boolean;
};

export const Heading: React.FC<Props> = ({ status, closed }) => {
  const { t } = useTranslation();
  const category = headingMap[status] || {};
  const isCloseAttributeEnabled = useFlag("close-attribute");

  let groupLabel: string;

  if (closed) {
    groupLabel = "Closed Orders";
  } else if (!status) {
    groupLabel = isCloseAttributeEnabled ? "Active Orders" : "All";
  } else {
    groupLabel = t(category.group);
  }

  return (
    <div className="flex items-center" data-testid="overviewTableHeading">
      <TypographyHeading size="300" className="capitalize">
        {groupLabel}
      </TypographyHeading>
      {!closed && status !== "" && (
        <TypographyHeading size="300" color="text-textIcon-blackSecondary" className="ml-2">
          | {t(`layouts.base.status.${status}`)}
        </TypographyHeading>
      )}
      {!!category.tooltip && (
        <div className="my-auto">
          <Tooltip tooltipText={t(`layouts.base.tooltip.${status}`)} className="m-2">
            <InformationCircleIcon className="w-4 h-4 text-textIcon-blackSecondary" />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
