import { ThumbDownIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

import { Label, Paragraph } from "@web/ui";
import { LEGACY_formatDateTime } from "@web/utils";

import { OrderRequisitionHistory } from "../../../network/model";

interface RejectionProps {
  name: string;
  date: string;
  note?: string;
}

const Rejection: React.FC<RejectionProps> = ({ name, date, note }) => {
  const { t } = useTranslation();

  return (
    <div className="flex">
      <div className="flex flex-col items-center">
        <ThumbDownIcon className="text-dangerDefault w-5 h-5 mr-2 mt-1" />
        <div className="w-1 h-full border-l-2 mt-1 pl-1" />
      </div>
      <div className="flex flex-col">
        <span>
          <Label size="200">{name}</Label>
          <Label size="200" color="text-textIcon-blackSecondary" className="pl-1">
            {t("common.components.activityBox.rejectedThisRequisition")}
          </Label>
        </span>
        <Paragraph size="300" color="text-textIcon-blackSecondary" className="pt-2">
          {LEGACY_formatDateTime(new Date(date))}
        </Paragraph>
        <div className="p-2 mt-2 border rounded shadow flex">
          <div className="w-1 h-full border-l-2 mr-2" />
          <Label size="200">{note}</Label>
        </div>
      </div>
    </div>
  );
};

interface Props {
  historyList: Array<OrderRequisitionHistory>;
}

export const History: React.FC<Props> = ({ historyList }) => {
  const { t } = useTranslation();
  return (
    <div className="py-3">
      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pb-3">
        {t("common.components.activityBox.history")}
      </Paragraph>
      {historyList.map(
        (history) =>
          history.status === "PURCHASER_REJECTED" && (
            <Rejection
              name={history.name}
              date={history.date}
              note={history.note}
              key={`${history.note}-${history.note}`}
            />
          )
      )}
    </div>
  );
};
