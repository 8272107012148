import { OrderInfo, OrderInfoFormValues } from "@web/common";
import FormLayout from "@web/common/layouts/FormLayout";
import { BasketRfqEntry } from "@web/common/models/basket";
import { OrderRequisition, RfqItem } from "@web/common/network/model";

import { useApproveRfqMutation } from "../../../hooks/useApproveRfqMutation";
import { toQuotationApproveItem } from "./utils";

interface Props {
  requisitionId: string;
  rfqItems: BasketRfqEntry[];
  orderRequisition: OrderRequisition;
}

export const RfqApproval = ({ requisitionId, rfqItems, orderRequisition }: Props) => {
  const { mutate, isPending } = useApproveRfqMutation();
  const items = rfqItems
    .filter((entry) => entry.quantity)
    .map((entry) => toQuotationApproveItem(entry.item));
  const hasEditedItems = items.length > 0;

  const submitOrderHandler = async (orderSummary: OrderInfoFormValues) => {
    // This guard is only to make the compiler happy, without major refactor of OrderInfo component.
    // In reality this will never happen thanks to the validation set up in OrderInfo component.
    if (!orderSummary.deliveryDate) {
      throw Error("Delivery date not defined");
    }

    const result = {
      id: requisitionId,
      requestBody: {
        agent: orderSummary.agentInformation || {
          firstName: "",
          lastName: "",
          company: "",
          email: "",
          phoneNumber: "",
        },
        consolidated: true,
        deliveryDate: orderSummary.deliveryDate,
        orderNotes: orderSummary.orderNotes,
        storageLabel: orderSummary.storageLabel,
        customerOrderId: orderSummary.customerOrderId,
        items: hasEditedItems
          ? items
          : (orderRequisition.rfqItems as RfqItem[]).map((item) => toQuotationApproveItem(item)),
        warehouseId: orderSummary.warehouseId,
      },
    };

    if (!orderSummary.agentInformation) {
      throw Error("Agent info not defined");
    }
    if (!orderSummary.deliveryDate) {
      throw Error("Delivery date not defined");
    }

    mutate(result);
  };

  return (
    <FormLayout>
      <OrderInfo
        creationMode={"QUOTATION_CREATION"}
        grandTotal={orderRequisition.totalGrossAmount}
        loading={isPending}
        nrLineItems={0}
        nrRfqItems={hasEditedItems ? items.length : (orderRequisition.rfqItems as RfqItem[]).length}
        orderRequisition={orderRequisition}
        port={orderRequisition.port}
        submitForm={submitOrderHandler}
        vessel={orderRequisition.vessel}
        withOrderComment
      />
    </FormLayout>
  );
};
