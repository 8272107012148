import { useQuery } from "@tanstack/react-query";

import { getVessels } from "../api/getVessels";
import { AppVessel } from "../typegens";

export const useVesselsQuery = () =>
  useQuery<AppVessel[]>({
    queryKey: ["vessels"],
    queryFn: getVessels,
    refetchOnMount: false,
  });
