import { ProductItemsTable } from "@web/common/components";
import { OrderItem } from "@web/common/network/model";
import { applyVariantPriceToProducts } from "@web/common/utils";
import { Label } from "@web/ui";

import { AppProductSku } from "src/typegens";
import { pairProductsWithVariants } from "src/utils/pairProductsWithVariants";

interface Props {
  sourceProducts: OrderItem[];
  productVariants: AppProductSku[];
}

export const ProductDiffList: React.FC<Props> = ({ sourceProducts, productVariants }) => {
  const pairedProducts = pairProductsWithVariants(sourceProducts)(productVariants);
  const unavailableProducts = pairedProducts.filter((e) => !e[1]).map((e) => e[0]);
  const availableProducts = (
    pairedProducts.filter((e) => e[1]) as Array<[OrderItem, AppProductSku]>
  ).map((e) => applyVariantPriceToProducts(e));

  return (
    <>
      {unavailableProducts.length > 0 && (
        <>
          <div className="flex flex-row justify-between">
            <Label size="300" className="text-text-whiteDisabled uppercase">
              Not Available
            </Label>
            <Label size="300" className="text-text-whiteDisabled">
              {unavailableProducts.length} ITEM{unavailableProducts.length > 1 && "S"}
            </Label>
          </div>
          <ProductItemsTable
            productItems={unavailableProducts}
            lineThrough={true}
            sideNote="Not Available"
          />
        </>
      )}
      {availableProducts.length > 0 && (
        <>
          <div className="flex flex-row justify-between mt-2">
            <Label size="300" className="text-text-whiteDisabled uppercase">
              Available
            </Label>
            <Label size="300" className="text-text-whiteDisabled">
              {availableProducts.length} ITEM{availableProducts.length > 1 && "S"}
            </Label>
          </div>
          <ProductItemsTable productItems={availableProducts} sideNote="Available" />
        </>
      )}
    </>
  );
};
