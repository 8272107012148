import { InviteVesselForm } from "src/components/modals/InviteVesselModal/schema";
import { ApiResponse, handleResponse } from "src/store";
import { AppVesselTokenShared } from "src/typegens/models/AppVesselTokenShared";

import { MainAppService } from "../typegens";

export interface IInviteVessel {
  id: string;
  form: InviteVesselForm;
}

export const inviteVessel = async ({
  id,
  form,
}: IInviteVessel): Promise<ApiResponse<AppVesselTokenShared>> => {
  const result = await handleResponse(() =>
    MainAppService.shareVesselToken({
      id,
      requestBody: {
        name: form.name,
        email: form.email,
      },
    })
  );

  return result;
};
