import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getTotalPriceInBasket } from "@web/common/utils/basket";
import { OrderConfiguratorHeader, OrderConfiguratorSummary } from "@web/ui";

import routes from "../../../config/routes";
import { RootState } from "../../../store";

export const EditOrderRequisitionHeader: React.FC = () => {
  const navigate = useNavigate();
  const { basket } = useSelector((state: RootState) => state.gather);

  const isRfqRequisition = basket.isRfqRequisition;
  const isQuotation = Boolean(basket.isQuotation);

  const handleProceedToOrderClick = () => {
    navigate(`${routes.orderDetails}/${basket.requisitionId}/approve`);
  };

  const onCancelClick = () => {
    navigate(`${routes.orderDetails}/${basket.requisitionId}`);
  };

  const itemsLength = Object.keys(basket.catalogItems).length;
  const rfqItemsLength = Object.keys(basket.rfqItems).length;
  const currentGrandTotal = getTotalPriceInBasket(basket.catalogItems);
  const originalGrandTotal = getTotalPriceInBasket(basket.requisitionItems);

  const isEmpty = originalGrandTotal.amount === 0 && currentGrandTotal.amount === 0;

  // TODO #2441: Add E2E tests confirming labels and presence of components
  return (
    <OrderConfiguratorHeader
      headerLabel={isQuotation ? "Edit Quotation" : "Edit Requisition"}
      submitButtonLabel={isRfqRequisition ? "Request for Quotation" : "Proceed to Order"}
      isSubmitButtonDisabled={itemsLength === 0 && rfqItemsLength === 0}
      hasSubmitButtonIcon={true}
      onSubmit={handleProceedToOrderClick}
      cancelButtonLabel="Cancel"
      onCancel={onCancelClick}
      hasOrderSummary={!isEmpty}
      renderOrderSummary={() => (
        <OrderConfiguratorSummary
          itemsQuantity={itemsLength}
          originalGrandTotal={originalGrandTotal}
          newGrandTotal={currentGrandTotal}
          isGrandTotalVisible={!isRfqRequisition && currentGrandTotal.amount !== 0}
          isNewGrandTotalVisible={currentGrandTotal.amount !== originalGrandTotal.amount}
        />
      )}
    />
  );
};
