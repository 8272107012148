import { OrderRequisition } from "../network/model";

export const getOrderDate = (
  order: OrderRequisition
):
  | OrderRequisition["orderDate"]
  | OrderRequisition["requestDate"]
  | OrderRequisition["quotationDate"] => {
  switch (order.type) {
    case "ORDER":
      return order.orderDate;
    case "REQUISITION":
      return order.requestDate;
    case "QUOTATION":
      return order.quotationDate;
    default:
      return undefined;
  }
};
