import { useEffect } from "react";

import { useAuth0WithRedirect } from "@web/common/contexts";
import { Loading } from "@web/ui";

export const RedirectToLoginPage: React.FC = () => {
  const { loginWithRedirectToOriginalRoute } = useAuth0WithRedirect();

  useEffect(() => {
    loginWithRedirectToOriginalRoute();
  });

  return <Loading />;
};
