import { InvoiceAccount } from "@web/models";
import { Label } from "@web/ui";

interface Props {
  value?: InvoiceAccount;
}

export const InvoiceAccountUI = ({ value: invoiceAccount }: Props) => {
  return (
    <>
      <div className="text-textIcon-blackSecondary uppercase">{invoiceAccount?.registerOwner}</div>
      {invoiceAccount?.billingAddressLine1 && (
        <div>
          <Label size="300" className="uppercase">
            {invoiceAccount.billingAddressLine1}
          </Label>
        </div>
      )}
      {invoiceAccount?.billingAddressLine2 && (
        <div>
          <Label size="300" className="uppercase">
            {invoiceAccount.billingAddressLine2}
          </Label>
        </div>
      )}
      {invoiceAccount?.billingAddressLine3 && (
        <div>
          <Label size="300" className="uppercase">
            {invoiceAccount.billingAddressLine3}
          </Label>
        </div>
      )}
    </>
  );
};
