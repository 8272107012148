import { ImagorConfig } from "./types";

export const imagorConfig: ImagorConfig = {
  dimensions: {
    height: 200,
    width: 200,
  },
  fitIn: true,
  filters: {
    quality: 90,
    format: "jpeg",
    fill: "white",
  },
};
