import { useSelector } from "react-redux";

import { useSearchInput } from "@web/common/hooks/useSearchHelpers";

import { RootState } from "../store";
import { useSearchQuery } from "./useSearchQuery";

export const useSearch = () => {
  const { port, vessel } = useSelector((state: RootState) => state.gather);
  const { query, sorting, priceMin, priceMax, leadTime } = useSearchInput();

  return useSearchQuery(
    {
      portId: port?.id || "",
      vesselId: vessel?.id || "",
      sorting,
      priceMax,
      priceMin,
      leadTime,
      query,
    },
    { enabled: !!port?.id && !!vessel?.id }
  );
};
