import { DraftRequest, MainAppService } from "../typegens";

export const updateDraft = async ({
  draftId,
  requestBody,
}: {
  draftId: string;
  requestBody: DraftRequest;
}) => {
  const result = await MainAppService.update({
    draftId,
    requestBody,
  });

  return result;
};
