import Avatar1 from "../icons/Avatar1.webp";
import Avatar2 from "../icons/Avatar2.webp";
import Avatar3 from "../icons/Avatar3.webp";
import Avatar4 from "../icons/Avatar4.webp";

const Avatars = () => {
  return (
    <div className="flex -space-x-1 relative overflow-hidden py-3">
      <img
        className="inline-block h-6 w-6 rounded-full ring-2 ring-neutral_0"
        src={Avatar1}
        alt=""
      />
      <img
        className="inline-block h-6 w-6 rounded-full ring-2 ring-neutral_0"
        src={Avatar2}
        alt=""
      />
      <img
        className="inline-block h-6 w-6 rounded-full ring-2 ring-neutral_0"
        src={Avatar3}
        alt=""
      />
      <img
        className="inline-block h-6 w-6 rounded-full ring-2 ring-neutral_0"
        src={Avatar4}
        alt=""
      />
    </div>
  );
};

export default Avatars;
