import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { SuccessModal } from "@web/common/components/modals/SuccessModal";
import { OrderRequisition } from "@web/common/network/model";

import {
  clearGatherBasket,
  setGatherBasketEntry,
  setGatherDraftId,
  setGatherVessel,
  setPreconfigureOrderSetup,
  useAppDispatch,
} from "src/store";
import { AppProductSku } from "src/typegens";

import routes from "../../../config/routes";

interface Props {
  draft: OrderRequisition;
  appProductList: AppProductSku[];
  closeModal: () => void;
}

// in ms
const MODAL_VISIBILITY_SPAN = 1000;

export const AllItemsAvailableModal = ({ draft, appProductList, closeModal }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const updateBasket = (products: AppProductSku[]) => {
    products.map((el, qty) =>
      dispatch(setGatherBasketEntry({ quantity: draft.items[qty].quantity, sku: el }))
    );
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(clearGatherBasket());
      dispatch(setGatherVessel(draft.vessel));
      dispatch(
        setPreconfigureOrderSetup({
          port: draft.port,
          deliveryDate: draft.deliveryDate,
          dutyFreeDeclaration: draft.dutyFreeDeclaration,
        })
      );
      dispatch(setGatherDraftId(draft.id));
      updateBasket(appProductList);
      closeModal();
      navigate(routes.gatherBasket);
    }, MODAL_VISIBILITY_SPAN);
    // We want to run this effect only once. This modal is more like a notification,
    // it should automatically disappear after 1s.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SuccessModal message="All draft items available" closeModal={closeModal} />;
};
