import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { TotalUnitOfMeasure } from "@web/common/components/TotalUnitOfMeasure";
import { prependHttpToUrlIfMissing } from "@web/common/utils/prependHttpToUrlIfMissing";
import { CircledImage, Paragraph, RfqBadge, Tooltip } from "@web/ui";

import VesselIcon from "../icons/VesselIcon.svg";
import { RfqItem } from "../network/model";

interface Props {
  isRemoved: boolean;
  quantity: number;
  rfqfItem: RfqItem;
}

export const RfqLineItem: React.FC<PropsWithChildren<Props>> = ({
  children,
  isRemoved,
  quantity,
  rfqfItem,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`bg-neutral_0 rounded-lg px-4 py-2 mt-2 shadow-sm ${
        isRemoved ? "bg-neutral_200" : ""
      }`}
      style={{ minWidth: 500 }}
    >
      <div className="grid grid-cols-3 items-center">
        <div className="flex flex-col items-start">
          <Paragraph
            size="200"
            color={isRemoved ? "text-textIcon-blackSecondary" : ""}
            className="w-13 mr-2 mb-2 font-medium"
          >
            {rfqfItem.name}
          </Paragraph>
          <Tooltip tooltipText={t("common.rfqTooltip")}>
            <RfqBadge copy={t("common.components.rfqLineItem.rfqItem")} />
          </Tooltip>
        </div>
        <div
          className={`col-span-2 grid grid-cols-2 ${
            children ? "" : "xl:grid-cols-3"
          } justify-items-start flex-grow`}
        >
          {children}
          <TotalUnitOfMeasure
            className={`justify-center sm:grow-0 ml-3 ${children ? "" : "col-start-2"}`}
            lineThrough={isRemoved}
            measurementUnit={rfqfItem.measurementUnit}
            quantityInBasket={isRemoved ? rfqfItem.quantity : quantity}
            salesEntityQuantity={1}
            variant="positive"
          />
        </div>
      </div>
      <div className="flex items-center bg-neutral_100 p-2 mt-4.5">
        <CircledImage
          Icon={VesselIcon}
          hashKey={rfqfItem.id}
          size={5}
          color="bg-LEGACY-cyanVessel"
        />
        <Paragraph size="300" color="text-textIcon-blackSecondary" className="ml-1">
          {rfqfItem.description}{" "}
          {!!rfqfItem.exampleUrl && (
            <a
              className="text-primaryDefault"
              href={prependHttpToUrlIfMissing(rfqfItem.exampleUrl)}
              target="_blank"
            >
              {rfqfItem.exampleUrl}
            </a>
          )}
        </Paragraph>
      </div>
    </div>
  );
};
