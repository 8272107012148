import { XIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getOrderDate } from "@web/common";
import { Pill, TypeLabel } from "@web/common/components";
import { OrderRequisition } from "@web/common/network/model";
import { IconButton, Paragraph } from "@web/ui";
import { LEGACY_formatDate } from "@web/utils";

import routes from "../../config/routes";
import QuotationCTAButtons from "./QuotationCTAButtons";

interface Props {
  quotation: OrderRequisition;
}

const QuotationTopbar: React.FC<Props> = ({ quotation }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const quotationDate = getOrderDate(quotation);

  return (
    <>
      <div className="w-full py-4 px-9 h-min flex-row flex items-center justify-between">
        <div className="flex items-center">
          <IconButton
            variant="secondary"
            shape="circle"
            size="large"
            label="Go back"
            Icon={XIcon}
            onClick={() => {
              navigate(routes.orders);
            }}
          />
          <div className="flex-col">
            <div className="flex align-start pl-5">
              <Paragraph size="100" weight="heavy">
                {quotation.id}
              </Paragraph>
              <div className="pl-4">
                <Pill status={quotation.status} />
              </div>
            </div>
            <div className="flex pt-2 pl-5">
              <TypeLabel className="sm:p-0" type={quotation.type} />
              {!!quotationDate && (
                <Paragraph size="300" color="text-textIcon-blackSecondary" className="pl-4">
                  {t("common.topbar.orderDate")} {LEGACY_formatDate(quotationDate)}
                </Paragraph>
              )}
            </div>
          </div>
        </div>
        <div>
          {quotation.status === "SUPPLIER_QUOTE_PROVIDED" && (
            <QuotationCTAButtons quotationId={quotation.id} />
          )}
        </div>
      </div>
      <hr />
    </>
  );
};
export default QuotationTopbar;
