import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { saveGather } from "./localStorage";
import { authReducer } from "./reducers/authReducer";
import { gatherReducer } from "./reducers/gatherReducer";
import { systemMessagesReducer } from "./reducers/systemMessagesReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  systemMessages: systemMessagesReducer,
  gather: gatherReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

store.subscribe(() => {
  saveGather(store.getState());
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
