import { Link } from "react-router-dom";

import { Paragraph } from "@web/ui";

import { Category } from "../models";

type Props = {
  categories: Category[];
  categoryIdRoute: string;
};

export const HomeCategoriesView: React.FC<Props> = ({ categories, categoryIdRoute }) => {
  const filteredCategories = categories;

  return (
    <>
      {filteredCategories.map((category) => (
        <div key={category.id} className="mb-7">
          <Paragraph size="100" color="text-textIcon-blackSecondary" className="pt-3 pb-4">
            {category.name}
          </Paragraph>
          <ul className="grid gap-6 sm:grid-cols-2 lg:grid-cols-5 pt-1">
            {category.children?.map((subcategory) => (
              <Link to={`${categoryIdRoute}/${subcategory.id}`} key={subcategory.id}>
                <li className="col-span-1 cursor-pointer bg-neutral_0 p-4  rounded shadow">
                  <div className="h-7 text-textIcon-blackPrimary text-center flex items-center justify-center">
                    <Paragraph size="100">{subcategory.name}</Paragraph>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};
