import { useQuery } from "@tanstack/react-query";

import { getPurchaserVessels } from "src/api/getPurchaserVessels";

import { AppPurchaserConfiguration } from "../typegens";

export const usePurchaserVesselsQuery = (id: string) =>
  useQuery<AppPurchaserConfiguration>({
    queryKey: ["purchaserVessels"],
    queryFn: () => getPurchaserVessels({ id }),
    refetchOnMount: false,
  });
