import { createReducer } from "@reduxjs/toolkit";

import { clearSystemMessage, setSystemMessage } from "../actions";
import { SystemMessagesState } from "../models";

const defaultState: SystemMessagesState = {
  systemMessages: [],
};

export const systemMessagesReducer = createReducer(defaultState, (builder) => {
  builder
    .addCase(setSystemMessage, (state, action) => {
      state.systemMessages = [...state.systemMessages, action.payload];
    })
    .addCase(clearSystemMessage, (state, action) => {
      state.systemMessages = state.systemMessages.filter((msg) => msg.id !== action.payload.id);
    });
});
