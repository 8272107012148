import { useState } from "react";

import { Label } from "../../atoms/Typography";
import { CustomerSupportModal, Modal } from "../modals";

export const CustomerSupportWidget = () => {
  const [isCustomerSupportModalOpen, setIsCustomerSupportModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsCustomerSupportModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCustomerSupportModalOpen(false);
  };

  return (
    <>
      <Modal isOpen={isCustomerSupportModalOpen} closeModal={handleCloseModal}>
        <CustomerSupportModal onClose={handleCloseModal} />
      </Modal>
      <div
        className="w-[140px] z-10 h-5.5 bg-successPressed cursor-pointer fixed bottom-0 -translate-x-2/4 left-[50%] flex items-center justify-center rounded-t-lg"
        data-testid="customerSupport_widget"
        onClick={handleOpenModal}
      >
        <Label size="300" color="text-textIcon-whitePrimary">
          Contact Support
        </Label>
      </div>
    </>
  );
};
