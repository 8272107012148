import { ChevronRightIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Label, SimpleBadgeLink } from "@web/ui";

import routes from "../../../config/routes";

export type Link = {
  status: string;
  copy?: string;
  badge?: number;
  path: string;
};

interface Props {
  sectionName: string;
  links: Link[];
}

export const FoldableSection: React.FC<Props> = ({ sectionName, links, ...rest }) => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);
  return (
    <div {...rest}>
      <button
        className="flex items-center justify-between py-2 pl-4 w-full group"
        onClick={() => setExpanded(!isExpanded)}
      >
        <Label size="100" color="text-textIcon-blackPrimary" className="block">
          {sectionName}
        </Label>
        <ChevronRightIcon
          className={classnames("w-5 h-5 text-textIcon-blackPrimary duration-300", {
            "rotate-90": isExpanded,
          })}
        />
      </button>
      <div className="flex flex-col">
        {isExpanded &&
          links.map((l) => (
            <SimpleBadgeLink
              key={l.status}
              text={t(`layouts.base.status.${l.status}`)}
              pathname={routes.orders}
              search={l.path}
              badge={l.badge}
            />
          ))}
      </div>
    </div>
  );
};
