import { Paragraph } from "@web/ui";

interface ErrorProps {
  errorMessage: string;
  minimumOrderQuantity: number | undefined;
  customerOrderQuantity?: number;
  measurementUnit: string;
}

const ErrorLabel: React.FC<ErrorProps> = ({
  errorMessage,
  minimumOrderQuantity,
  customerOrderQuantity,
  measurementUnit,
}) => {
  return (
    <div className="px-2 py-1 rounded-md bg-dangerBackground mt-1">
      <Paragraph size="300" color="text-dangerDefault">
        {errorMessage}{" "}
        <strong className="text-textIcon-blackPrimary">{minimumOrderQuantity}</strong> (
        {customerOrderQuantity} {measurementUnit})
      </Paragraph>
    </div>
  );
};

export default ErrorLabel;
