import { useTranslation } from "react-i18next";

import { SkuDimensionsAndDelivery } from "@web/common/models/ProductSku";

import { ContractableSection, RowGroup } from "./SkuAttributesUtils";

interface Props {
  dimensions?: SkuDimensionsAndDelivery;
}

export const DimensionsAndDeliverySection = ({ dimensions }: Props) => {
  const { t } = useTranslation();

  if (!dimensions?.dimensions) {
    return null;
  }

  if (Object.values(dimensions.dimensions).length === 0) {
    return null;
  }

  return (
    <ContractableSection title={t("common.views.productDetails.dimensions")}>
      <RowGroup
        rows={{
          "Size Imperial": dimensions.dimensions.sizeImperial,
          "Size Metric": dimensions.dimensions.sizeMetric,
        }}
      />
    </ContractableSection>
  );
};
