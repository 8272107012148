import { Paragraph } from "@web/ui";

interface DesiredDeliveryTimeProps {
  isOrderClosed: boolean;
  className?: string;
}

export const OrderState: React.FC<DesiredDeliveryTimeProps> = ({
  isOrderClosed,
  className = "",
}) => {
  const orderState = isOrderClosed ? "Closed" : "Active";

  return (
    <div className={className} data-testid="orderState">
      <Paragraph size="300" color="text-textIcon-blackSecondary" className="pb-2 uppercase">
        Order State
      </Paragraph>
      <div className="flex items-center truncate">
        <Paragraph size="200" color="text-neutral_700" className="truncate">
          {orderState}
        </Paragraph>
      </div>
    </div>
  );
};
