import { imagorConfig } from "./imagorConfig";
import { ImagorConfig } from "./types";
import { createImagorUrlFilters } from "./utils";

export const imagor = (imageUrl: string, providedConfig?: Partial<ImagorConfig>) => {
  const config = { ...imagorConfig, ...providedConfig };

  if (!config.baseUrl) {
    return imageUrl;
  }

  return `${config.baseUrl}/imagor/unsafe/${config.fitIn ? "fit-in/" : ""}${
    config.dimensions.width
  }x${config.dimensions.height}/${createImagorUrlFilters(config.filters)}${imageUrl}`;
};
