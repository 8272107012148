import { useSelector } from "react-redux";

import { getCategory, getChildCategories } from "@web/common/utils/categories";

import { RootState } from "../store";
import useCategoriesQuery from "./useCategoriesQuery";

export const useCategories = () => {
  const { port, vessel } = useSelector((state: RootState) => state.gather);
  const categoriesQuery = useCategoriesQuery(
    { portId: port?.id || "", vesselId: vessel?.id || "" },
    { enabled: !!port?.id && !!vessel?.id }
  );

  return {
    categoriesQuery,
    getChildCategories: (categoryId: string) =>
      getChildCategories(categoryId, categoriesQuery.data || []),
    getCategory: (categoryId: string) =>
      getCategory(categoryId, categoriesQuery.data || []).reverse()[0],
    getBreadcrumb: (categoryId: string) => getCategory(categoryId, categoriesQuery.data || []),
  };
};
