import { Label } from "../../atoms";
import { EmptyField } from "../../atoms/EmptyField/EmptyField";

type Props = {
  dataTestId?: string;
  error?: string | React.ReactElement;
  label?: string | React.ReactElement;
  onChange?: (time: string) => void;
  placeholder?: string;
  description?: string;
  initialValue?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalProps?: Record<string, any>;
};

export const TimePicker: React.FC<Props> = ({
  additionalProps,
  error,
  label,
  onChange,
  placeholder,
  ...rest
}: Props) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <EmptyField
      label={
        <Label size="200" color="text-textIcon-blackSecondary">
          {label}
        </Label>
      }
      inputProps={{
        ...additionalProps,
        onChange: handleOnChange,
        onBlur: handleOnChange,
        placeholder: placeholder,
      }}
      {...rest}
      errorMessage={error}
    />
  );
};
