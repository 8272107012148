import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useLogout } from "../hooks/useLogout";
import { RootState, setUserInactivitySpanAction, useAppDispatch } from "../store";

// in seconds
const USER_INACTIVITY_THRESHOLD = 1800; // equals to refresh token lifetime defined in auth0
const TOKEN_CHECK_INTERVAL = 60;

export const ExpirationController: React.FC = () => {
  const location = useLocation();
  const { forceLogout } = useLogout();
  const {
    auth: { userInactivitySpan },
  } = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Acknowledge use activity on route change
    dispatch(setUserInactivitySpanAction(0));
  }, [dispatch, location]);

  useEffect(() => {
    if (userInactivitySpan >= USER_INACTIVITY_THRESHOLD) {
      forceLogout();
    }
  }, [forceLogout, userInactivitySpan]);

  useEffect(() => {
    // Periodically check access token expiration and increase user inactivity time span
    const intervalId = setInterval(() => {
      dispatch(setUserInactivitySpanAction(TOKEN_CHECK_INTERVAL + userInactivitySpan));
    }, TOKEN_CHECK_INTERVAL * 1000);

    return () => clearInterval(intervalId);
  }, [dispatch, userInactivitySpan]);

  return null;
};
