import { DefaultError, InfiniteData, QueryKey, useInfiniteQuery } from "@tanstack/react-query";

import { Sorting, getSortingString } from "@web/common/hooks/useSearchHelpers";

import { ApiListAppProduct, ProductsService } from "../typegens";

export const useSearchQuery = (
  {
    portId,
    vesselId,
    query,
    sorting,
    priceMin,
    priceMax,
    leadTime,
  }: {
    portId: string;
    vesselId: string;
    query: string;
    sorting?: Sorting;
    priceMin?: string;
    priceMax?: string;
    leadTime?: "ALL" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
  },
  settings: Record<string, unknown>
) =>
  useInfiniteQuery<
    ApiListAppProduct,
    DefaultError,
    InfiniteData<ApiListAppProduct>,
    QueryKey,
    string
  >({
    queryKey: ["search", portId, vesselId, query, sorting, priceMax, priceMin, leadTime],
    queryFn: ({ pageParam }) => {
      const sort = getSortingString(sorting);
      return ProductsService.searchProducts({
        portId,
        vesselId,
        query,
        sort,
        priceMin,
        priceMax,
        leadTime,
        cursor: pageParam,
      });
    },
    initialPageParam: "",
    ...settings,
    getNextPageParam: (lastPage) => {
      if (lastPage.cursor !== "") {
        return lastPage.cursor;
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    throwOnError: false,
  });
