import { useCallback } from "react";

import { ProductSku } from "@web/common/models/ProductSku";

import { clearGatherBasketEntry, setGatherBasketQuantity, useAppDispatch } from "../store";

export const useSetQuantity = () => {
  const dispatch = useAppDispatch();

  const setQuantity = useCallback(
    (sku: ProductSku) => (q: number) => {
      if (q === 0) {
        dispatch(clearGatherBasketEntry(sku.id));
      } else {
        dispatch(setGatherBasketQuantity({ id: sku.id, quantity: q }));
      }
    },
    [dispatch]
  );

  return { setQuantity };
};
