import { v4 as uuidv4 } from "uuid";

import { CustomerFormType } from "../components/modals/EditCustomerModal/schema";
import { ApiResponse, handleResponse } from "../store/utils";
import { AppCustomer, MainAppService } from "../typegens";

export const createCustomer = async (form: CustomerFormType): Promise<ApiResponse<AppCustomer>> => {
  const id = uuidv4();
  const result = await handleResponse(() =>
    MainAppService.saveOrUpdateCustomer({
      id,
      requestBody: {
        id: id,
        name: form.customerName,
        companyId: form.companyId,
        adminId: form.adminId,
        adminReferenceId: form.adminReferenceId,
      },
    })
  );

  return result;
};
