import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { Fragment } from "react";

import { Heading, IconButton, Label, Paragraph } from "@web/ui";

export const Description: React.FC<{
  label: string;
  value?: string | number;
}> = ({ label, value }) => {
  if (!value) {
    return null;
  }
  return (
    <div className="py-4 items-baseline">
      <div className="uppercase pb-1">
        <Label size="300" color="text-textIcon-blackSecondary">
          {label}
        </Label>
      </div>
      <Paragraph size="200" color="text-textIcon-blackSecondary">
        {value}
      </Paragraph>
    </div>
  );
};

export const Row: React.FC<{
  label: string;
  value?: boolean | string | number;
}> = ({ label, value }) => {
  if (!value) {
    return null;
  }
  return (
    <div className="flex justify-between pt-4 pb-2 border-b items-baseline border-neutral_300">
      <div className="uppercase">
        <Label size="300">{label}</Label>
      </div>
      <Label size="200" color="text-textIcon-blackSecondary">
        {String(value)}
      </Label>
    </div>
  );
};

export const RowGroup: React.FC<{
  title?: string;
  rows?: Record<string, boolean | number | string | undefined>;
}> = ({ title, rows }) => {
  if (!rows) {
    return null;
  }

  const rowGroupHasAnyValue = Object.values(rows).some((r) => Boolean(r));

  if (!rowGroupHasAnyValue) {
    return null;
  }

  return (
    <div>
      {!!title && (
        <div className="pt-6 pb-2">
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            {title}
          </Paragraph>
        </div>
      )}
      <>
        {Object.entries(rows).map(([key, value]) => {
          return <Row key={key} label={key} value={value} />;
        })}
      </>
    </div>
  );
};

export const ContractableSection: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  // const handleAccordionOpen = () => {
  //   ReactGA.event({
  //     category: trackingCategories.CREW_APP,
  //     action: trackingEvents.ACCORDION_EXPANDED,
  //     label: title,
  //   });
  // };

  return (
    <Disclosure as="div" className="pt-7">
      {({ open }) => (
        <>
          <div className="flex items-center">
            <Disclosure.Button as={Fragment}>
              <IconButton
                variant="secondary"
                size="small"
                shape="circle"
                label="Expant/Contract Section"
                Icon={open ? ChevronDownIcon : ChevronRightIcon}
                isControlled
                tabIndex={-1}
              />
            </Disclosure.Button>
            <Heading size="300" className="ml-2">
              {title}
            </Heading>
          </div>
          <Disclosure.Panel as="dd" className="mt-2 pl-7">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
