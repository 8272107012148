import { DefaultError, useMutation } from "@tanstack/react-query";

import { closeOrder } from "src/api/closeOrder";
import { AppOrderRequisition } from "src/typegens";

export const useCloseOrder = (options = {}) => {
  return useMutation<AppOrderRequisition, DefaultError, { orderId: string }>({
    mutationKey: ["closeOrder"],
    mutationFn: closeOrder,
    ...options,
  });
};
